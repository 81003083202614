/* Toast */
:root {
  --toast-success: #42D887;
  --toast-danger: #FA4517;
  --toast-info: #0687D7;
  --toast-warning: #FFBC01;
}

.toast {
  max-width: 400px;
  min-width: 300px;
  margin: 15px;
  display: none;
  padding: 0 10px 0 25px;
  background-color: #FFF;
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
  border: none;
  .close {
    width: 32px;
    height: 32px;
    border-radius: 2px;
    padding: 6px;
    &:before,
    &:after {
      width: 20px;
    }
  }
  .toast-body {
    width: 100%;
  }
  .toast-title {
    font-size: 18px;
    font-weight: 500;
    color: #484848;
  }
  p {
    margin: 0;
    font-size: 14px;
    color: #7E7E7E;
  }
  .toast-icon {
    font-size: 24px;
  }
  &:after {
    content: "";
    height: 80%;
    width: 4px;
    border-radius: 2px;
    position: absolute;
    top: 10%;
    left: 10px;
  }
  &.floating-top-toast {
    position: fixed;
    top: 0;
    right: 0;
  }
  &.toast-success {
    .toast-icon {
      color: var(--toast-success);
    }
    &:after {
      background-color: var(--toast-success);
    }
  }
  &.toast-danger {
    .toast-icon {
      color: var(--toast-danger);
    }
    &:after {
      background-color: var(--toast-danger);
    }
  }
  &.toast-info {
    .toast-icon {
      color: var(--toast-info);
    }
    &:after {
      background-color: var(--toast-info);
    }
  }
  &.toast-warning {
    .toast-icon {
      color: var(--toast-warning);
    }
    &:after {
      background-color: var(--toast-warning);
    }
  }
}
.policy-doc {
  font-family: "Poppins", sans-serif;
  color: $color-dark;
  .policy-doc_content{
    max-width: 650px;
    position: relative;
    margin: 0 auto;
    padding-top: 40px;
    padding-bottom: 40px;
    text-align: center;
    &:after{
      content: '';
      position: absolute;
      background-color: rgba(0, 0, 0, 0.1);
      width: 100%;
      height: 1px;
      bottom: 0;
      left: 0;
    }
    a{
      color: $brand-primary;
      &:hover{
        color: #d83105;
      }
    }
    h1{
      font-size: 36px;
      font-weight: 600;
    }
    h3{
      font-size: 18px;
      margin-top: 15px;
    }
    p{
      font-size: 16px;
    }
    img{
      width: 84px;
      height: 84px;
      margin: 10px 0 5px;
    }
  }
  .policy-doc_info{
    max-width: 980px;
    margin: 50px auto;
    .policy-doc_subhead{
      font-size: 15px;
      line-height: 28px;
      font-weight: 600;
    }
  }
  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
    margin-bottom: 10px;
    // padding: 10px 0 15px;
  }
  h2{
    font-size: 20px;
    font-weight: 500;
    margin: 20px 0 10px;
  }
  h3{
    font-size: 18px;
    font-weight: 600;
    line-height: 31px;
    margin: 25px 0 10px;
  }
  .highlight-text {
    font-size: 16px;
    font-weight: 600;
  }
  a {
    text-decoration: underline;
  }
  ul {
    list-style-type: none;
    margin-bottom: 20px;
    // font-size: 16px;
    line-height: 31px;
    font-weight: 400;
    > li {
      // margin-bottom: 5px;
    }
    &.decimal-list,&.alpha-list {
      list-style-type: decimal;
      font-size: 14px;
      line-height: 28px;
      font-weight: 400;
      > li {
        margin-bottom: 5px;
      }
    }
    &.alpha-list {
      list-style-type: lower-alpha;
    }
  }
}
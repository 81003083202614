
.dropdown-menu {
  margin: 0 auto;
  padding: 20px 0;
  border-radius: 4px;
  background-color: #fff;
  -webkit-box-shadow: 0 0 50px 0 rgba(82, 63, 105, 0.2);
  box-shadow: 0 0 50px 0 rgba(82, 63, 105, 0.2);
  border: none;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transform-style: preserve-3d;
  -webkit-animation: kt-header-menu-submenu-fade-in 0.3s ease 1, kt-header-menu-submenu-move-down 0.3s ease-out 1;
  animation: kt-header-menu-submenu-fade-in 0.3s ease 1, kt-header-menu-submenu-move-down 0.3s ease-out 1;
}

.dropdown-item {
  color: #707070;
  font-size: 14px;
  padding: 8px 20px;
  margin-bottom: 2px;
  &:hover,
  &:focus,
  &.active {
    background-color: #f7ecec;
  }
  i {
    margin-right: 5px;
    width: 24px;
  }
  &:last-child {
    margin-bottom: 0;
  }
}
.nav-link {
  font-weight: 400;
  font-size: 13px;
  letter-spacing: 1px;
  text-transform: initial;
  color: #fff;
}

.dropdown-menu-sm {
  padding: 10px 0;
  .dropdown-item {
    color: #707070;
    font-size: 14px;
    padding: 5px 15px;
    margin-bottom: 2px;
    > i {
      margin-right: 0;
      width: 20px;
      font-size: 16px;
    }
  }
}

/*Filter Dropdown*/

.qd_filter-btn {
  .dropdown-menu{
    width: 450px;
    top: calc(100% + 5px) !important;
    padding: 0;
    transform: translateZ(0) !important;
    -webkit-transform-style: preserve-3d;
    animation: kt-header-menu-submenu-fade-in 0.3s ease 1, kt-header-menu-submenu-move-down 0.3s ease-out 1;
    &.dropdown-menu-right{
      right: 0 !important;
      left: auto !important;
    }
    .form-group{
      font-size: 14px;
      line-height: 18px;
      label{
        color: #707070;
      }
    }
    .btn{
      &.btn-datapicker{
        margin-bottom: 16px;
      }
    }
  }
  .qd_filter__body{
    padding: 16px 18px 0;
    hr{
      margin-top: 12px;
    }
  }
  .dropdown-toggle:after {
    display:none !important;
  }
  &>.btn{
    padding: 7px 15px;
    //margin-left: 8px;
    height: 100%;
  }
  .btn-light{
    background-color: #E9E7FE;
    border: 1px solid #E3E3E3;
    color: #484848;
    &:hover,&:focus{
      background-color: #d5d1fc;
      border: 1px solid #d5d1fc;
      color: #484848;
      box-shadow: none;
    }
    &:not(:disabled):not(.disabled):active{
      background-color: #d5d1fc;
      border: 1px solid #d5d1fc;
      color: #484848;
    }
  }

}

/*filter tags*/
.filter-tags{
  .filter-tag_block{
    border: 1px solid rgba(34, 36, 38, 0.15);
    padding: 10px 10px 2px;
    border-radius: 3px;
    margin-bottom: 16px;
  }
  .filter-tag{
    background-color: #EBEBEB;
    border: 1px solid #D6D6D6;
    border-radius: 4px;
    margin: 0 8px 8px 0;
    display: inline-block;
    padding: 2px 10px;
    img{
      width: 16px;
      position: relative;
      right: -4px;
      top: -1px;
    }
  }
  .filter-tag_label{
    font-size: 12px;
    font-weight: 400;
    color: rgba(4, 4, 4, 0.75);
  }
}
.filter_title{
  font-size: 14px;
  font-weight: 400;
  color: #707070;
  margin-bottom: 8px;
}
@media (max-width: 767.98px){
  .qd_filter-btn{
    .dropdown-menu{
      width: 400px;
    }
  }
}
@media (max-width: 576px){
  .qd_filter-btn {
    position: static;
    .dropdown-menu{
      width: 100%;
    }
    &>.btn{
      margin-left: 5px;
    }
  }
}


/*Custom tag*/

.custom-tag-group{
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 16px;
  .custom-tag{
    background-color: #FFFFFF;
    border-radius: 20px;
    border: 1px solid #DADCE0;
    display: inline-flex;
    align-items: center;
    padding: 5px 15px;
    margin-right: 8px;
    margin-top: 8px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.05);
    transition: all 0.3s ease-in-out;
    position: relative;
    white-space: nowrap;
    cursor: pointer;
    &.active{
      background-color: #E8F0FE;
      border-color: rgba(25, 103, 210, 0.25);
      .custom-tag_icon{
        background: url('../../assets/img/icons/icon_tag-done.svg') center center no-repeat;
        animation: custom-check 1s 1 linear;
      }
      .custom-tag_title{
        color: #0021A5;
      }
      .custom-tag_divider{
        background-color: rgba(25, 103, 210, 0.25);
      }
    }
  }
  .custom-tag_icon{
    display: inline-block;
    width: 20px;
    height: 20px;
    background: url('../../assets/img/icons/icon_tag-add.svg') center center no-repeat;
    background-size: 20px 20px;
    margin: 0;
    opacity: 0.2;
  }
  .custom-tag_divider{
    width: 1px;
    box-sizing: border-box;
    align-self: stretch;
    margin: 3px 5px 3px 10px;
    background-color: rgba(95, 99, 104, 0.25);
  }
  .custom-tag_title{
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: #484848;
  }
}
@keyframes custom-check{
  0% {opacity: 0;}
  50% {opacity: 0.5;}
  100% {opacity: 1;}
}
// ERRORS
.opps-wrap {
  height: 100vh;
  display: table;
  width: 100%;
  padding: 0 30px;
}
.opps-content {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
  display: table-cell;
  width: 100%;
  vertical-align: middle;
}
.opps-infographic {
  width: 500px;
  margin: -75px auto 0;
}
.opps-infographic_human {
  position: relative;
  top: 62px;
}
.opps-infographic_globe {}
.opps-text {
  margin-top: -70px;
  p {
    margin-top: 15px;
  }
}
.opps-button {
  margin-top: 20px;
}

@media (max-width: 991.98px) {
  .opps-content {
    max-width: 500px;
  }
  .opps-infographic_globe {
    width: 100%;
  }
}
@media (max-width: 575.98px) {

}

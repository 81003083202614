


//
// Variables
// --------------------------------------------------


//== Colors
//
//## Gray and brand colors for use across Bootstrap.
$white:                 #fff !default;
$gray-base:              #000 !default;
$gray-darker:            lighten($gray-base, 13.5%) !default; // #222
$gray-dark:              lighten($gray-base, 20%) !default;   // #333
$gray:                   lighten($gray-base, 33.5%) !default; // #555
$gray-light:             lighten($gray-base, 46.7%) !default; // #777
$gray-lighter:           lighten($gray-base, 93.5%) !default; // #eee

// $brand-primary:         #0021A5 !default;
$brand-primary:         #FA4616 !default;
// $brand-secondary:       #007BFF !default;
$brand-secondary:       #0021A5 !default;

// $color-success:         #5cb85c !default;
// $color-info:            #5bc0de !default;
// $color-warning:         #f0ad4e !default;
// $color-danger:          #d9534f !default;

$color-success:         #4ED2CC !default;
$color-info:            #4CA3FF !default;
$color-warning:         #FFA800 !default;
$color-danger:          #F64E60 !default;
$color-light:           #e5eaee !default;
$color-dark:            #484848 !default;

$color-purple:          #915EF9 !default;
$color-royalblue:       #495FB8 !default;
$color-gray:            #7D7D7D !default;
$color-orange:          #E64A19 !default;
$color-brown:           #795548 !default;
$color-blue:           #007BFF !default;
$color-green:           #198754 !default;

$base-text-color:       #484848 !default;

//== Scaffolding
//
//## Settings for some of the most global styles.

//** Background color for `<body>`.
$body-bg:               $white !default;
//** Global text color on `<body>`.
$text-color:            $gray-dark !default;

//** Global textual link color.
$link-color:            $brand-primary !default;
//** Link hover color set via `darken()` function.
$link-hover-color:      darken($link-color, 15%) !default;
//** Link hover decoration.
$link-hover-decoration: underline !default;


//== Typography
//

$font-family-poppins:  'Poppins', sans-serif !default;
$font-family-base:     $font-family-poppins !default;

$font-size-base:          14px !default;


/* Start: Rc Tooltip */
$rcTooltip-text: $white;
$rcTooltip-bg: $gray-base;
$rcTooltip-arrow-border: 6px;
$rcTooltip-arrow-position: 5px;
/* End: Rc Tooltip */
/*!
* Dashboard
*/
.main-wrap {
  background-image:linear-gradient(90deg,#2651C2,#3D94F7);
  background-position: center top;
  background-size: 100% 350px;
  // background-color: #fff;
  background-color: #eef0f8;
  background-repeat: no-repeat; 
  position: relative;
  z-index: 1;
  &:after{
    background: url(../../assets/img/qd-header-bg-vector.svg) top right no-repeat;
    background-size: 882px 347px;
    width: 100%;
    height: 100%;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
}

/* Basic portlet */
.portlet {
  // padding: 10px 0 15px 0;
  background-color: #fff;
  border: 1px solid #EFEFEF;
  margin-top: 0px;
  margin-bottom: 15px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 6px;
  box-shadow: 0 0 30px 0 rgba(82,63,105,.05);
    &:last-child {
      margin-bottom: 0;
    }
    .portlet-table{
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      color: #484848;
      .form-group{
        float: left;
        width: 50%;
        padding: 10px;
        margin-bottom: 0;
        border-bottom: 1px solid #efefef;
        &:nth-child(even){
          border-left: 1px solid #efefef;
        }
        &:last-child{
          border-bottom: none;
        }
        &:nth-last-child(2){
          border-bottom: none;
        }
        label{
          margin-bottom: 0;
        }
      }
      .portlet-table-value{
        display: block;
        color: rgba(72, 72, 72, 0.5);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    &.has-numbered-title {
      position: relative;
      > .portlet-title {

        .caption {
          padding: 6px 0 4px;
        }
        .actions{
          padding: 6px 0 4px;
          position: absolute;
          right: 15px;
        }
        .circle-number{
          height: 28px;
          min-width: 28px;
          width: 28px;
          top: 0;
          font-size: 15px !important;
          &:before{
            width: 20px;
            height: 20px;
            border-radius: 18px;
          }
        }
      }
    }
  }
  .portlet.portlet-fullscreen {
    z-index: 10060;
    margin: 0;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: #fff; }
    .portlet .portlet-body {
      padding: 15px; }
    .portlet.portlet-fullscreen > .portlet-body {
      overflow-y: auto;
      overflow-x: hidden; }
    // .portlet.portlet-fullscreen > .portlet-title {
    //   padding: 0 10px; }
  .portlet > .portlet-title {
    border-bottom: 1px solid #eee;
    padding: 5px 15px;
    min-height: 40px;
    -webkit-border-radius: 4px 4px 0 0;
    -moz-border-radius: 4px 4px 0 0;
    -ms-border-radius: 4px 4px 0 0;
    -o-border-radius: 4px 4px 0 0;
    border-radius: 4px 4px 0 0;
   }
    .portlet > .portlet-title:before, .portlet > .portlet-title:after {
      content: " ";
      display: table; }
    .portlet > .portlet-title:after {
      clear: both; }
    .portlet > .portlet-title > .caption {
      float: left;
      display: inline-block;
      font-size: 15px;
      font-weight: 500;
      color: #000;
      line-height: normal;
      padding: 10px 0; }
      .portlet > .portlet-title > .caption span{
        font-size: 16px;
        line-height: 25px;
        }
        .portlet > .portlet-title > .caption p{
          font-size: 13px;
          font-weight: 500;
          line-height: 20px;
          margin-bottom: 0;
          color:#B2B2B2;}
      .portlet > .portlet-title > .caption.bold {
        font-weight: 400; }
      .portlet > .portlet-title > .caption > i {
        float: left;
        margin-top: 4px;
        display: inline-block;
        font-size: 13px;
        margin-right: 5px;
        color: #666; }
        .portlet > .portlet-title > .caption > i.glyphicon {
          margin-top: 2px; }
      .portlet > .portlet-title > .caption > .caption-helper {
        padding: 0;
        margin: 0;
        line-height: 13px;
        color: #9eacb4;
        font-size: 13px;
        font-weight: 400; }
    .portlet > .portlet-title > .actions {
      float: right;
      display: inline-block;
      padding: 6px 0;
      > div,
      > span,
      .select2 {
        display: inline-block;
      }
      .select-control__control {
        min-height: 30px !important;
        height: 30px;
        // font-size: 12px;
      }
      .select-control__value-container {
        padding-top: 0;
        padding-bottom: 0;
      }
      .select-control__indicator {
        padding: 5px !important; }
      .select2-container {
        .select2-selection--single {
          height: 30px !important;
          font-size: 12px;
          .select2-selection__rendered,
          .select2-selection__placeholder {
            line-height: 30px;
          }
        }
      }
      .select2-container--bootstrap4  .select2-selection {
        background-color: #fff;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        font-size: 14px;
        color: #464e5f;
        // width: 230px;
      }
      .select-control__control {
        min-width: 200px;
      }
    }
      .portlet > .portlet-title > .actions > .dropdown-menu i {
        color: #555555; }
      .portlet > .portlet-title > .actions > .btn,
      .portlet > .portlet-title > .actions > .btn.btn-sm,
      .portlet > .portlet-title > .actions > .btn-group > .btn,
      .portlet > .portlet-title > .actions > .btn-group > .btn.btn-sm {
        height: 30px;
        padding: 4px 10px;
        font-size: 14px;
        line-height: 1.5;
        &:hover{
          box-shadow: none;
        }
        &:active{
          box-shadow: none;
        }
      }
        .portlet > .portlet-title > .actions > .btn.btn-default,
        .portlet > .portlet-title > .actions > .btn.btn-sm.btn-default,
        .portlet > .portlet-title > .actions > .btn-group > .btn.btn-default,
        .portlet > .portlet-title > .actions > .btn-group > .btn.btn-sm.btn-default {
          padding: 3px 9px; }
        .portlet > .portlet-title > .actions > .btn > i,
        .portlet > .portlet-title > .actions > .btn.btn-sm > i,
        .portlet > .portlet-title > .actions > .btn-group > .btn > i,
        .portlet > .portlet-title > .actions > .btn-group > .btn.btn-sm > i {
          font-size: 14px; }
      .portlet > .portlet-title > .actions .btn-icon-only {
        padding: 5px 7px 3px 7px; }
        .portlet > .portlet-title > .actions .btn-icon-only.btn-default {
          padding: 4px 6px 2px 6px; }
          .portlet > .portlet-title > .actions .btn-icon-only.btn-default > i {
            font-size: 14px; }
          .portlet > .portlet-title > .actions .btn-icon-only.btn-default.fullscreen {
            font-family: FontAwesome;
            color: #a0a0a0;
            padding-top: 3px; }
            .portlet > .portlet-title > .actions .btn-icon-only.btn-default.fullscreen.btn-sm {
              padding: 3px 3px !important;
              height: 27px;
              width: 27px; }
            .portlet > .portlet-title > .actions .btn-icon-only.btn-default.fullscreen:before {
              content: "\f065"; }
            .portlet > .portlet-title > .actions .btn-icon-only.btn-default.fullscreen.on:before {
              content: "\f066"; }
    .portlet > .portlet-title > .tools {
      float: right;
      display: inline-block;
      padding: 12px 0 8px 0; }
      .portlet > .portlet-title > .tools > a {
        display: inline-block;
        height: 16px;
        margin-left: 5px;
        opacity: 1 ;
        filter: alpha(opacity=100) ; }
      .portlet > .portlet-title > .tools > a.fullscreen {
        display: inline-block;
        top: -3px;
        position: relative;
        font-size: 13px;
        font-family: FontAwesome;
        color: #ACACAC; }
        .portlet > .portlet-title > .tools > a.fullscreen:before {
          content: "\f065"; }
        .portlet > .portlet-title > .tools > a.fullscreen.on:before {
          content: "\f066"; }
      .portlet > .portlet-title > .tools > a:hover {
        text-decoration: none;
        -webkit-transition: all 0.1s ease-in-out;
        -moz-transition: all 0.1s ease-in-out;
        -o-transition: all 0.1s ease-in-out;
        -ms-transition: all 0.1s ease-in-out;
        transition: all 0.1s ease-in-out;
        opacity: 0.8 ;
        filter: alpha(opacity=80) ; }
    .portlet > .portlet-title > .pagination {
      float: right;
      display: inline-block;
      margin: 2px 0 0 0;
      border: 0;
      padding: 4px 0; }
    .portlet > .portlet-title > .nav-tabs {
      background: none;
      margin: 1px 0 0 0;
      float: right;
      display: inline-block;
      border: 0; }
      .portlet > .portlet-title > .nav-tabs > li {
        background: none;
        margin: 0;
        border: 0; }
        .portlet > .portlet-title > .nav-tabs > li > a {
          background: none;
          margin: 5px 0 0 1px;
          border: 0;
          padding: 8px 10px;
          color: #fff; }
        .portlet > .portlet-title > .nav-tabs > li.active > a,
        .portlet > .portlet-title > .nav-tabs > li:hover > a {
          color: #333;
          background: #fff;
          border: 0; }
  .portlet > .portlet-body {
    position: relative;
    clear: both;
    -webkit-border-radius: 0 0 4px 4px;
    -moz-border-radius: 0 0 4px 4px;
    -ms-border-radius: 0 0 4px 4px;
    -o-border-radius: 0 0 4px 4px;
    border-radius: 0 0 4px 4px;
    > .help-icon {
      position: absolute;
      right: 15px;
      top: 15px;
    }
    .input-group-text{
      line-height: 1.2;
    }
  }
    .portlet > .portlet-body p {
      margin-top: 0; }
  .portlet > .portlet-empty {
    min-height: 125px; }
  .portlet.full-height-content {
    margin-bottom: 0; }
    .page-portlet-fullscreen {
      overflow: hidden;
    }
    .portlet__dropdown_select {
      min-width: 200px;
    }
    .portlet__select {
      min-width: 250px;
    }

/*  */

/* Light Portlet */
/*
  .portlet.light > .portlet-title {
    padding: 0;
    min-height: 48px; }
    .portlet.light > .portlet-title > .caption {
      color: #666;
      padding: 10px 0; }
      .portlet.light > .portlet-title > .caption > .caption-subject {
        font-size: 16px; }
      .portlet.light > .portlet-title > .caption > i {
        color: #777;
        font-size: 15px;
        font-weight: 300;
        margin-top: 3px; }
      .portlet.light > .portlet-title > .caption.caption-md > .caption-subject {
        font-size: 15px; }
      .portlet.light > .portlet-title > .caption.caption-md > i {
        font-size: 14px; }
    .portlet.light > .portlet-title > .actions {
      padding: 6px 0 14px 0; }
      .portlet.light > .portlet-title > .actions .btn-default {
        color: #666; }
      .portlet.light > .portlet-title > .actions .btn-icon-only {
        height: 27px;
        width: 27px; }
      .portlet.light > .portlet-title > .actions .dropdown-menu li > a {
        color: #555; }
    .portlet.light > .portlet-title > .inputs {
      float: right;
      display: inline-block;
      padding: 4px 0; }
      .portlet.light > .portlet-title > .inputs > .portlet-input .input-icon > i {
        font-size: 14px;
        margin-top: 9px; }
      .portlet.light > .portlet-title > .inputs > .portlet-input .input-icon > .form-control {
        height: 30px;
        padding: 2px 26px 3px 10px;
        font-size: 13px; }
      .portlet.light > .portlet-title > .inputs > .portlet-input > .form-control {
        height: 30px;
        padding: 3px 10px;
        font-size: 13px; }
    .portlet.light > .portlet-title > .pagination {
      padding: 2px 0 13px 0; }
    .portlet.light > .portlet-title > .tools {
      padding: 10px 0 13px 0;
      margin-top: 2px; }
    .portlet.light > .portlet-title > .nav-tabs > li {
      margin: 0;
      padding: 0; }
      .portlet.light > .portlet-title > .nav-tabs > li > a {
        margin: 0;
        padding: 12px 13px 13px 13px;
        font-size: 13px;
        color: #666; }
      .portlet.light > .portlet-title > .nav-tabs > li.active > a,
      .portlet.light > .portlet-title > .nav-tabs > li:hover > a {
        margin: 0;
        background: none;
        color: #333; }
  .portlet.light.form-fit {
    padding: 0; }
    .portlet.light.form-fit > .portlet-title {
      padding: 17px 20px 10px 20px;
      margin-bottom: 0; }
  .portlet.light .portlet-body {
    padding-top: 8px; }
  .portlet.light.portlet-fullscreen > .portlet-body {
    padding: 8px 0; }
  .portlet.light.portlet-fit {
    padding: 0; }
    .portlet.light.portlet-fit > .portlet-title {
      padding: 15px 20px 10px 20px; }
    .portlet.light.portlet-fit > .portlet-body {
      padding: 10px 20px 20px 20px; }
  .portlet.light.portlet-fit.portlet-form > .portlet-body {
    padding: 0; }
  .portlet.light.portlet-fit.portlet-form > .portlet-body {
    padding: 0; }
    .portlet.light.portlet-fit.portlet-form > .portlet-body .form-actions {
      background: none; }
  .portlet.light.portlet-datatable.portlet-fit > .portlet-body {
    padding-top: 10px;
    padding-bottom: 25px; }
*/
.portlet-2col {
  padding-bottom: 0;
  > .portlet-body {
    padding: 0;
    .row {
      margin: 0;
      > div {
        padding: 0;
        &:first-child {
          border-right: 1px solid #eee;
        }
      }
    }
  }
}

@media (max-width: 576px){
  .portlet{
    &.has-numbered-title{
      > .portlet-title{
        .caption{
          padding-right: 40px;
        }
      }
    }
  }
}


/* Card design */
.dashboard-stat {
  // border: none;
  border: 1px solid #ccc;
  width: 100%;
  position: relative;
  background-color: #fff;
  display: block;
  margin-bottom: 15px;
  overflow: hidden;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 5px;
  -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.0);
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.0);
  &:before,
  &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
  // .visual {
  //   position: absolute;
  //   top: 10px;
  //   right: 27px;
  //   > i {
  //     color: rgba(0, 123, 255, 0.2);
  //     font-size: 50px;
  //   }
  // }

  .details {
    padding: 5px 10px 10px;
    .title {
      color: #484848;
      font-size: 20px;
      line-height: 24px !important;
      line-height: normal;
      letter-spacing: -1px;
      margin-bottom: 0px;
      font-weight: 600;
      width: calc(100% - 48px);
      // white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    // .custom-switch {
    //   padding-left: 0;
    //   .custom-control-label{
    //     &:before{
    //       left: -2.5rem;
    //     }
    //     &:after{
    //       left: -2.8rem;
    //     }
    //   }
    // }
    .desc-header {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      min-height: 48px;
      .app-icon {
        color: #b9b9b9;
        &:hover {
          background-color: #f3f6f9;
        }
      }
    }
    .desc {
      display: flex;
      width: 100%;
      color: #909090;
      font-size: 16px;
      letter-spacing: 0px;
      font-weight: 400;
      align-items: baseline;
      .desc-value {
        font-size: 30px;
        font-weight: 600;
        color: #484848;
        flex: 0 !important;
        line-height: 36px;
      }
      .desc-status{
        font-size: 12px;
        font-weight: 500;
        color: #ADB7BB;
        margin-left: 5px;
      }
      .desc-percentage{
        font-size: 16px;
        font-weight: 500;
        color: #484848;
        margin-left: 2px;
      }
      span {
        &:first-child {
          flex: 1;
        }
        .btn {
          width: 50px;
          margin-top: 10px;
        }
      }
      .btn {
        &.btn-light {
          color: #80808f;
          background-color: $color-light;
          &:hover,
          &:focus {
            background-color: #e9e9e9;
            color: #80808f;
          }
        }
        &.btn-sm {
          font-size: 12px;
          font-weight: 600;
          padding: 4px 10px;
          [class*=" fa-"] {
            margin-right: 5px;
          }
        }
      }
     }
     .desc-img{
      position: absolute;
      top: 0.469rem;
      right: 0.375rem;
    }
  }
  .chart {
    margin: 0 -10px -10px -10px;
    img {
      width: 100%;
    }
  }
  .btn {
    margin-top: 4px;
  }
  &.disable {
    &:after {
      background-color: rgba(0, 0, 0, 0.25);
      content: "";
      width: 100%;
      height: 100%;
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
    }
    .details {
      .desc-header,
      .desc {
        position: relative;
        z-index: 2;
      }
    }
  }
}
@media(max-width: 575.98px){
  .portlet{
    &>.portlet-title{
      &>.actions{
        .select-control__control{
          min-width: 178px;
        }
      }
    }
  }
}

/* Chart info */
.chart-container {
  position: relative;
}
.chart-info-outer {
  max-width: calc(100% - 120px);
  margin: 0 auto 20px;
}
.chart-info {
  max-width: fit-content;
  border-radius: 6px;
  color: #78829D;
  background-color: #F1F1F2;
  padding: 10px;
  margin: 0 auto;
  p{
    @include flexbox;
    @include align-items(flex-start);
    font-size: 13px;
    line-height: 15px;
    font-weight: 500;
    font-style: italic;
    margin-bottom: 0;
    i{
      font-size: 14px;
      margin-right: 5px;
      color: #99A1B7;
      svg {
        display: block;
      }
    }
    a {
      white-space: nowrap;
    }
  }
}
@media (max-width: 767.98px) {
  .chart-info-outer {
    max-width: calc(100% - 50px);
    margin-left: 0;
    margin-bottom: 15px;
  }
}

/* Chart Legend */
.chart-legend {
  max-width: calc(100% - 120px);
  margin: 0 auto 20px;
  @include flexbox();
  @include justify-content(center);
  > div {
    @include flexbox();
    @include align-items(center);
    margin-right: 15px;
    padding: 0;
    color: #6C757D;
    font-size: 14px;
    &:last-child {
      margin-right: 0;
    }
  }
}
.dot {
    width:12px;
    height:12px;
    border-radius: 2px;
    display: inline-block;
    margin-right: 5px;
    vertical-align: middle;
}
@media (max-width: 767.98px) {
  .chart-legend {
    max-width: calc(100% - 50px);
    margin: 15px auto 15px 0;
    justify-content: flex-start;
  }
}

/* Code Highlight */
.highlight {
  padding: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  background-color: #f7f7f9;
  -ms-overflow-style: -ms-autohiding-scrollbar }
@media (min-width:576px) {
  .highlight {
    padding: 1.5rem
  }
}

/* Profile page attribute */
// .portlet > .portlet-body p{
//   margin-bottom: 0;
//   line-height: 20px;
// }
// .portlet .portlet-body .summary-details{
//   color: #969696;
// }
.card-attribute{
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  margin-bottom: 15px;
  .card-body{
    display: flex;
    justify-content: space-between;
    .card-image{
      display: flex;
      align-items: center;
      height: auto;
      i{
        color: rgba(149, 170, 201, 0.5);
      }
      .card_line-graph{
        width: 100px;
        height: 50px;
      }
      .svg-inline--fa{
        width: 35px;
        height: 35px;
      }
    }
  }
}
.card-attribute {
  .card-title {
    text-align: left;
    color: #95AAC9;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 5px;
  }
  .card-text{
    color: #484848;
    font-weight: 600;
    margin-bottom: 0;
    text-align: left;
    font-size: 30px;
  }
}

.attribute-list .list-group-item{
  padding: 0.41rem 1.25rem;
}
.observation-list .list-group-item{
  padding: 0.60rem 1.25rem;
}
.list-group-item svg{
  width: 100%;
  height: 10px;
  // background-color: #FA4616;
}
.list-group-item svg.svg-alt-color{
  // background-color: #0021A5;
}

/*card observation*/
.card-observation{
  border: none;
  box-shadow: none;
  margin-bottom: 15px;
  .card-body{
    padding: 30px 16px;
    .card-title{
      font-size: 18px;
      font-weight: 600;
      line-height: 27px;
      margin-bottom: 5px;
    }
    .card-text{
      font-size: 24px;
      font-weight: 600;
      line-height: 35px;
      color: #000000;
      margin-bottom: 15px;
      span{
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        color: rgba(0, 0, 0, 0.23);
        margin-left: 8px;
      }
    }
  }
  .progress{
    @include flexbox();
    margin: 0;
    margin-bottom: 5px;
    height: 0.38rem;
    overflow: hidden;
    line-height: 0;
    font-size: .75rem;
    background-color: #ebedf3;
    border-radius: .675rem;
    -webkit-box-shadow: none;
    box-shadow: none;
    .progress-bar{
      @include flexbox();
      @include justify-content(center);
      flex-direction: column;
      height: 100%;
      color: #fff;
      background-color: #484848;
      overflow: hidden;
      white-space: nowrap;
      text-align: center;
      border-radius: .28rem;
      -webkit-transition: width .6s ease;
      -o-transition: width .6s ease;
      transition: width .6s ease;
    }
  }
}
.card-bg-success{
  background-color: rgba(78, 210, 204, 0.08);
  color: #4ED2CC;
}
.card-bg-warning{
  background-color: rgba(255, 188, 57, 0.08);
  color: #FFBC39;
}
.card-bg-danger{
  background-color: rgba(248, 118, 132, 0.08);
  color: #F87684;
}
.card-bg-info{
  background-color: rgba(76, 163, 255, 0.08);
  color: #4CA3FF;
}
.progress-bar-success{
  background-color: #4ED2CC !important;
}
.progress-bar-warning{
  background-color: #FFBC39 !important;
}
.progress-bar-danger{
  background-color: #F87684 !important;
}
.progress-bar-info{
  background-color: #4CA3FF !important;
}
.progress-info{
  background-color: rgba(76, 163, 255, 0.08) !important;
}
.progress-success{
  background-color: rgba(78, 210, 204, 0.08) !important;
}
.progress-warning{
  background-color: rgba(255, 188, 57, 0.08) !important;
}
.progress-danger{
  background-color: rgba(248, 118, 132, 0.08) !important;
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .card-attribute{
    .card-body{
      .card-content{
        .card-title{
          min-height: 42px;
        }
      }
      .card-image{
        .card_line-graph{
          width: 90px;
          height: 40px;
          margin-right: -5px;
        }
      }
    }
  }
}

@media (max-width: 768px){
  .card-attribute .card-text{
    font-size: 24px;
  }
  .card-attribute{
    .card-body{
      .card-image{
        .card_line-graph{
          width: 80px;
          height: 40px;
        }
      }
    }
  }
  .portlet .portlet-body img{
    display: block;
    margin: 0 auto;
  }
  // .portlet > .portlet-title > .actions{
  //   .btn-datapicker{
  //     [class*=" fa-"] {
  //       margin-right: 0;
  //     }
  //   }
  // }
}
@media (max-width: 576px){
  .card-attribute{
    margin-bottom: 10px;
  }
}





.highlight pre {
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
  background-color: transparent;
  border: 0 }
.highlight pre code {
  font-size: inherit;
  color: #212529;
  word-wrap: break-word; }
.hll {
  background-color: #ffc }
.c {
  color: #999 }
.k {
  color: #069 }
.o {
  color: #555 }
.cm {
  color: #999 }
.cp {
  color: #099 }
.c1 {
  color: #999 }
.cs {
  color: #999 }
.gd {
  background-color: #fcc;
  border: 1px solid #c00 }
.ge {
  font-style: italic }
.gr {
  color: red }
.gh {
  color: #030 }
.gi {
  background-color: #cfc;
  border: 1px solid #0c0 }
.go {
  color: #aaa }
.gp {
  color: #009 }
.gu {
  color: #030 }
.gt {
  color: #9c6 }
.kc {
  color: #069 }
.kd {
  color: #069 }
.kn {
  color: #069 }
.kp {
  color: #069 }
.kr {
  color: #069 }
.kt {
  color: #078 }
.m {
  color: #f60 }
.s {
  color: #d44950 }
.na {
  color: #4f9fcf }
.nb {
  color: #366 }
.nc {
  color: #0a8 }
.no {
  color: #360 }
.nd {
  color: #99f }
.ni {
  color: #999 }
.ne {
  color: #c00 }
.nf {
  color: #c0f }
.nl {
  color: #99f }
.nn {
  color: #0cf }
.nt {
  color: #2f6f9f }
.nv {
  color: #033 }
.ow {
  color: #000 }
.w {
  color: #bbb }
.mf {
  color: #f60 }
.mh {
  color: #f60 }
.mi {
  color: #f60 }
.mo {
  color: #f60 }
.sb {
  color: #c30 }
.sc {
  color: #c30 }
.sd {
  font-style: italic;
  color: #c30 }
.s2 {
  color: #c30 }
.se {
  color: #c30 }
.sh {
  color: #c30 }
.si {
  color: #a00 }
.sx {
  color: #c30 }
.sr {
  color: #3aa }
.s1 {
  color: #c30 }
.ss {
  color: #fc3 }
.bp {
  color: #366 }
.vc {
  color: #033 }
.vg {
  color: #033 }
.vi {
  color: #033 }
.il {
  color: #f60 }
.css .nt+.nt,
.css .o,
.css .o+.nt {
  color: #999 }




/* Get select from Configure  */
.qd-get-configure {
  max-width: 900px;
  .card {
    padding: 20px 15px 10px;
    margin: 15px 0;
    position: relative;
    border-color: #EFEFEF;
    background-color: #fff;
    label {
      position: absolute;
      padding: 0 10px;
      background-color: #fff;
      display: inline-block;
      left: 5px;
      top: -10px;
      font-weight: 400;
      border-radius: 4px;
    }
    .form-control {
      margin-right: 10px;
      width: 80px;
    }
    &.has-error {
      border-color: $label-danger-color;
      // background: rgba(250, 92, 124, 0.10);
      > small {
        color: $label-danger-color !important;
        animation: blinker 1.5s linear infinite;
      }
      label {
        color: $label-danger-color;
      }
      // .select-control__control {
      //   border-color: $label-danger-color;
      // }
    }
  }
  @media (max-width: 576px) {
    .card {
      padding: 15px 10px 5px;
      .form-control {
        margin-right: 0;
      }
      .btn {
        margin-left: 0;
        display: block;
        width: 100%;
        margin-top: 10px;
      }
    }
  }
}

@keyframes blinker {
  50% { opacity: 0; }
}




.vs_form-ml-modal {
  max-width: 400px;
}




/* Tab content action bar */
.qd-tab__content-action {
  margin-bottom: 10px;
  .form-control__menu{
    z-index: 2;
  }
  .alert {
    margin-bottom: 0;
    padding: 10px 15px;
    line-height: 20px;
  }
  .form-control__control {
    width: 100%;
    min-width: 200px;
    height: 100%;
    min-height: 42px;
  }
  .btn {
    &.btn-datapicker {
      height: 100%;
      min-height: 42px;
      display: flex !important;
      @include align-items(center);
    }
  }
  .qd_filter-btn{
    height: 100%;
    .btn{
      min-height: 42px;
      &.btn-datapicker{
        min-height: 34px;
        height: 100%;
        display: inline-block !important;
        margin-bottom: 16px;
      }
    }
  }
  .actions {
    display: flex;
    .action-left,.action-right {
      @include flexbox();
      @include align-items(center);
    }
    .action-left {
      flex: 1 1 auto;
      > div {
        margin-right: 8px;
      }
      .css-2b097c-container {
        width: 100%;
        height: 100%;
      }
    }
    .action-right {
      > div {
        + div {
          margin-left: 8px;
        }
      }
    }
  }
  .btn-light {
    position: relative;
    .dot-symbol {
      display: none;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      position: absolute;
      top: 6px;
      left: 32px;
      border: 3px solid #EFEFF2;
      background-color: $brand-secondary;
    }
    &.has-dot-symbol {
      .dot-symbol {
        display: inline-block;
      }
    }
  }
  @media (min-width: 1200px) {
    @include flexbox();
    .caption {
      width: 100%;
      min-width: 200px;
    }
    .actions {
      @include flex-shrink(1);
      padding-left: 10px;
    }
  }
  @media (max-width: 1199.98px) {
    .moniker-alert {
      margin-bottom: 10px;
    }
  }
  @media (max-width: 991.98px) {
    .actions {
      flex-wrap: wrap;
      .action-left,.action-right {
        margin-bottom: 10px;
      }
      .action-left {
        >div {
          &:nth-child(2) {
            margin-right: 0;
          }
        }
      }
    }
  }
  @media (max-width: 767.98px) {
    .actions {
      .action-right {
        .btn-datapicker {
          svg {
            margin-right: 5px;
          }
        }
      }
    }
  }
  @media (max-width: 575.98px) {
    .actions {
      flex-direction: column;
      .action-left {
        flex-direction: column;
        margin-bottom: 0;
        >div {
          margin-right: 0;
          margin-bottom: 8px;
        }
      }
      .action-right {
        .qd_filter-btn > .btn {
          margin-left: 0;
        }
      }
    }
  }
}
.form-control {
  &__control {
    &.css-yk16xz-control {
      @include flex-wrap(nowrap);
    }
  }
  &__value-container {
    &.css-1hwfws3 {
      @include flex-wrap(nowrap);
    }
  }
  &__placeholder {
    &.css-1wa3eu0-placeholder {
      white-space: nowrap;
      position: static;
      -webkit-transform: none;
      -ms-transform: none;
      transform: none;
    }
  }
}
/* Breadcrume */
.breadcrumb-direct-section {
  .breadcrumb {
    padding: 10px 5px 0;
  }
  .breadcrumb-item {
    a {
      border-bottom: 1px solid #ccc;
      color: #484848;
      &:hover,
      &:active,
      &:focus {
        color: #0056b3;
        text-decoration: none;
        border-bottom: 2px solid #0056b3;
        transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      }
    }
    + .breadcrumb-item {
      &:before {
        padding-right: 14px;
      }
    }
    &:first-child {
      strong {
          display: inline-block;
        }
    }
    + .breadcrumb-item {
      padding-left: 14px;
      &:before {
        content: "•";
      }
    }
  }
}
/* Help Guide*/
.qd-helpGuide {
  background-color: #F8F9FA;
  overflow-y: auto;
  .qd-helpGuide__content {
    padding: 30px 20px;
    .qd-helpGuide__title {
      font-size: 16px;
      font-weight: 500;
      color: #E83E8C;
      margin-bottom: 30px;
    }
    .qd-helpGuide__info {
      list-style: none;
      counter-reset: item;
      padding-left: 2rem;
      li {
        counter-increment: item;
        margin-bottom: 5px;
        color: #212529;
        a {
          white-space: nowrap;
        }
        &:before {
          content: counter(item) ".";
          color: #CC3300;
          width: 28px;
          height: auto;
          display: inline-block;
          margin-left: -1.75rem;
        }
      }
    }
  }
}
@media (max-width: 576px) {
  .qd-helpGuide {
    .qd-helpGuide__content {
      padding: 25px 15px;
      .qd-helpGuide__title {
        margin-bottom: 25px;
      }
      .qd-helpGuide__info {
        padding-left: 1.75rem;
      }
    }
  }
}
/* radio selector Card*/
.card_radio-selector {
  padding: 20px 50px 20px 36px;
  border: 1px solid transparent;
  background-color: transparent;
  border-radius: 10px;
  + .card_radio-selector {
      margin-top: 10px;
  }
  .card-content {
      padding-left: 16px;
      .card-title {
          font-size: 16px;
          font-weight: 500;
          color: $base-text-color;
          margin-bottom: 12px;
      }
      .card-text {
          font-size: 15px;
          color: #7E8299;
          line-height: normal;
          margin-bottom: 0; 
      }
  }
  .custom-control-label:before,.custom-control-label:after {
      top: 50%;
      transform: translateY(-50%);
  }
  &.--is-selected {
      border-color: rgba(0, 123, 255, 0.25);
      background-color: rgba(0, 123, 255, 0.1);
  }
}
@media (max-width: 767.98px) {
  .card_radio-selector {
      padding-left: 30px;
      padding-right: 20px;
      .card-content {
          .card-title {
              font-size: 15px;
              margin-bottom: 10px;
          }
          .card-text {
              font-size: 14px;
          }
      }
  }
}
@media (max-width: 575.98px) {
  .card_radio-selector {
      padding: 12px 12px 12px 15px;
      .card-content {
          padding-left: 10px;
          .card-title {
              font-size: 14px;
              margin-bottom: 8px;
          }
          .card-text {
              font-size: 13px;
          }
      }
  }
}

/*React Custom Table*/
.react-custom-table {
  border-collapse: separate;
  border-spacing: 0 4px;
  white-space: nowrap;
  thead th, 
  tbody td,tbody th {
    font-size: 12px;
    padding: 8px 10px;
    vertical-align: middle;
    line-height: normal;
  }
  thead tr > th,
  tbody tr > td {
    &:nth-child(1) {
      width: 40px;
      text-align: center;
    }
    &:nth-child(2) {
      width: 50px;
      text-align: center;
    }
  }
  thead {
    th {
      color: $base-text-color;
      font-weight: 500; 
      border-bottom: 1px solid rgba(181, 179, 179, 0.15);
      [class*=" fa-"] {
        opacity: 0.5;
        font-size: 15px;
        vertical-align: middle;
      }
    }
  }
  tbody {
    &:before {
      content: "-";
      display: block;
      line-height: 5px;
      color: transparent;
    }
    tr {
      &:hover {
        background-color: #F8F8F8;
        border-radius: 2px;
      }
      th {
        font-size: 14px;
        font-weight: 400;
        color: $base-text-color;;
      }
      td {
        color: #7E8299;
      }
    }
  }}
.icon-box-square {
  width: 24px;
  height: 24px;
  border-radius: 2px;
  color: $base-text-color;
  background-color: transparent;
  padding: 6px 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  &.--type-dashboard {
    background-color: #197278;
    color: #ffffff;
  }
  &.--type-report {
    background-color: #12239E;
    color: #ffffff;
  }
}

/*card dashboard*/
.dashboard-container {
  .dashboard-content {
    @include flexbox();
    @include justify-content(center);
    @include flex-wrap(wrap);
  }
  .btn-link {
    font-size: 12px;
    color: $brand-secondary;
    text-decoration: underline;
  }
}
.card-dashboard {
  @include flexbox();
  @include align-items(center);
  @include flex-direction(row);
  padding: 4px 10px 4px 4px;
  background-color: #F8F8F8;
  border: 1px solid #EDEDED;
  border-radius: 4px;
  margin: 0 5px 5px 0;
  .icon-box-square {
    margin-right: 5px;
  }
  .card-title {
    font-size: 14px;
    font-weight: 500;
    color: $base-text-color;
    margin: 0;
  }
}

/*custom table*/

.custom-table {
  .custom-table__header {
    background-color: #F3F6F9;
   border-radius: 2px;
  }
  .custom-table__title {
    padding: 10px;
    font-size: 14px;
    font-weight: 500;
    margin: 0;
    color: rgba(0, 123, 255, 0.75);
  }
  .custom-table__body {
    padding: 15px 0;
    .btn-light-success,.btn-light-danger {
      padding: 4px 8px;
      font-size: 18px;
      background-color: transparent;
      border: none;
      [class*=" fa-"] {
        margin: 0;
      }
      &:hover {
        box-shadow: none;
      }
    }
    .btn-light-success {
      &:hover,&:focus {
        background-color: rgba($color-success, 0.15);
        color: $color-success;
      }
    }
    .btn-light-danger {
      &:hover,&:focus {
        background-color: rgba($color-danger, 0.15);
        color: $color-danger;
      }
    }
  }
  .btn-actions {
    .btn {
      padding: 4px 10px;
      span {
        vertical-align: middle;
      }
      &:hover {
        .icon__add-attribute {
        }
      }
    }
    .icon__add-attribute {
      width: 24px;
      height: 24px;
      display: inline-block;
      vertical-align: middle;
    }
  }
}
@media (max-width: 1199.98px) {
  .custom-table {
    .btn-actions {
      .btn {
        span {
          display: inline-block;
        }
      }
    }
  }
}
@media (max-width: 576px) {
  .custom-table {
    .custom-table__body {
      padding-bottom: 0;
      .btn-actions {
        padding: 10px 0 15px;
        text-align: center;
      }
    }
  }
}

/*Modal menu hide*/
.modal_menu-hide {
  .modal-dialog {
    margin: 24px;
  }
  .modal-title {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .close {
    width: 20px;
    height: 20px;
    padding: 2px;
    &:before,&:after {
      width: 16px;
    }
  }
}
.modal-mapping{
  .custom-table{
    .form-control{
      max-width: 280px;
    }
  }
}
/* PowerBi Connection style */

.pbi-connector-block {
  margin-bottom: 20px;
  .pbi-connector-header {
    @include flexbox();
    @include justify-content(space-between);
    @include align-items(center);
    padding: 5px 0;
    .pbi-connector-title {
      font-size: 16px;
      font-weight: 600;
      color: #525256;
      margin-bottom: 0;
    }
    .action {
      &> .btn {
        white-space: nowrap;
      }
    }
  }
  .pbi-connector-body {
    padding: 10px 0 15px;
  }

}

.pbi-connector-card {
  border: 1px solid #E3E3E3;
  border-radius: 6px;
  .card-icon-wrap {
    flex: 0 0 75px;
    @include flexbox();
    @include justify-content(center);
    @include align-items(center);
    background-color: #F2C811;
    border-radius: 5px 0 0 5px;
    .card-icon {
      width: 40px;
      height: auto;
    }
  }
  .card-body {
    padding: 10px 15px;
    @include flexbox();
    @include justify-content(space-between);
    @include align-items(center);
  }

  .card-content {
    margin-right: 10px;
    flex-grow: 1;
    .card-title {
      font-size: 15px;
      font-weight: 500;
      color: #484848;
      margin-bottom: 5px;
    }

    .card-text {
      @include flexbox();
      font-size: 11px;
      color: #A0A0A0;
      margin-bottom: 0;
      &> i {
        flex: 0 0 20px;
      }
    }
  }
  .card-action {
    .btn {
      padding: 3px 16px;
      display: flex;
      align-items: center;
      &.is-checked {
        background-color: #0ACF97;
        border-color: #0ACF97;
        color: #ffffff;
      }
      svg{
        fill:currentColor;
        width: 14px;
        height: auto;
        vertical-align: text-top;
      }
    }
    // .icon-plus,.icon-check {
    //   width: 14px;
    //   height: 14px;
    //   display: inline-block;
    //   vertical-align: middle;
    // }
    // .icon-plus {
    //   background: url('../../assets/img/icons/icon_plus-gray.svg') center center no-repeat;
    //   background-size: 14px;
    // }
    // .icon-check {
    //   background: url('../../assets/img/icons/icon_check-white.svg') center center no-repeat;
    //   background-size: 14px;
    // }
  }
}


@media (max-width: 575.98px) {
  .pbi-connector-block {
    margin-bottom: 0;
    .pbi-connector-header {
      flex-direction: column;
      align-items: flex-start;
      .action {
        flex-grow: 1;
        width: 100%;
        margin-bottom: 20px;
        &> .btn {
          width: 100%;
          display: block;
        }
      }
    }
    .pbi-connector-body {
      padding-bottom: 5px;
    }
  }
  .pbi-connector-card {
    .card-icon-wrap {
      .card-icon {
        width: 30px;
      }
    }
    .card-content {
      margin-right: 0;
    }
    .card-body {
      display: block;
      padding-left: 12px;
      padding-right: 12px;
    }
    .card-action {
      margin: 12px 0 5px;
    }
  }
}

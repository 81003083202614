.btn {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 14px;
  padding: 6px 20px;
  &.btn-outline {
    &.btn-grey {
      border-color: #9E9E9E;
      color: #9E9E9E;
      background: none;
        &:hover,
        &:active,
        &:active:hover,
        &:active:focus,
        &:focus,
        &.active {
        border-color: #9E9E9E;
        color: #FAFCFB;
        background-color: #9E9E9E; }
        &:disabled {
          opacity: 0.7;
          background-color: #fff;
          border-color:  #9E9E9E !important;
          color:  #9E9E9E !important;
          cursor: not-allowed;
          &:hover,
          &:focus,
          &:active {
            color:  #9E9E9E !important;
            background-color: #fff;
            border-color:  #9E9E9E !important;
          }
        }
    }
  }
  [class*=" fa-"]{
    margin-right: 5px;
  }
  &.btn-icon {
    padding-left: 15px;
  }
  &:disabled  {
    cursor: default;
  }
  + .btn {
    margin-left: 2px;
  }
  &:focus {
    box-shadow: none !important;
  }
  &.btn-datapicker {
    height: 34px;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    font-size: 14px;
    color: #464e5f;
    white-space: nowrap;
    padding-left: 12px;
    padding-right: 12px;
    .icon-close {
      width: 16px;
      height: 16px;
      display: inline-block;
      background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9Imljb25fY2lyY2xlLWNhbmNlbCIgb3BhY2l0eT0iMC4yNSI+CjxwYXRoIGlkPSJWZWN0b3IiIGQ9Ik04IDguODRMOS43NCAxMC41OEM5Ljg1IDEwLjY5IDkuOTkgMTAuNzQ1IDEwLjE2IDEwLjc0NUMxMC4zMyAxMC43NDUgMTAuNDcgMTAuNjkgMTAuNTggMTAuNThDMTAuNjkgMTAuNDcgMTAuNzQ1IDEwLjMzIDEwLjc0NSAxMC4xNkMxMC43NDUgOS45OSAxMC42OSA5Ljg1IDEwLjU4IDkuNzRMOC44NCA4TDEwLjU4IDYuMjZDMTAuNjkgNi4xNSAxMC43NDUgNi4wMSAxMC43NDUgNS44NEMxMC43NDUgNS42NyAxMC42OSA1LjUzIDEwLjU4IDUuNDJDMTAuNDcgNS4zMSAxMC4zMyA1LjI1NSAxMC4xNiA1LjI1NUM5Ljk5IDUuMjU1IDkuODUgNS4zMSA5Ljc0IDUuNDJMOCA3LjE2TDYuMjYgNS40MkM2LjE1IDUuMzEgNi4wMSA1LjI1NSA1Ljg0IDUuMjU1QzUuNjcgNS4yNTUgNS41MyA1LjMxIDUuNDIgNS40MkM1LjMxIDUuNTMgNS4yNTUgNS42NyA1LjI1NSA1Ljg0QzUuMjU1IDYuMDEgNS4zMSA2LjE1IDUuNDIgNi4yNkw3LjE2IDhMNS40MiA5Ljc0QzUuMzEgOS44NSA1LjI1NSA5Ljk5IDUuMjU1IDEwLjE2QzUuMjU1IDEwLjMzIDUuMzEgMTAuNDcgNS40MiAxMC41OEM1LjUzIDEwLjY5IDUuNjcgMTAuNzQ1IDUuODQgMTAuNzQ1QzYuMDEgMTAuNzQ1IDYuMTUgMTAuNjkgNi4yNiAxMC41OEw4IDguODRaTTggMTRDNy4xNyAxNCA2LjM5IDEzLjg0MjUgNS42NiAxMy41Mjc1QzQuOTMgMTMuMjEyNSA0LjI5NSAxMi43ODUgMy43NTUgMTIuMjQ1QzMuMjE1IDExLjcwNSAyLjc4NzUgMTEuMDcgMi40NzI1IDEwLjM0QzIuMTU3NSA5LjYxIDIgOC44MyAyIDhDMiA3LjE3IDIuMTU3NSA2LjM5IDIuNDcyNSA1LjY2QzIuNzg3NSA0LjkzIDMuMjE1IDQuMjk1IDMuNzU1IDMuNzU1QzQuMjk1IDMuMjE1IDQuOTMgMi43ODc1IDUuNjYgMi40NzI1QzYuMzkgMi4xNTc1IDcuMTcgMiA4IDJDOC44MyAyIDkuNjEgMi4xNTc1IDEwLjM0IDIuNDcyNUMxMS4wNyAyLjc4NzUgMTEuNzA1IDMuMjE1IDEyLjI0NSAzLjc1NUMxMi43ODUgNC4yOTUgMTMuMjEyNSA0LjkzIDEzLjUyNzUgNS42NkMxMy44NDI1IDYuMzkgMTQgNy4xNyAxNCA4QzE0IDguODMgMTMuODQyNSA5LjYxIDEzLjUyNzUgMTAuMzRDMTMuMjEyNSAxMS4wNyAxMi43ODUgMTEuNzA1IDEyLjI0NSAxMi4yNDVDMTEuNzA1IDEyLjc4NSAxMS4wNyAxMy4yMTI1IDEwLjM0IDEzLjUyNzVDOS42MSAxMy44NDI1IDguODMgMTQgOCAxNFoiIGZpbGw9ImJsYWNrIi8+CjwvZz4KPC9zdmc+Cg==') center center no-repeat;
      background-size: 16px;
    }
    &:active{
      transform: none;
      box-shadow: none;
    }
    &:hover{
      box-shadow: none;
    }
    @media (max-width: 768px){
      [class*=" fa-"] {
        margin-right: 0;
      }
    }
  }
}

.btn-sm {
  padding: 2px 6px;
  font-size: 11px;
  [class*=" fa-"] {
    margin-right: 3px;
  }
}
.tbl-status_btn-sm {
  padding: 2px 16px;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 11px;
  letter-spacing: 1px;
}
.btn-group-lg>.btn, .btn-lg {
	padding: 8px 24px;
	font-size: 16px;
	line-height: 1.5;
	border-radius: .3rem;
}
.btn-circle {
  border-radius: 25px;
  overflow: hidden;
}

/* Button Primary */
.btn-primary {
  color: #fff;
  background-color: lighten($brand-primary, 0%);
  border-color: lighten($brand-primary, 0%);
  &:disabled {
    cursor: default;
    background-color: lighten($brand-primary, 0%);
    border-color: lighten($brand-primary, 0%);
    opacity: 0.7;
    &:hover,
    &:focus,
    &:active {
      background-color: lighten($brand-primary, 0%);
      border-color: lighten($brand-primary, 0%);
      opacity: 0.7;
    }
  }
  &:hover,
  &:focus,
  &:active {
    color: $white;
    border-color: darken($brand-primary, 10%);
    background-color: darken($brand-primary, 10%);
  }
  &:not(:disabled):not(.disabled):active:focus,
  &.focus,
  &:focus {
    box-shadow: 0 0 0 .2rem lighten($brand-primary, 40%);
  }
  &:not(:disabled):not(.disabled):active {
    color: $white;
    border-color: darken($brand-primary, 10%);
    background-color: darken($brand-primary, 10%);
  }
}
.btn-outline-primary {
  color: lighten($brand-primary, 0%);
  border-color: lighten($brand-primary, 0%);
  &:hover {
    color: $white;
    border-color: lighten($brand-primary, 0%);
    background-color: lighten($brand-primary, 0%);
  }
  &:not(:disabled):not(.disabled){
    &:active{
      background-color: #E03E12;
      border-color: #E03E12;
    }
  }
}

/* Button secondary */
.btn-secondary {
  color: #fff;
  background-color: lighten($brand-secondary, 0%);
  border-color: lighten($brand-secondary, 0%);
  &:disabled {
    opacity: 0.7;
    background-color: lighten($brand-secondary, 0%);
    border-color: lighten($brand-secondary, 0%);
    cursor: not-allowed;
    &:hover,
    &:focus,
    &:active {
      color: $white;
      background-color: lighten($brand-secondary, 0%);
      border-color: lighten($brand-secondary, 0%);
    }
  }
  &:hover,
  &:focus,
  &:active {
    color: $white;
    border-color: darken($brand-secondary, 10%);
    background-color: darken($brand-secondary, 10%);
  }
  &:not(:disabled):not(.disabled):active:focus,
  &.focus,
  &:focus {
    box-shadow: 0 0 0 .2rem lighten($brand-secondary, 40%);
  }
  &:not(:disabled):not(.disabled):active {
    color: $white;
    border-color: darken($brand-secondary, 10%);
    background-color: darken($brand-secondary, 10%);
  }
}
.btn-outline-secondary {
  color: lighten($brand-secondary, 0%);
  border-color: lighten($brand-secondary, 0%);
  &:hover {
    color: $white;
    border-color: lighten($brand-secondary, 0%);
    background-color: lighten($brand-secondary, 0%);
  }
  &:disabled {
    opacity: 0.7;
    background-color: #fff;
    border-color:  #6c757d !important;
    color:  #6c757d !important;
    cursor: not-allowed;
    &:hover,
    &:focus,
    &:active {
      color:  #6c757d !important;
      background-color: #fff;
      border-color:  #6c757d !important;
    }
  }
}
.btn-light-secondary{
  color: #0021a5 !important;
	background-color: rgba(0, 33, 165, 0.15);
	border: 1px solid rgba(0, 33, 165, 0.35) !important;
  &:hover,
  &:focus,
  &:active{
    background-color: rgba(0, 33, 165, 0.25) !important;
    border: 1px solid rgba(0, 33, 165, 0.5) !important;
  }
}

/* Button success */
.btn-success {
  color: #fff;
  background-color: lighten($color-success, 0%);
  border-color: lighten($color-success, 0%);
  &:disabled {
    opacity: 0.7;
    background-color: lighten($color-success, 0%);
    border-color: lighten($color-success, 0%);
    cursor: not-allowed;
    &:hover,
    &:focus,
    &:active {
      color: $white;
      background-color: lighten($color-success, 0%);
      border-color: lighten($color-success, 0%);
    }
  }
  &:hover,
  &:focus,
  &:active {
    color: $white;
    border-color: darken($color-success, 10%);
    background-color: darken($color-success, 10%);
  }
  &:not(:disabled):not(.disabled):active:focus,
  &.focus,
  &:focus {
    box-shadow: 0 0 0 .2rem lighten($color-success, 40%);
  }
  &:not(:disabled):not(.disabled):active {
    color: $white;
    border-color: darken($color-success, 10%);
    background-color: darken($color-success, 10%);
  }
}
.btn-light-success {
  color: $color-success;
  font-weight: 500;
  background-color: lighten($color-success, 35%);
  border-color: lighten($color-success, 35%);
  &:disabled {
    opacity: 0.7;
    background-color: lighten($color-success, 0%);
    border-color: lighten($color-success, 0%);
    cursor: not-allowed;
    &:hover,
    &:focus,
    &:active {
      color: $white;
      background-color: lighten($color-success, 0%);
      border-color: lighten($color-success, 0%);
    }
  }
  &:hover,
  &:focus,
  &:active {
    color: $white;
    border-color: darken($color-success, 10%);
    background-color: darken($color-success, 10%);
  }
  &:not(:disabled):not(.disabled):active:focus,
  &.focus,
  &:focus {
    box-shadow: 0 0 0 .2rem lighten($color-success, 40%);
  }
  &:not(:disabled):not(.disabled):active {
    color: $white;
    border-color: darken($color-success, 10%);
    background-color: darken($color-success, 10%);
  }
}
.btn-outline-success {
  color: lighten($color-success, 0%);
  border-color: lighten($color-success, 0%);
  &:hover {
    color: $white;
    border-color: lighten($color-success, 0%);
    background-color: lighten($color-success, 0%);
  }
}


/* Button info */
.btn-info {
  color: #fff;
  background-color: lighten($color-info, 0%);
  border-color: lighten($color-info, 0%);
  &:disabled {
    opacity: 0.7;
    background-color: lighten($color-info, 0%);
    border-color: lighten($color-info, 0%);
    cursor: not-allowed;
    &:hover,
    &:focus,
    &:active {
      color: $white;
      background-color: lighten($color-info, 0%);
      border-color: lighten($color-info, 0%);
    }
  }
  &:hover,
  &:focus,
  &:active {
    color: $white;
    border-color: darken($color-info, 10%);
    background-color: darken($color-info, 10%);
  }
  &:not(:disabled):not(.disabled):active:focus,
  &.focus,
  &:focus {
    box-shadow: 0 0 0 .2rem lighten($color-info, 40%);
  }
  &:not(:disabled):not(.disabled):active {
    color: $white;
    border-color: darken($color-info, 10%);
    background-color: darken($color-info, 10%);
  }
}
.btn-light-info {
  color: $color-info;
  font-weight: 500;
  background-color: lighten($color-info, 35%);
  border-color: lighten($color-info, 35%);
  &:disabled {
    opacity: 0.7;
    background-color: lighten($color-info, 0%);
    border-color: lighten($color-info, 0%);
    cursor: not-allowed;
    &:hover,
    &:focus,
    &:active {
      color: $white;
      background-color: lighten($color-info, 0%);
      border-color: lighten($color-info, 0%);
    }
  }
  &:hover,
  &:focus,
  &:active {
    color: $white;
    border-color: darken($color-info, 10%);
    background-color: darken($color-info, 10%);
  }
  &:not(:disabled):not(.disabled):active:focus,
  &.focus,
  &:focus {
    box-shadow: 0 0 0 .2rem lighten($color-info, 40%);
  }
  &:not(:disabled):not(.disabled):active {
    color: $white;
    border-color: darken($color-info, 10%);
    background-color: darken($color-info, 10%);
  }
}
.btn-outline-info {
  color: lighten($color-info, 0%);
  border-color: lighten($color-info, 0%);
  &:hover {
    color: $white;
    border-color: lighten($color-info, 0%);
    background-color: lighten($color-info, 0%);
  }
}
/* Button Blue */
.btn-light-blue {
  color: $color-blue;
  font-weight: 500;
  background-color: rgba($color-blue, 0.10);
  border-color: rgba($color-blue, 0);
  &:disabled {
    opacity: 0.7;
    background-color: rgba($color-blue, 0.10);
    border-color: rgba($color-blue, 0);
    cursor: not-allowed;
    &:hover,
    &:focus,
    &:active {
      background-color: rgba($color-blue, 0.10);
      border-color: rgba($color-blue, 0);
    }
  }
  &:hover,
  &:focus,
  &:active {
    color: $color-blue;
    background-color: rgba($color-blue, 0.20);
    border-color: rgba($color-blue, 0);
  }
}

/* Button warning */
.btn-warning {
  color: #fff;
  background-color: lighten($color-warning, 0%);
  border-color: lighten($color-warning, 0%);
  &:disabled {
    opacity: 0.7;
    background-color: lighten($color-warning, 0%);
    border-color: lighten($color-warning, 0%);
    cursor: not-allowed;
    &:hover,
    &:focus,
    &:active {
      color: $white;
      background-color: lighten($color-warning, 0%);
      border-color: lighten($color-warning, 0%);
    }
  }
  &:hover,
  &:focus,
  &:active {
    color: $white;
    border-color: darken($color-warning, 10%);
    background-color: darken($color-warning, 10%);
  }
  &:not(:disabled):not(.disabled):active:focus,
  &.focus,
  &:focus {
    box-shadow: 0 0 0 .2rem lighten($color-warning, 40%);
  }
  &:not(:disabled):not(.disabled):active {
    color: $white;
    border-color: darken($color-warning, 10%);
    background-color: darken($color-warning, 10%);
  }
}
.btn-light-warning {
  color: $color-warning;
  font-weight: 500;
  background-color: lighten($color-warning, 35%);
  border-color: lighten($color-warning, 35%);
  &:disabled {
    opacity: 0.7;
    background-color: lighten($color-warning, 0%);
    border-color: lighten($color-warning, 0%);
    cursor: not-allowed;
    &:hover,
    &:focus,
    &:active {
      color: $white;
      background-color: lighten($color-warning, 0%);
      border-color: lighten($color-warning, 0%);
    }
  }
  &:hover,
  &:focus,
  &:active {
    color: $white;
    border-color: darken($color-warning, 10%);
    background-color: darken($color-warning, 10%);
  }
  &:not(:disabled):not(.disabled):active:focus,
  &.focus,
  &:focus {
    box-shadow: 0 0 0 .2rem lighten($color-warning, 40%);
  }
  &:not(:disabled):not(.disabled):active {
    color: $white;
    border-color: darken($color-warning, 10%);
    background-color: darken($color-warning, 10%);
  }
}
.btn-outline-warning {
  color: lighten($color-warning, 0%);
  border-color: lighten($color-warning, 0%);
  &:hover {
    color: $white;
    border-color: lighten($color-warning, 0%);
    background-color: lighten($color-warning, 0%);
  }
}

/* Button danger */
.btn-danger {
  color: #fff;
  background-color: lighten($color-danger, 0%);
  border-color: lighten($color-danger, 0%);
  &:disabled {
    opacity: 0.7;
    background-color: lighten($color-danger, 0%);
    border-color: lighten($color-danger, 0%);
    cursor: not-allowed;
    &:hover,
    &:focus,
    &:active {
      color: $white;
      background-color: lighten($color-danger, 0%);
      border-color: lighten($color-danger, 0%);
    }
  }
  &:hover,
  &:focus,
  &:active {
    color: $white;
    border-color: darken($color-danger, 10%);
    background-color: darken($color-danger, 10%);
  }
  &:not(:disabled):not(.disabled):active:focus,
  &.focus,
  &:focus {
    box-shadow: 0 0 0 .2rem lighten($color-danger, 40%);
  }
  &:not(:disabled):not(.disabled):active {
    color: $white;
    border-color: darken($color-danger, 10%);
    background-color: darken($color-danger, 10%);
  }
}
.btn-light-danger {
  color: $color-danger;
  font-weight: 500;
  background-color: lighten($color-danger, 35%);
  border-color: lighten($color-danger, 35%);
  &:disabled {
    opacity: 0.7;
    background-color: lighten($color-danger, 0%);
    border-color: lighten($color-danger, 0%);
    cursor: not-allowed;
    &:hover,
    &:focus,
    &:active {
      color: $white;
      background-color: lighten($color-danger, 0%);
      border-color: lighten($color-danger, 0%);
    }
  }
  &:hover,
  &:focus,
  &:active {
    color: $white;
    border-color: darken($color-danger, 10%);
    background-color: darken($color-danger, 10%);
  }
  &:not(:disabled):not(.disabled):active:focus,
  &.focus,
  &:focus {
    box-shadow: 0 0 0 .2rem lighten($color-danger, 40%);
  }
  &:not(:disabled):not(.disabled):active {
    color: $white;
    border-color: darken($color-danger, 10%);
    background-color: darken($color-danger, 10%);
  }
}
.btn-outline-danger {
  color: lighten($color-danger, 0%);
  border-color: lighten($color-danger, 0%);
  &:hover {
    color: $white;
    border-color: lighten($color-danger, 0%);
    background-color: lighten($color-danger, 0%);
  }
}

/* Button Purple */
.btn-purple {
  color: #fff;
  background-color: lighten($color-purple, 0%);
  border-color: lighten($color-purple, 0%);
  &:disabled {
    opacity: 0.7;
    background-color: lighten($color-purple, 0%);
    border-color: lighten($color-purple, 0%);
    cursor: not-allowed;
    &:hover,
    &:focus,
    &:active {
      color: $white;
      background-color: lighten($color-purple, 0%);
      border-color: lighten($color-purple, 0%);
    }
  }
  &:hover,
  &:focus,
  &:active {
    color: $white;
    border-color: darken($color-purple, 10%);
    background-color: darken($color-purple, 10%);
  }
  &:not(:disabled):not(.disabled):active:focus,
  &.focus,
  &:focus {
    box-shadow: 0 0 0 .2rem lighten($color-purple, 40%);
  }
  &:not(:disabled):not(.disabled):active {
    color: $white;
    border-color: darken($color-purple, 10%);
    background-color: darken($color-purple, 10%);
  }
}
.btn-light-purple {
  color: $color-purple;
  font-weight: 500;
  background-color: lighten($color-purple, 35%);
  border-color: lighten($color-purple, 35%);
  &:disabled {
    opacity: 0.7;
    background-color: lighten($color-purple, 0%);
    border-color: lighten($color-purple, 0%);
    cursor: not-allowed;
    &:hover,
    &:focus,
    &:active {
      color: $white;
      background-color: lighten($color-purple, 0%);
      border-color: lighten($color-purple, 0%);
    }
  }
  &:hover,
  &:focus,
  &:active {
    color: $white;
    border-color: darken($color-purple, 10%);
    background-color: darken($color-purple, 10%);
  }
  &:not(:disabled):not(.disabled):active:focus,
  &.focus,
  &:focus {
    box-shadow: 0 0 0 .2rem lighten($color-purple, 40%);
  }
  &:not(:disabled):not(.disabled):active {
    color: $white;
    border-color: darken($color-purple, 10%);
    background-color: darken($color-purple, 10%);
  }
}
.btn-outline-purple {
  color: lighten($color-purple, 0%);
  border-color: lighten($color-purple, 0%);
  &:hover {
    color: $white;
    border-color: lighten($color-purple, 0%);
    background-color: lighten($color-purple, 0%);
  }
}

/* Button Brown */
.btn-brown {
  color: #fff;
  background-color: lighten($color-brown, 0%);
  border-color: lighten($color-brown, 0%);
  &:disabled {
    opacity: 0.7;
    background-color: lighten($color-brown, 0%);
    border-color: lighten($color-brown, 0%);
    cursor: not-allowed;
    &:hover,
    &:focus,
    &:active {
      color: $white;
      background-color: lighten($color-brown, 0%);
      border-color: lighten($color-brown, 0%);
    }
  }
  &:hover,
  &:focus,
  &:active {
    color: $white;
    border-color: darken($color-brown, 10%);
    background-color: darken($color-brown, 10%);
  }
  &:not(:disabled):not(.disabled):active:focus,
  &.focus,
  &:focus {
    box-shadow: 0 0 0 .2rem lighten($color-brown, 40%);
  }
  &:not(:disabled):not(.disabled):active {
    color: $white;
    border-color: darken($color-brown, 10%);
    background-color: darken($color-brown, 10%);
  }
}
.btn-light-brown {
  color: $color-brown;
  font-weight: 500;
  background-color: lighten($color-brown, 35%);
  border-color: lighten($color-brown, 35%);
  &:disabled {
    opacity: 0.7;
    background-color: lighten($color-brown, 0%);
    border-color: lighten($color-brown, 0%);
    cursor: not-allowed;
    &:hover,
    &:focus,
    &:active {
      color: $white;
      background-color: lighten($color-brown, 0%);
      border-color: lighten($color-brown, 0%);
    }
  }
  &:hover,
  &:focus,
  &:active {
    color: $white;
    border-color: darken($color-brown, 10%);
    background-color: darken($color-brown, 10%);
  }
  &:not(:disabled):not(.disabled):active:focus,
  &.focus,
  &:focus {
    box-shadow: 0 0 0 .2rem lighten($color-brown, 40%);
  }
  &:not(:disabled):not(.disabled):active {
    color: $white;
    border-color: darken($color-brown, 10%);
    background-color: darken($color-brown, 10%);
  }
}
.btn-outline-brown {
  color: lighten($color-brown, 0%);
  border-color: lighten($color-brown, 0%);
  &:hover {
    color: $white;
    border-color: lighten($color-brown, 0%);
    background-color: lighten($color-brown, 0%);
  }
}

/* Button Light */
.btn-light {
  color: $color-dark;
  background-color: rgba($color-dark, 0.05);
  border-color:  rgba($color-dark, 0.25);
  &:disabled {
    opacity: 0.7;
    background-color: lighten($color-dark, 0%);
    border-color: lighten($color-dark, 0%);
    cursor: not-allowed;
    &:hover,
    &:focus,
    &:active {
      color: $white;
      background-color: lighten($color-dark, 0%);
      border-color: lighten($color-dark, 0%);
    }
  }
  &:hover,
  &:focus,
  &:active {
    color: $color-dark;
    border-color: rgba($color-dark, 0.25);
    background-color: rgba($color-dark, 0.1);
  }
  &:not(:disabled):not(.disabled):active:focus,
  &.focus,
  &:focus {
    box-shadow: 0 0 0 .2rem lighten($color-dark, 40%);
  }
  &:not(:disabled):not(.disabled):active {
    color: $white;
    border-color: darken($color-dark, 10%);
    background-color: darken($color-dark, 10%);
  }
}
.btn-outline-light {
  color: lighten($color-dark, 0%);
  border-color: lighten($color-dark, 0%);
  &:hover {
    color: $white;
    border-color: lighten($color-dark, 0%);
    background-color: lighten($color-dark, 0%);
  }
}

/* Button Disabled */
.btn-disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
  &:hover,
  &:focus,
  &:active {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
  }
}


a.btn-primary[type="button"],
a.btn-primary[type="button"]:hover {
  color: #fff; }


/* Loading Button */
.loading-btn {
  min-width: auto;
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;
  background: #03a9f4;
  border: 0;
  border-radius: 24px;
  cursor: pointer;
  -webkit-transition: all .33s ease-in-out;
  transition: all .33s ease-in-out;

  &:hover {
    background: #2196f3;
  }

  outline: none;

  &:focus {
    outline: none;
  }
}

.loading-btn--pending, .loading-btn--success, .loading-btn--fail {
  width: 40px;
  pointer-events: none;
  cursor: default;
}

.loading-btn--pending .loading-btn__text,
.loading-btn--success .loading-btn__text,
.loading-btn--fail .loading-btn__text {
  opacity: 0;
}

.loading-btn--pending {
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    display: inline-block;
    height: 28px;
    width: 28px;
    border: 3px solid rgba(255, 255, 255, 0.33);
    border-top-color: #fff;
    border-radius: 50%;
    -webkit-animation: loading-btn--fade-in .33s ease, loading-btn--rotation .66s linear 0s infinite;
    animation: loading-btn--fade-in .33s ease, loading-btn--rotation .66s linear 0s infinite;
  }
}

.loading-btn--success {
  background: #8bc34a;
  &:hover {
    background: #8bc34a;
  }
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    display: inline-block;
    height: 25%;
    width: 50%;
    border: 3px solid #fff;
    border-top-width: 0;
    border-right-width: 0;
    -webkit-transform: translate(-50%, -75%) rotate(-45deg);
    transform: translate(-50%, -75%) rotate(-45deg);
    -webkit-animation: loading-btn--fade-in .6s ease;
    animation: loading-btn--fade-in .6s ease;
  }
}

.loading-btn--fail {
  background: #ff5722;

  &:hover {
    background: #ff5722;
  }

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    display: inline-block;
    height: 65%;
    width: 65%;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(44%, transparent), color-stop(44%, #fff), color-stop(56%, #fff), color-stop(56%, transparent)), -webkit-gradient(linear, left top, right top, color-stop(44%, transparent), color-stop(44%, #fff), color-stop(56%, #fff), color-stop(56%, transparent));
    background: linear-gradient(to bottom, transparent 44%, #fff 44%, #fff 56%, transparent 56%), linear-gradient(to right, transparent 44%, #fff 44%, #fff 56%, transparent 56%);
    -webkit-transform: translate(-50%, -50%) rotate(-45deg);
    transform: translate(-50%, -50%) rotate(-45deg);
    -webkit-animation: loading-btn--fade-in .6s ease;
    animation: loading-btn--fade-in .6s ease;
  }
}

.loading-btn__text {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 13px;
  color: #fff;
  -webkit-transition: inherit;
  transition: inherit;
}


/**
 * Animations
 */
@-webkit-keyframes loading-btn--fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes loading-btn--fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes loading-btn--rotation {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
            transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
            transform: translate(-50%, -50%) rotate(360deg);
  }
}
@keyframes loading-btn--rotation {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
            transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
            transform: translate(-50%, -50%) rotate(360deg);
  }
}


/* Custom color buttons */
.font-grey-silver {
  color: #BFBFBF!important;
}
.font-grey-salsa {
  color: #ACB5C3!important;
}

// --toast-success: #42D887;
// --toast-danger: #FA4517;
// --toast-info: #0687D7;
// --toast-warning: #FFBC01;

/* Labels */
$label-primary-color:   $brand-primary !default;
$label-primary-text:      $white !default;
$label-secondary-color: $brand-secondary !default;

$label-success-color: #0acf97 !default;
$label-success-text: $white !default;

$label-success-light-color: #c9f7f5 !default;

$label-info-color: #39afd1 !default;
$label-info-text: #39afd1 !default;

$label-warning-light-color: #fff4de !default;

$label-warning-color: #ffbc00 !default;
$label-warning-text: $white !default;

$label-danger-color: #fa5c7c !default;
$label-danger-text: $white !default;

$label-danger-light-color: #ffe2e5 !default;
$label-dark-color: #464e5f !default;


.label {
  padding: 0;
  margin: 0;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 24px;
  // width: 20px;
  border-radius: 4px;
  font-size: 10px;
  background-color: #ecf0f3;
  color: #464e5f;
  font-weight: 400;
  font-size: 12px;
  &.label-primary {
    color: $label-primary-text;
    background-color: $label-primary-color;
  }
  &.label-outline-primary {
    background-color: transparent;
    color: $label-primary-color;
    border: 1px solid $label-primary-color;
    &.label-outline-2x {
      border: 2px solid $label-primary-color;
    }
  }
  &.label-light-primary {
    color: $label-primary-color;
    background-color: #eee5ff;
  }
  &.label-secondary {
    color: #464e5f;
    background-color: $label-secondary-color;
  }
  &.label-outline-secondary {
    background-color: transparent;
    color: #464e5f;
    border: 1px solid $label-secondary-color;
    &.label-outline-2x {
      border: 2px solid $label-secondary-color;
    }
  }
  &.label-light-secondary {
    color: $label-secondary-color;
    background-color: #ecf0f3;
  }
  &.label-success {
    color: $label-success-text;
    background-color: $label-success-color;
  }
  &.label-outline-success {
    background-color: transparent;
    color: $label-success-color;
    border: 1px solid $label-success-color;
    &.label-outline-2x {
      border: 2px solid $label-success-color;
    }
  }
  &.label-light-success {
    color: $label-success-color;
    background-color: $label-success-light-color;
  }
  &.label-info {
    color: #fff;
    background-color: $label-info-color;
  }
  &.label-outline-info {
    background-color: transparent;
    color: $label-info-color;
    border: 1px solid $label-info-color;
    &.label-outline-2x {
      border: 2px solid $label-info-color;
    }
  }
  &.label-light-info {
    color: $label-info-color;
    background-color: #e1e9ff;
  }
  &.label-warning {
    color: $label-warning-text;
    background-color: $label-warning-color;
  }
  &.label-outline-warning {
    background-color: transparent;
    color: $label-warning-color;
    border: 1px solid $label-warning-color;
    &.label-outline-2x {
      border: 2px solid $label-warning-color;
    }
  }
  &.label-light-warning {
    color: $label-warning-text;
    background-color: $label-warning-light-color;
  }
  &.label-danger {
    color: #fff;
    background-color: $label-danger-color;
  }
  &.label-outline-danger {
    background-color: transparent;
    color: $label-danger-color;
    border: 1px solid $label-danger-color;
    &.label-outline-2x {
      border: 2px solid $label-danger-color;
    }
  }
  &.label-light-danger {
    color: $label-danger-color;
    background-color: $label-danger-light-color;
  }
  &.label-light {
    color: #80808f;
    background-color: #f3f6f9;
  }
  &.label-outline-light {
    background-color: transparent;
    color: #464e5f;
    border: 1px solid #f3f6f9;
    &.label-outline-2x {
      border: 2px solid #f3f6f9;
    }
  }
  &.label-light-light {
    color: #f3f6f9;
    background-color: #f3f6f9;
  }
  &.label-dark {
    color: #fff;
    background-color: #212121;
  }
  &.label-outline-dark {
    background-color: transparent;
    color: #212121;
    border: 1px solid #212121;
    &.label-outline-2x {
      border: 2px solid #212121;
    }
  }
  &.label-light-dark {
    color: #212121;
    background-color: #d6d6e0;
  }
  &.label-outline-light-dark {
    background-color: transparent;
    color: #c2c2c2;
    border: 1px solid #c2c2c2;
    &.label-outline-2x {
      border: 2px solid #c2c2c2;
    }
  }
  &.label-white {
    color: #464e5f;
    background-color: #fff;
  }
  &.label-outline-white {
    background-color: transparent;
    color: #fff;
    border: 1px solid #fff;
    &.label-outline-2x {
      border: 2px solid #fff;
    }
  }
  &.label-light-white {
    color: #fff;
    background-color: #fff;
  }
  &.label-dark-75 {
    color: #fff;
    background-color: $label-dark-color;
  }
  &.label-outline-dark-75 {
    background-color: transparent;
    color: $label-dark-color;
    border: 1px solid $label-dark-color;
    &.label-outline-2x {
      border: 2px solid $label-dark-color;
    }
  }
  &.label-light-dark-75 {
    color: $label-dark-color;
    background-color: $label-secondary-color;
  }
  &.label-inline {
    width: auto;
    padding: 4px 12px;
    // border-radius: 0.42rem;
    &.label-md {
      padding: 0.8rem 0.6rem;
    }
    &.label-lg {
      padding: 0.9rem 0.75rem;
    }
    &.label-xl {
      padding: 1rem 0.85rem;
    }
  }
  &.label-pill {
    border-radius: 2rem;
  }
  &.label-rounded {
    border-radius: 0.42rem;
  }
  &.label-square {
    border-radius: 0;
  }
  &.label-dot {
    display: inline-block;
    font-size: 0 !important;
    vertical-align: middle;
    text-align: center;
  }
  &.label-inline {
    width: auto;
  }
  &.label-dot {
    line-height: 6px;
    min-height: 6px;
    min-width: 6px;
    height: 6px;
    width: 6px;
  }
  &.label-sm {
    height: 16px;
    width: 16px;
    font-size: 0.75rem;
    &.label-inline {
      width: auto;
    }
    &.label-dot {
      line-height: 4px;
      min-height: 4px;
      min-width: 4px;
      height: 4px;
      width: 4px;
    }
  }
  &.label-lg {
    height: 24px;
    width: 24px;
    font-size: 0.9rem;
    &.label-inline {
      width: auto;
    }
    &.label-dot {
      line-height: 8px;
      min-height: 8px;
      min-width: 8px;
      height: 8px;
      width: 8px;
    }
  }
  &.label-xl {
    height: 28px;
    width: 28px;
    font-size: 1rem;
    &.label-inline {
      width: auto;
    }
    &.label-dot {
      line-height: 10px;
      min-height: 10px;
      min-width: 10px;
      height: 10px;
      width: 10px;
    }
  }
}



/*all button micro-interaction style*/
.btn{
  transition: all 0.3s ease;
  &:hover{
    box-shadow: 0px 1px 2px hsla(240, 8%, 12%, 0.12), 0px 1px 1px hsla(240, 8%, 12%, 0.12);
  }
  &:active{
    transform: translateY(2px);
    box-shadow: 0px 2px 8px hsla(240, 8%, 12%, 0.12), 0px 1px 1px hsla(240, 8%, 12%, 0.12);
  }
}
/* Button icon animation */
.btn__theme {
  transition: background-color 300ms ease, box-shadow 300ms ease, color 300ms ease;
  animation: btn__scale-down 600ms ease;
  &:hover { 
    cursor: pointer;
    transform: scale(1.05);
    animation: btn__scale-up 600ms ease;
    box-shadow: 0 5px 20px rgba(60, 124, 198, 0.2);
    i {
      transform: translateX(24px);
      opacity: 0;
      animation: arrow__slide-ltr 600ms ease-out;
      }
    }
}
.btn__theme {
  i {
    transition: opacity 300ms ease;
  }
}
@keyframes btn__scale-up {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.05);
  }
}
@keyframes btn__scale-down {
  from {
    transform: scale(1.05);
  }
  to {
    transform: scale(1);
  }
}
@keyframes arrow__slide-ltr {
  from {
    transform: translateX(0px);
    opacity: 1;
  }
  to {
    transform: translateX(24px);
    opacity: 0;
  }
}
@media (max-width: 1199.98px) {
  .btn{
    &> span{
      display: none;
    }
    &.btn-sm [class*="fa-"]{
      margin-right: 0;
    }
  }
}

/*btn cross close*/
.btn-remove{
  height: 34px;
  line-height: 20px;
  padding: 6px 8px;
  width: 100%;
  opacity: 0.75;
  display: flex;
  align-items: center;
  justify-content: center;
  &:before,&:after{
    content: '';
    width: 14px;
    height: 2px;
    background-color: #fa4616;
    position: absolute;
  }
  &:before{
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  &:after{
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  &:hover,&:active{
    &:before,&:after{
      background-color: #fff;
    }
  }
}
@media (max-width: 768px){
  .btn-remove{
    position: relative;
    top: -50%;
    margin-top: -8px;
    width: 32px;
  }
}
/* Monitor */
// .qd-grid {
//   &.qd-grid-monitor {
//     .qd-section {}
//     .qd-aside {
//       width: 110px;
//       padding: 0 0 0 15px;
//       box-shadow: none;
//       background-color: transparent;
//     }
//   }
// }

.qd-grid {
  &.qd-grid-monitor {
      display: block;
    .qd-section {}
    .qd-aside {
      width: 100%;
      padding: 15px;
    }
  }
}

@media (min-width: 768px) {
  .qd-grid{
    &.qd-grid-monitor{
        width: 100%;
        display: flex;
        flex-direction: row;
      .qd-aside{
        width: 110px;
        padding: 0 0 0 15px;
        box-shadow: none;
        background-color: transparent;
      }
    }
  }
}
.show-more {
  text-align: center;
  color: $brand-primary;
  cursor: pointer;
  p {
    margin: 0;
  }
  .icon {
    width: 50px;
    height: 50px;
    margin: 0 auto 10px;
    color: $brand-primary;
    font-size: 20px;
    border: 1px solid #ccc;
    display: inline-block;
    border-radius: 50px;

    @include flexbox();
    @include align-items(center);
    @include justify-content(center);
  }
}


/* Monitor Stat */
.qd-monitor {
  &__stat {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color:#f8f8f8;
    background-clip: border-box;
    border: 1px solid #EAEAEA;
    box-shadow: 0 0 20px rgba(0,0,0,.05);;
    border-radius: 10px;
    height: calc(100% - 15px);
    margin-bottom: 15px;
    overflow: hidden;
    .btn-card-alert {
      .btn {
        padding: 4px 10px 4px 6px;
        font-size: 12px;
        border-radius: 20px;
        border-width: 1px;
        height: 100%;
        cursor: default;
        @include flexbox();
        @include align-items(center);
        @include justify-content(center);
        position: relative;
        transition: 0.5s;
        &.btn-outline-danger,
        &.btn-outline-success {
          &:before {
            content: "";
            display: inline-block;
            position: absolute;
            top: -2px;
            left: -2px;
            bottom: -2px;
            right: -2px;
            border-radius: inherit;
            animation: btnIconRipple 2.5s cubic-bezier(0.23, 1, 0.320, 1) both infinite;
          }
        }
        &.btn-outline-danger {
          &:before {
            border: 1px solid #dc3545;
          }
        }
        &.btn-outline-success {
          &:before {
            border: 1px solid #28a745;
          }
        }
        i{
          display: flex;
          align-items: center;
        }
      }
      .btn-outline-danger {
        &:hover {
          color:#dc3545;
          border-color: #dc3545;
          background-color: transparent;
        }
      }
      .btn-outline-success {
        &:hover {
          color:#28a745;
          border-color: #28a745;
          background-color: transparent;
        }
      }
      [class*=" fa-"] {
        font-size: 16px;
      }
    }
    &-title {
      text-align: center;
      color: #505050;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      background-color: #fff;
      padding:0 10px 10px;
    }
  }
  &__head {
    padding: 15px;
    background-color: #fff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    // margin-bottom: 10px;
    @include flexbox();
    @include align-items(center);
    @include justify-content(space-between);
  }
  &__body {
    padding: 10px;
    background-color: #fff;
  }
  &__footer {
    border-top: 1px solid #EAEAEA;
    background-color: #f8f8f8;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    .error-detail {
      padding: 15px;
      font-weight: 500;
      p {
        @include flexbox();
        @include align-items(center);
        @include flex-wrap(wrap);
        i {
          -ms-flex: 0 0 24px;
          flex: 0 0 24px;
        }
      }
    }
    p, label {
      margin-bottom: 0;
    }
    p {
      color: rgba(0, 0, 0, 0.65);
      i {
        width: 24px;
        display: inline-block;
        color: rgba(0, 0, 0, 0.4);
      }
      span {
        > label {
          color: rgba(0, 0, 0, 1);
          margin-right: 5px;
        }
        > span {
          word-break: break-all;
        }
      }

      label {
        display: inline;
      }
    }
    .badge {
      font-weight: 500;
      color: #000;
      background-color: rgba(0, 0, 0, 0.05);
      margin-left: 5px;
      margin-top: 2px;
      margin-bottom: 2px;
      label {
        font-weight: 400;
        margin-right: 2px;
      }
    }
  }
}
.chart-indication {
  color: #484848;
  font-size: 12px;
  > i {
    margin-right: 5px;
  }
  > i,
  > label {
    color: rgba(72, 72, 72, 0.5);
    margin-bottom: 0;
  }
}

/* Animations */
@keyframes btnIconRipple {
	0% { border-width: 4px; transform: scale(1); }
	80% { border-width: 1px; transform: scale(1.15);}
	100% { opacity: 0;}
}
.btn-blink {
  animation: blink 1s infinite;
  animation-timing-function: ease-in-out;
}
@keyframes blink {
  0% { border-color: #dc3545; }
  50% { border-color: rgba(220, 53, 70, 0.2); background-color: rgba(220, 53, 70, 0.2); }
  100% { border-color: #dc3545; }
}

.qd-driftChart {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: .25rem; }
  .qd-driftChart .qd-driftChart_header {
    padding: 4px 2px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .qd-driftChart .qd-driftChart-grid {
    padding: 0 2px; }
  .qd-driftChart .btn {
    border: 1px solid rgba(99, 48, 48, 0.125);
    font-size: 12px;
    font-weight: 500; }
  .qd-driftChart .qd-driftChart_body {
    padding: 10px; }
  .qd-driftChart .qd-driftChart_footer {
    padding: 4px 2px;
    border-top: 1px solid rgba(0, 0, 0, 0.125); }
.qd-driftChart-footer-select {
  position: relative; }
.qd-driftChart_select__control {
  display: block;
  width: 100%;
  height: 30px;
  min-height: 30px !important;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: .25rem;
  font-size: 12px;
  font-weight: 500;
  padding: 0;
  vertical-align: middle;
  line-height: 1.5;  }
.qd-driftChart_select__indicators {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 5px;
  right: 2px; }
  .qd-driftChart_select__indicators svg {
    width: 14px;
    height: 14px; }
.qd-driftChart_select__multi-value {
  display: inline-flex; }


  .qd-driftChart {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: .25rem; }
  .qd-driftChart .qd-driftChart_header {
    padding: 4px 2px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .qd-driftChart .qd-driftChart-grid {
    padding: 0 2px; }
  .qd-driftChart .btn {
    border: 1px solid rgba(0, 0, 0, 0.125);
    font-size: 12px;
    font-weight: 500; }
  .qd-driftChart .qd-driftChart_body {
    padding: 10px; }
  .qd-driftChart .qd-driftChart_footer {
    padding: 4px 2px;
    border-top: 1px solid rgba(0, 0, 0, 0.125); }
.qd-driftChart-footer-select {
  position: relative; }
.qd-driftChart_select__control {
  display: block;
  width: 100%;
  height: 30px;
  min-height: 30px !important;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: .25rem;
  font-size: 12px;
  font-weight: 500;
  padding: 6px;
  vertical-align: middle;
  line-height: 1.5;  }
.qd-driftChart_select__indicators {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 5px;
  right: 2px; }
  .qd-driftChart_select__indicators svg {
    width: 14px;
    height: 14px; }
.qd-driftChart_select__multi-value {
  display: inline-flex; }

.tbl_horiz-charts{
  margin-bottom: 15px;
  dt {
    line-height: 18px;
    color: #484848 !important;
    margin-bottom: 5px; }
  .tbl_horiz-chart-content{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    .tbl_chart{
      width: 100px;
      margin-right: 5px;
      .chart-card{
        width: 100px;
        height: 65px;
        background-color: #FFFFFF;
        border: 1px solid #E6E6E6;
        padding: 4px;
        border-radius: 2px;
        img{
          width: 100%;
          height: auto;
        }
      }
      .tbl_chart-caption{
        font-size: 11px;
        font-weight: 500;
        color: #7B7B7B;
        line-height: 12px;
        margin: 6px 0 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 24px;
        label{
          display: inline-block;
          margin: 0;
          white-space: normal;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        strong{
          color: #484848;
          font-size: 12px;
          letter-spacing: -0.5px;
        }
        span{
          &.perform{
            position: relative;
            right: 0;
            top: 1px;
            width: 10px;
            height: 10px;
            display: inline-block;
            &.up{
              background: url(../../assets/img/icons/icon_arrow-up-green.svg);
              background-size: cover;
            }
            &.pve-down {
              background: url(../../assets/img/icons/icon_arrow-down-green.svg);
              background-size: cover;
            }
            &.down{
              background: url(../../assets/img/icons/icon_arrow-down-red.svg);
              background-size: cover;
            }
            &.nve-up{
              background: url(../../assets/img/icons/icon_arrow-up-red.svg);
              background-size: cover;
            }
          }
        }
        > div {
          position: relative;
          right: -2px;
          text-align: right;
        }
      }&.tbl_chart-lg {
        width: 120px;
        .chart-card {
          width: 120px;
          height: 80px;
        }
      }
    }
  }
  &:last-child{
    margin-bottom: 5px;
  }
}

.tbl-placeholder {
  text-align: center;
  padding: 20px 0;
  color: #777777;
  h4{
    font-size: 14px;
  }
}
/*Fieldset Card*/
.react-bootstrap-table table {
  .card_fieldset-group{
    padding: 10px 5px 5px 10px;
    margin: 15px 0 20px;
    position: relative;
    border-color: #E6E6E6;
    background-color: transparent;
    min-width: 200px;
    box-shadow: none;
    label{
      position: absolute;
      padding: 0 10px;
      background-color: #f9fbfd;
      display: inline-block;
      left: 5px;
      top: -10px;
      font-weight: 400;
      border-radius: 4px;
      color: #777777;
    }
    .table-vertical-btns{
      padding: 0;
      .btn{
        display: inline-block;
      }
    }
    i{
      color: #F64E60;
      font-size: 12px;
      cursor: pointer;
    }
   .table-monitor-label-mw {
    width: 200px; 
    }
  }
  .show-more{
    .icon{
      width: 38px;
      height: 38px;
      border-radius: 30px;
      .svg-inline--fa.fa-w-14{
        width: 14px;
      }
    }
    p{
      font-size: 12px;
    }
  }
}

/*Nested Checkbox*/

.qd_filter-btn{
  .qd_nested-multiselect{
    .select-control__menu-list {
      &--is-multi{
        padding: 12px 0 20px;
      }
    }
    .select-control__option{
      &.select-control__option--is-selected{
        background-color: #EBEFFF;
      }
    }
    .parent-checkbox,.child-checkbox{
      &> div{
        padding: 6px 12px;
        &:hover{
          background-color: #EBEFFF;
        }
      } 
    }
    .child-checkbox { 
      &> div{
        padding-left: 35px;
      }
      &+ .parent-checkbox{
        margin-top: 10px;
      }
    }
  }
}


/*Follow Button*/
.has-btn-follow{
  @include flexbox();
  @include align-items(center);
  .form-group{
    &:first-child{
      flex-grow: 1;
    }
  }
}
.btn-follow{
  @include flexbox();
  @include align-items(center);
  @include justify-content(center);
  height: 34px;
  font-weight: 500;
  padding: 6px 12px;
  i{
    font-size: 12px;
    position: relative;
    top: -1px;
    &.badge{
      top: 0;
      left: 0;
      margin-right: 5px;
      font-size: 11px;
      font-style: normal;
    }
  }
  &:hover{
    box-shadow: none;
  }
}
.react-bootstrap-table {
  table {
    .follow-btn {
      min-width: 60px;
      text-align: center;
      color: #0D6EFD;
      cursor: pointer;
      .icon {
        width: 38px;
        height: 38px;
        border-radius: 30px;
        margin: 0 auto 10px;
        color: #0D6EFD;
        font-size: 20px;
        border: 1px solid #ccc;
        background-color: transparent;
        @include flexbox();
        @include align-items(center);
        @include justify-content(center);
        i{
          font-size: 14px;
        }
      }
      p{
        font-size: 12px;
        margin: 0;
      }
      &.following{
        .icon{
          border-color: rgba(13, 110, 253, 0.25);
          background-color: rgba(13, 110, 253, 0.25);
        }
        &:hover{
          .icon{
            border-color: #d1d3db;
            background-color: #E4E6EF;
            i{
              color: #3F4254;
            }
          }
          p{
            color: #3F4254;
          }
        }
      }
      
      &:hover,
      &:focus{
       .icon{
        background-color: rgba(13, 110, 253, 0.15);
        border-color: rgba(13, 110, 253, 0.15);
       }
      }
    }
  }
}



/* AB Testing Model */
.abtested_div {
  position: relative;
  height: 65px;
  &:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 1px;
    border-left: 1px solid #e1edfc;
    top: 0;
    left: 10px;
  }
  &.abtested_start {
    &:before {
      top: 20px;
    }
  }
  &.abtested_end {
    &:before {
      top: calc(-100% + 10px);
    }
  }
}
.abtest_parent,
.abtest_child,
.abtest_nextchild {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background-color: #000;
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
  color: #fff;
  font-weight: 600;
  font-size: 12px;
  margin-right: 5px;
  position: relative;
  z-index: 1;
}
.abtest_parent {
  background-color: $brand-primary;
}
.abtest_child {
  background-color: #3E9BFE;
  width: 16px;
  height: 16px;
  border-radius: 16px;
  font-size: 10px;
  margin: 2px 7px 2px 2px;
}
.abtest_nextchild {
  background-color: $color-success;
  width: 16px;
  height: 16px;
  border-radius: 16px;
  font-size: 10px;
  margin: 2px 7px 2px 2px;
}

.fs-5 {
    font-size: 1.25rem !important;
}

/* Alert */
.alert__testing-connection {
  background-color: #fff;
  border: 6px solid #ffd3d3;
  border-radius: 20px;
  padding: 20px;
  .alert-heading {
    img {
      margin-right: 10px;
    }
  }
  .brand-logo {
    width: 44px;
    height: 44px;
  }
  strong {
    color: $color-dark;
    margin-right: 5px;
  }
}
.loading-indicator {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.85);
}
.spinner-border {
  border-right-color: #D3D3D3;
}



// Date Picker

.daterangepicker {
  padding: 0;
  margin: 0;
  width: auto;
  -webkit-box-shadow: 0 2px 4px 0 rgba(107, 121, 136, 0.4);
  box-shadow: 0 2px 4px 0 rgba(107, 121, 136, 0.4);
  border-radius: 4px;
  border: 0;
  font-family: 'Poppins', sans-serif;
  z-index: 95
}

.modal-open .daterangepicker {
  z-index: 1051
}

.daterangepicker:after,
.daterangepicker:before {
  display: none
}

.daterangepicker .ranges ul {
  padding: 4px 0;
  width: 170px
}

.daterangepicker .ranges li {
  font-size: 12px;
  padding: 4px 12px;
  font-weight: 400;
  min-height: 28px;
  line-height: 28px;
  color: #595d6e;
  -webkit-transition: color .3s ease;
  transition: color .3s ease
}

.daterangepicker .ranges li.active,
.daterangepicker .ranges li:hover {
  background-color: #f7ecec;
  color: #16181b;
  -webkit-transition: color .3s ease;
  transition: color .3s ease
}

.daterangepicker.show-calendar .ranges {
  border-right: 1px solid #ebedf2
}

.daterangepicker.show-ranges .drp-calendar.left {
  border-left: 0
}

.daterangepicker.show-calendar .ranges {
  margin-top: 0
}

.daterangepicker .drp-buttons {
  padding: 1rem 1.75rem;
  border-top: 1px solid #ebedf2
}

.daterangepicker .drp-buttons .btn {
  font-size: .9rem;
  font-weight: 400;
  padding: .5rem 1rem;
  border-radius: 0;
  border-radius: 4px
}

.daterangepicker .drp-calendar.left,
.daterangepicker .drp-calendar.right {
  padding: 1rem 1.5rem 1rem 1.5rem
}

.daterangepicker .drp-calendar.left {
  border-left: 0 !important
}

.daterangepicker .drp-calendar th {
  font-weight: 500;
  font-size: 12px;
  color: #595d6e
}

.daterangepicker .drp-calendar th.month {
  font-weight: 400;
  color: #74788d
}

.daterangepicker .drp-calendar th.next span,
.daterangepicker .drp-calendar th.prev span {
  border-width: 0 1px 1px 0;
  border-color: #74788d
}

.daterangepicker .drp-calendar th.next span {
  margin-right: 1px
}

.daterangepicker .drp-calendar th.prev span {
  margin-left: 1px
}

.daterangepicker .drp-calendar td {
  font-size: 12px;
  color: #595d6e
}

.daterangepicker .drp-calendar td.available.off {
  color: #a2a5b9
}

.daterangepicker .drp-calendar td.active {
  background-color: #ff7e5d;
  color: #fff;
  border-radius: 0;
  border-radius: 4px
}

.daterangepicker .drp-calendar td.active.start-date {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0
}

.daterangepicker .drp-calendar td.active.end-date {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0
}

.daterangepicker .drp-calendar td.active.start-date.end-date {
  border-radius: 4px
}

.daterangepicker .drp-calendar td.today,
.daterangepicker .drp-calendar td.today.active {
  border-radius: 0;
  border-radius: 4px;
  background: rgb(251, 70, 24) !important;
  color: #fff !important
}

.daterangepicker .drp-calendar td.in-range.available:not(.active):not(.off):not(.today) {
  background-color: #ffd0c4;
  color: #fb4618;
}

.daterangepicker select {
  border-radius: 4px;
  background: 0 0 !important;
  border-color: #ebedf2;
  color: #fb4618;
}

@media (max-width: 576px){
  .daterangepicker .drp-calendar.left,.daterangepicker .drp-calendar.right{
    margin: 0 auto;
  }
  .daterangepicker .drp-buttons .btn{
    font-size: 12px;
    padding: 6px 12px;
  }
}

/* | Alert | */
.alert {
  &.alert-sm {
    padding: 10px;
    font-size: 12px;
  }
  &.has-close {
    padding-right: 30px;
    .close {
      &.close-sm {
        right: 5px;
        top: 5px;
      }
    }
  }
}


/* | Moniker Alert | */
.moniker-alert {
  color: #484848;
  border-color: lighten($color-danger, 25%);
  background-color: lighten($color-danger, 32%);
}


/* Alert Success */
.alert-success{
  color: $color-dark;
  background-color: rgba($color-success, 0.15);
  border-color: rgba($color-success, 0.25);
}

/* Alert Success */
.alert-info{
  color: $color-dark;
  background-color: rgba($color-info, 0.15);
  border-color: rgba($color-info, 0.25);
}

/* Alert Warning */
.alert-warning{
  color: $color-dark;
  background-color: rgba($color-warning, 0.15);
  border-color: rgba($color-warning, 0.25);
}

/* Alert Danger */
.alert-danger{
  color: $color-dark;
  background-color: rgba($color-danger, 0.15);
  border-color: rgba($color-danger, 0.25);
}

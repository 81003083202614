/* Quick Start */
.qd_quick-start-wrapper {
  width: 100%;
}



.qd-qs_aside {
  padding: 20px 10px;
  width: 100%;
  .nav-link {
    margin-bottom: 5px;
  }
  .nav-pills {
    .nav-link {
      padding: 6px 10px;
      color: #6D6D6D;
      font-size: 14px;
      &:hover {
        background-color: #c4c4c4;
        color: #484848;
      }
      &.active {
        background-color: $color-info;
        color: #FFF;
      }
    }
  }
}
.qd-qs_content {
  padding: 20px 10px;
}

.qd-qs_tab-content {
  padding: 0;
  p {
    margin-bottom: 15px;
  }
}
.qd-qs_tab-title {
  font-size: 18px;
  margin-bottom: 20px;
}

@media (min-width: 768px) {

  .qd_quick-start-wrapper {
    margin: 20px auto;
  }
    .qd-qs_aside {
      border-right: 1px solid #ddd;
      width: 300px;
      .nav-link {
        margin-bottom: 5px;
      }
      .nav-pills {
        .nav-link {
          padding: 12px 20px;
          color: #6D6D6D;
          border-radius: 3px;
          border-top-left-radius: 25px;
          border-bottom-left-radius: 25px;
          height: 44px;
        }
      }
    }
    .qd-qs_tab-content {
      padding: 0 20px;
    }
  }
  
@media (min-width: 1300px) {
  .qd_quick-start-wrapper {
    max-width: 90%;
  }
}
@media (max-width: 991.98px) {

}
// @media (max-width: 767.98px) {
//   .qd-qs_aside {
//     width: 100%;
//   }
// }
/***
Dropzone css changes(new in v1.1.1)
***/
.dropzone {
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px; 
}

.dropzone-file-area {
  border: 1px solid #ddd;
  border-radius: 6px;
  background: #F8F8F8;
  padding: 20px;
  margin: 0 auto;
  text-align: center;
  i {
    font-size: 50px;
    color: $brand-primary;
    display: inline-block;
    width: auto;
    height: auto;
    margin-bottom: 20px;
    opacity: 0.75;
  }
  h3 {
    font-size: 16px;
  }
  p {
    font-size: 12px;
  }
  .dz-message {
    display: none;
  }
}

.dz-hidden-input {
  left: 0; }
 

@media (max-width: 768px) {
  /* 768px */
  .dropzone-file-area {
    width: auto; } }
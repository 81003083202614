/* Billing Subscribe */

.vertical-stepper {
  position: relative;
  .vertical-stepper__heading {
    padding: 24px 24px 16px;
  }
  .vertical-stepper__content {
    margin: -8px -36px -16px 36px;
    border-left: 2px solid #d8e2ef;
    // &:not(:last-child) {
    //   border-left: 2px solid rgba(0,0,0,.12);
    // }
  }
  &.vertical-stepper-sm {
    margin-bottom: 30px;
    .vertical-stepper__heading {
      padding: 10px 24px 5px;
    }
    .vertical-stepper__content {
      margin: -8px -36px -16px 36px;
      padding-left: 28px;
      border-left: 2px solid #d8e2ef;
    }
    .vs-heading__title {
      font-size: 18px;
      padding-left: 10px;
      color: #2f6bd6;
    }
    .vs-heading__circle {
      height: 36px;
      min-width: 36px;
      width: 36px;
      background-color: #d8e2ef;
      &:before {
        background-color: #2f6bd6;
        width: 28px;
        height: 28px;
        top: 4px;
        left: 4px;
      }
    }
  }
}
.vertical-stepper__heading {
  align-items: center;
  display: flex;
  flex-direction: row;
  padding: 24px;
  position: relative;
}
.vertical-stepper__content {
  padding: 16px 60px 16px 23px;
  width: auto;
}
.vs-heading__circle {
  align-items: center;
  border-radius: 50%;
  display: inline-flex;
  font-size: 15px;
  font-weight: 500;
  justify-content: center;
  height: 40px;
  min-width: 40px;
  width: 40px;
  transition: .3s cubic-bezier(.25,.8,.25,1);
  position: relative;
  margin-left: -5px;
  // background-color: $brand-primary;
  // border-color: $brand-primary;
  background-color: lighten($brand-primary, 30%);
  color: #fff;
  z-index: 1;
  &:before {
    position: absolute;
    top: 5px;
    left: 5px;
    width: 30px;
    height: 30px;
    content: "";
    z-index: -1;
    background-color: $brand-primary;
    // border: 5px solid lighten($brand-primary, 20%);
    border-radius: 30px;
  }
}

.vs-heading__title {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  line-height: 1;
  padding-left: 15px;
  font-size: 22px;
  font-weight: 500;
  color: #000;
}
@media (max-width: 575.98px) {
  .vertical-stepper {
    &.vertical-stepper-sm {
      padding: 15px;
      margin-bottom: 0;
      .vertical-stepper__heading {
        padding: 0;
      }
      .vs-heading__title {
        font-size: 16px;
      }
      .vs-heading__circle {
        height: 28px;
        min-width: 28px;
        width: 28px;
        font-size: 12px;
        &:before {
          background-color: #2f6bd6;
          width: 20px;
          height: 20px;
          top: 4px;
          left: 4px;
        }
      }
      .vertical-stepper__content {
        border: none;
        padding: 0;
        margin: 0;
      }
    }
  }
}


/* Billing Payment Wrapper */
.billing-payment-wrapper {
  max-width: 960px;
  margin: 0 auto 40px;
  .heading-section { 
    margin-bottom: 0;
  }
}

/* Payment */
/* Payment Preference */
.payment__preference{
  max-width: 635px;
  margin: 10px 0 20px;
  background-color: rgba($color-dark, 0.03);
  border: 1px solid rgba($color-dark, 0.15);
  .card-icon{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 0 0 60px;
    i{
      font-size: 32px;
      color: rgba($gray-base, 0.35);
    }
  }
}
.payment-cards{
  max-width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: .25rem;
  list-style: none;
  li{
    margin-right: 0.5rem;
  }
}
@media (max-width: 575.98px){
  .payment__preference{
    .card-icon{
      flex: 0 0 40px;
      i{
        font-size: 28px;
      }
    }
    .card-body{
      padding: 10px;
    }
  }
  .payment-cards{
    li{
      margin-right: 5px;
    }
    img{
      width: 36px;
    }
  }
  .payment-card-icon{
    width: 50px !important;
  }
}


/* Cried Card */
.payment__card {
  max-width: 635px;
  margin: 10px 0 10px;
}

/* Saved cards */
.payment-savedCard{
  max-width: 665px;
  background-color: rgba($color-dark, 0.02);
  border: 1px solid rgba($color-dark, 0.25);
  border-radius: 6px;
  padding: 15px 20px;
  margin-bottom: 20px;

  .payCard-details{
    margin-left: 10px;
    .card-title{
      font-size: 14px;
      font-weight: 500;
      color: rgba($gray-base, 0.4);
    }
    .payCard-content{
      display: flex;
      align-items: center;
      i{
        font-size: 18px;
        color: #CCCCCC;
      }
    }
    .payCard-info{
      margin-left: 18px;
      color: $color-dark;
      h5{
        font-weight: 600;
        margin-bottom: 0;
      }
      p{
        font-weight: 500;
        margin-bottom: 0;
        letter-spacing: 1px;
      }
      small{
        font-size: 40px;
        line-height: 0px;
        display: inline-block;
        height: 22px;
        margin-right: 5px;
        vertical-align: bottom;
        letter-spacing: -1px;
        font-family: 'Poppins Regular';
      }
      span{
        &:first-child{
          margin-right: 5px;
        }
        &:last-child{
          white-space: nowrap;
        }
      }
    }
  }
  .payCard-action{
    flex: 0 0 250px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    label{
      margin-bottom: 0;
      font-weight: 500;
    }
    .btn{
      font-size: 12px;
      padding: 7px 20px;
      font-weight: 500;
    }
  }
  // + .payment-savedCard {
  //   margin-top: 20px;
  // }
}
@media (max-width: 767.98px){
  .payment-savedCard{
    .payCard-action{
      flex: 0 0 230px;
    }
  }
}
@media (max-width: 575.98px){
  .payment-savedCard{
    padding: 15px;
    margin-bottom: 15px;
    .payCard-action{
      flex: 0 0 420px;
      // margin-left: 10px;
      // margin-top: 5px;
      justify-content: start;
      // .btn{
      //   padding: 4px 18px;
      // }
    }
    .payCard-details{
      margin-left: 0;
      .payCard-content{
        margin-bottom: 5px;
        i{
          font-size: 16px;
        }
      }
      .card-title{
        margin-bottom: 5px;
        // font-size: 12px;
      }
      .payCard-info{
        margin-left: 10px;
        small{
          height: 20px;
          margin-right: 0;
          letter-spacing: -2px;
        }
        // h5{
        //   margin-bottom: 5px;
        // }
        p{
          font-size: 11px;
          letter-spacing: 0;
        }
        span{
          &:first-child{
            margin-right: 2px;
          }
        }
      }
    }
  }
}

/*Billing Payment*/
.sticky__billing-summary-card {
  .billing-summary-card {
    position:-webkit-sticky; 
    position:sticky; 
    top: 85px; 
  }
}
.billing-payment {
  max-width: 1200px;
  margin: 0 auto 40px;
  width: 100%;
  .heading-section { 
    margin: 30px 0 30px;
    text-align: left;
    .heading-section-title{
      margin-bottom: 5px;
    }
    p{
      margin: 0;
      font-size: 18px;
      font-weight: 400;
    }
  }
  .circle-number{
    background-color: #fff !important;
    margin-right: 6px;
  }
  .card-payment__control{
    .payment-savedCard{
      margin: 0 20px 20px;
      .btn{
        margin-left: 0;
      }
    }
    .btn{
      margin-left: 45px;
    }
    .payment-address{
      margin: 0 20px 20px;
    }
  }
}
.billing-summary-card {
  background-color: #fff;
  padding: 15px;
  .card-header {
    background-color: rgba($color-dark, 0.1);
    border-radius: 6px;
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 0;
    h4 {
      font-size: 16px;
      color: $color-dark;
      margin-bottom: 0;
      font-weight: 500;
    }
  }
}

.billing-summary-card-add-ons {
  margin: 20px 0;
}

.billing-summary-card__item {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  margin-bottom: 10px;
  margin-top: 10px;
  font-weight: 500;
  color: $color-dark;
}
.billing-summary-card__item-annual-prefix {
  color: #868e96
}
.billing-summary-card__item-annual-prefix {
  margin-right: 5px;
}
.billing-summary-card__divider {
  border: none;
  height: 1px;
  margin: 20px 0;
  flex-shrink: 0;
  background-image: linear-gradient(90deg, rgba(112, 112, 112, 0.50), rgba(112, 112, 112, 0.50) 55%, transparent 40%, transparent 40%);
  background-size: 14px 1px;
}
.billing-summary-card__item-cost {
  font-weight: 500;
  font-size: 14px;
}

.billing-summary-card__total-cost {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  font-size: 18px;
  span{
    &:first-child{
      font-size: 14px;
    }
  }
}
.billing-summary-card__footer {
  margin-top: 30px;
  .btn {
    margin-bottom: 20px;
  }
  .terms-text {
    font-size: 13px;
    margin: 0;
    text-align: center;
    a{
      color: #007BFF;
    }
  }
}
.billing-period-selector__price {
  text-align: left;
  line-height: 24px;
  h4 {
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 0;
    small {
      font-style: italic;
    }
  }
  small{
    font-size: 14px;
    font-weight: 500;
  }
}
@media (max-width: 575.98px){
  .billing-payment {
    .heading-section { 
      text-align: center;
    }
    .circle-number{
      height: 22px;
      min-width: 22px;
      width: 22px;
      &:before{
        top: 0px;
        left: 0px;
      }
    }
  }
}

/*qd-payment-block*/

.qd-payment-block{
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 30px;
  margin-right: 25px;
  .qd-payment-header{
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .qd-payment-title{
      font-size: 20px;
      font-weight: 500;
      color: $gray-base;
      margin-bottom: 0;
    }
    .dotted-link{
      // margin-left: 47px;
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;
      &:hover{
        border-bottom: 1px dashed #4CA3FF;
      }
    }
  }
  .qd-payment-body{
    padding-left: 42px;
    .card-body{
      padding: 30px;
    }
    .card-payment__control {
      .custom-control-label{
        &:before{
          top: 50%;
          transform: translateY(-50%);
        }
        &:after{
          top: 50%;
          transform: translateY(-50%);
        }
      }  
    }
  }
}

@media(min-width: 576px){
  // .qd-payment-block{
  //   .qd-payment-header{
  //     display: flex;
  //     align-items: center;
  //     justify-content: space-between;
  //   }
  // }
  .billing-period-selector {
    display: flex;
    justify-content: space-between;
}
  .billing-period-selector__price{
    h4{
      text-align: right;
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .billing-payment {
    .card-payment__control {
      .payment-savedCard{
        margin-right: 0;
        margin-left: 15px;
      }
      .payment-address{
        margin-right: 0;
        margin-left: 15px;
      }
    }
  }
}
@media (max-width: 1199.98px) { 
  .qd-payment-block{
    margin-right: 0;
  }
}
@media (max-width: 991.98px){
  .qd-payment-block {
    .qd-payment-body {
      padding-left: 0;
  }
  }
}
/*payment address*/
.payment-address{
  max-width: 635px;
  display: flex;
  align-items: flex-start;
  padding: 15px 30px;
  background-color: rgba($color-dark, 0.02);
  border: 1px solid rgba($color-dark, 0.25);
  border-radius: 6px;
  margin-left: 16px;
  .payment-address__icon{
    color: #CCCCCC;
    font-size: 16px;
    margin-right: 15px;
  }
  address{
    margin-bottom: 0;
    h4{
      margin-bottom: 10px;
    }
    span{
      color: rgba($color-dark, 0.75);
    }
  }
}
@media(max-width: 767.98px){
  .billing-payment{
    .card-payment__control{
      .payment-savedCard{
        .payCard-action{
          flex: 0 0 150px;
        }
      }
    }
  }
}
@media(max-width: 575.98px){
  .billing-period-selector {
    .billing-period-selector__radio{
      .custom-control {
        display: inline-flex;
        &:first-child{
          margin-right: 12px;
        }
      }
    }
}
.billing-period-selector__price{
  margin-top: 10px;
}
  .billing-payment{
    .heading-section{
      margin: 10px 0 20px;
      .heading-section-title{
        font-size: 26px;
      }
      p{
        font-size: 14px;
      }
    }
    .card-payment__control{
      .payment-savedCard{
        margin: 0 0 10px;
        .payCard-action{
          flex: 0 0 420px;
          margin-left: 10px;
        }
      }
      .btn{
        margin-left: 25px;
      }
      .payment-address{
        margin: 0 0 10px;
      }
    }
  }
  .qd-payment-block{
    padding-bottom: 20px;
    margin-right: 0;
    .qd-payment-header{
      padding: 20px 0 10px;
      .qd-payment-title{
        font-size: 16px;
      }
    }
    .qd-payment-body{
      padding-left: 0;
      .card-body{
        padding: 15px 10px;
      }
    }
  }
  .payment-address{
    padding: 10px 15px ;
    margin-left: 0 ;
    address{
      h4{
        font-size: 16px;
        margin-bottom: 10px;
      }
      span{
        font-size: 12px;
      }
    }
  }
  .billing-summary-card__divider{
    margin: 15px 0  ;
  }
  .billing-summary-card__footer{
    margin-top: 15px;
    .terms-text{
      font-size: 10px;
    }
  }
  .billing-summary-card__item{
    font-size: 12px;
  }
  .billing-summary-card__item-cost{
    font-size: 12px;
  }
  .billing-summary-card__total-cost{
    span{
      &:first-child{
        font-size: 12px;
      }
    }
    font-size: 16px;
  }
  .billing-summary-card{
    .card-header{
      h4{
        font-size: 14px;
      }
    }
    .card-body{
      padding: 15px;
    }
  }
  .custom-control-label{
    img{
      width: 36px;
    }
  }
}


/*Edition User plan*/

.card__qualdo-edition{
  position: relative;
  overflow: hidden;
  z-index: 1;
  margin-bottom: 20px;
  p{
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    margin-bottom: 0;
    color: #677294;
  }
  h5{
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    margin-bottom: 0;
    color: rgba($color-dark, 0.75);
  }
  .card-header{
    font-size: 16px;
    font-weight: 600;
    line-height: 25px;
    padding: 6px 15px;
    text-align: center;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
  }
  .card-body{
    padding: 10px 15px;
    .user__plan{
      display: flex;
      align-items: center;
      img{
        width: 36px;
        height: 36px;
        position: relative;
        left: -5px;
      }
      .user__plan-info{
        margin-left: 5px;
        p{
          font-size: 12px;
          font-weight: 500;
          line-height: 18px;
          color: #677294;
        }
        h4{
          font-size: 18px;
          font-weight: 600;
          line-height: 20px;
          margin-bottom: 0;
          color: $color-dark;
        }
      }
    }
  }
}

@media(max-width: 576px){
  .card__qualdo-edition{
    p{
      font-size: 10px;
      line-height: 16px;
    }
    h5{
      font-size: 10px;
      line-height: 16px
    }
    .card-header{
      font-size: 14px;
      line-height: 22px;
      padding: 6px 10px;
    }
    .card-body{
      padding: 10px;
      .user__plan{
        img{
          width: 30px;
          height: 30px;
        }
        .user__plan-info{
          margin-left: 5px;
          p{
            font-size: 10px;
            line-height: 16px;
          }
          h4{
            font-size: 14px;
            line-height: 16px;
          }
        }
      }
    }
  }
}


/* Payment Success Message */
.qd-payment-success {
  padding: 50px 20px;
  @include flexbox();
  @include align-items(center);
  @include justify-content(center);
  .qd-payment-success__content {
    text-align: center;
    max-width: 600px;
  }
  img {
    margin-bottom: 20px;
  }
  h2 {
    font-size: 28px;
    font-weight: 500;
    color: $color-info;
    margin-bottom: 15px;
  }
  p {
    font-size: 16px;
    color: #484848;
    margin-bottom: 20px;
  }
  @media(max-width: 576px){
    img {
      width: 200px;
      height: auto;
    }
    padding: 30px 10px;
    h2 {
      font-size: 22px;
    }
    p {
      font-size: 14px;
    }
  }
}


/*Renew Subscription*/

.renew-subscription-info{
  background-color: rgba($color-danger, 0.1);
  border: 1px solid rgba($color-danger, 0.2);
  border-radius: 4px;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 665px;
  h6{
    font-size: 16px;
    font-weight: 500;
    color: #000000;
    line-height: 25px;
    margin: 0;
  }
}
.renew-subscription-text{
  font-size: 14px;
  font-weight: 500;
  color: #677294;
  margin: 0;
}
.custom-switch{
  &.orange-switch{
    .custom-control-input{
      &:checked{
        & ~.custom-control-label{
          &:before{
            color: #fff;
            border-color: $color-danger;
            background-color: $color-danger;
            
          }
        }
      }
      &:focus {
        ~ .custom-control-label {
          &:before {
            box-shadow: 0 0 0 0.2rem rgba($color-danger, 0.25);
          }
        }
      }
    }
  }
}
@media (max-width: 576px){
  .renew-subscription-info{
    padding: 10px;
    h6{
      font-size: 14px;
      line-height: 20px;
    }
  }
  .renew-subscription-text{
    font-size: 12px;
  }
}

/*Success Message*/
.centered-msg{
  margin: 15px auto;
  text-align: center;
  &__icon{
    margin-bottom: 22px;
    img{
      width: 50px;
      height: auto;
    }
  }
  &__title{
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    margin: 0 0 20px;
  }
  &__description{
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    line-height: 21px;
    strong{
      display: block;
      color: #000;
    }
  }
}
@media (max-width: 576px){
  .centered-msg{
    margin: 15px auto 0;
    &__icon{
      margin-bottom: 16px;
    }
    &__title{
      font-size: 18px;
      line-height: 26px;
      margin: 0 0 15px;
    }
    .alert-warning{
      padding: 15px;
      margin-bottom: 15px;
    }
  }
}
@media (max-width: 359px){
  .centered-msg{
    margin: 10px auto 0;
    &__icon{
      margin-bottom: 12px;
      img{
        width: 40px;
      }
    }
    &__title{
      font-size: 16px;
      line-height: 22px;
    }
    p{
      font-size: 13px;
      line-height: 18px;
    }
  }
}

/*Modal AB Test*/
.has-border-bottom{
  padding-bottom: 16px !important;
  &:before{
    content: '';
    width: calc(100% - 80px);;
    height: 1px;
    position: absolute;
    bottom: 0;
    background-color: rgba(112, 112, 112, 0.05);
  }
}

.vertical-stepper{
  &.vertical-stepper__abtest{
    margin-bottom: 20px;
    .vs-heading__circle{
      font-size: 14px;
      font-weight: 600;
      color: #2F6BD6;
      height: 28px;
      min-width: 28px;
      width: 28px;
      background-color: #ffffff;
      &:before{
        background-color: #D5E4FF;
        width: 24px;
        height: 24px;
        top: 2px;
        left: 2px;
      }
    }
    .vertical-stepper__heading {
      padding: 15px 20px 5px;
      &.has-hyperlink{
        cursor: pointer;
        width: max-content;
        i{
          font-size: 26px;
          color: #2A72F2;
          position: relative;
          left: -5px;
          padding: 1px;
          background-color: #ffffff;
        }
        .vs-heading__title{
          color: #2f6bd6;
          text-decoration: underline;
          padding-left: 6px;
        }
        &:hover{
          i{
            color: #255CBB;
          }
          .vs-heading__title{
            color: #255CBB;
          }
        }
      }
    }
    .vs-heading__title{
      font-size: 16px;
      color: rgba(47, 107, 214, 0.9);
    }
    .vertical-stepper__content{
      margin: -8px 0 -16px 29px !important;
      padding: 10px 15px 10px 22px;
      border-left: 1px dashed #D0D0D0;
    }
    .form-group{
      max-width: 270px;
      width: 100%;
      &.has-text-or{
        padding: 0;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        flex: 20px 0 0;
        h5{
          margin-top: 8px;
        }
      }
    }
  }
}
@media (max-width: 768px){
  .has-border-bottom{
    &:before{
      width: calc(100% - 50px);;
    }
  }
}
@media (max-width: 576px){
  .has-border-bottom{
    &:before{
      width: calc(100% - 30px);;
    }
  }
  .vertical-stepper{
    &.vertical-stepper__abtest{
      padding: 0 15px;
      .vertical-stepper__heading{
        padding: 15px 4px 5px;
        &.has-hyperlink{
          .vs-heading__title{
            padding-left: 2px;
          }
        }
      }
      .vs-heading__title{
        padding-left: 6px;
      }
      .vertical-stepper__content{
        margin: -8px 0 -16px 12px !important;
        padding: 8px 4px 10px 18px;
      }
      .form-group{
        max-width: 100%;
        &.has-text-or{
          flex: 100% 0 0;
          margin-bottom: 10px;
          h5{
            margin: 0;
          }
        }
      }
    }
  }
}
.vertical-stepper.vertical-stepper__abtest {
  margin-bottom: 20px;
}

.vertical-stepper.vertical-stepper__abtest .vertical-stepper__heading.disabled {
    opacity: 0.5;
    cursor: default;
}
.vertical-stepper.vertical-stepper__abtest .btn-link {
    font-size: 11px;
    padding: 0;
}
.btn {
    transition: all 0.3s ease;
}

/***
Modify bootstrap-tagsinput plugin's css
***/
.bootstrap-tagsinput {
  display: block;
  padding: 4px 4px 6px 4px;
  font-size: 14px;
  font-weight: normal;
  min-height: 34px;
  color: #555555;
  background-color: #fff;
  border: 1px solid #c2cad8;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;

  .tag {
    position: relative;
    display: inline-block;
    margin-top: 2px;
    padding: 4px 24px 4px 10px;
    line-height: 16px;
    font-weight: 400;
    [data-role="remove"] {
      box-shadow: none;
      width: 24px;
      height: 24px;
      position: absolute;
      right: 0;
      top: 0;
      &:after,
      &:before {
        height: 2px;
        background-color: #fff;
        content: "";
        position: absolute;
        display: block;
        -webkit-transform-origin: 50% 50%;
        -moz-transform-origin: 50% 50%;
        -ms-transform-origin: 50% 50%;
        -o-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        -webkit-transition: all 0.15s cubic-bezier(0.075, 0.82, 0.165, 1);
        -moz-transition: all 0.15s cubic-bezier(0.075, 0.82, 0.165, 1);
        transition: all 0.15s cubic-bezier(0.075, 0.82, 0.165, 1);
        width: 10px;
        top: 11px;
        right: 8px;
      }
      &:before {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }
      &:after {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
      }

      &:hover {
        text-decoration: none;
        box-shadow: none;
      }
    }
    input {
      width: 100% !important;
    }
  }
}
.bootstrap-tagsinput-width-auto .bootstrap-tagsinput {
  display: inline-block;
}
.mt-bootstrap-tables .mt-btm-transform .bars {
  height: auto;
}
.fixed-table-toolbar .columns label {
  margin: 10px;
  padding: 0 0 0 30px;
}
.fixed-table-header th.bs-checkbox {
  outline: none;

  .th-inner {
    overflow: visible;

    .mt-checkbox {
      right: -5px;
    }
  }
}

/*!
* Qualdo Admin dashboard
* Version: 0.1
* Author: Saturam
* UI/UX Designer : Sasikumar
* Comapny : Saturam
* Company Website : www.saturam.com
*/

// Vendor
// @import "customize-bootstrap";

// 1 - HELPERS
@import "1-helper/variables";
@import "1-helper/functions";
@import "1-helper/mixins";

// 2 - TOOLS
// @import "2-tools/bourbon/bourbon";
// @import "2-tools/animate";
@import "2-tools/toast";
@import "2-tools/alert";


// 3 - BASICS
@import '3-basics/reset';
// @import '3-basics/base';
@import '3-basics/buttons';
// @import '3-basics/links';
// @import '3-basics/selection-colors';
@import '3-basics/typography';
// @import '3-basics/global';
@import '3-basics/forms';
@import '3-basics/table';
@import '3-basics/nav-tabs';
@import '3-basics/dropdown';
@import '3-basics/card';
@import '3-basics/components';

// 4 - LAYOUT
@import '4-layout/header';
@import '4-layout/footer';
@import '4-layout/modal';

// 5 - VENDOR
@import '5-vendor/dropzone';
@import '5-vendor/select2';
@import '5-vendor/taginputs';
@import '5-vendor/d3-charts';

// 6 - PAGES
@import '6-pages/login-page';
@import '6-pages/dashboard';
@import '6-pages/settings';
@import '6-pages/monitor';
@import '6-pages/error-pages';
@import '6-pages/quick-start';
@import '6-pages/notification';
@import '6-pages/billing-subscribe';
@import '6-pages/policy';
@import '6-pages/discover';
@import '6-pages/powerbi';
// @import 'themes/theme';
// @import 'themes/admin';

.qd-serving-data_block{
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.qd-serving-data_card{
  width: 210px;
  height: 135px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  p{
    margin: 0;
    text-align: center;
    span{
      font-size: 24px;
      font-weight: 600;
      color: #484848;
    }
  }
  img{
    width: 36px;
    height: auto;
    position: relative;
    top: -2px;
  }
  h6{
    font-size: 20px;
    font-weight: 500;
    color: #717171;
    margin: 0;
  }
}
@media (max-width: 576px){
  .qd-serving-data_card {
    width: 180px;
    height: 105px;
    border-radius: 6px;
    img {
      width: 34px;
      height: auto;
      position: relative;
      top: -6px;
    }
    p{
      span {
        font-size: 22px;
      }
    }
    h6 {
      font-size: 18px;
      font-weight: 500;
      color: #717171;
      margin: 0;
    }
  }
}

/* AB Tested table */
.abtested-table {
  td {
    vertical-align: middle;
  }
  tr {
    position: relative;
  }
  .abtested_cell {
    position: relative;
    &:before {
      content: "";
      position: absolute;
      height: 100%;
      width: 1px;
      border-left: 1px solid #e1edfc;
      top: 0;
      left: 19px;
    }
    &.abtested_start {
      &:before {
        top: 50%;
      }
    }
    &.abtested_end {
      &:before {
        top: auto;
        bottom: 50%;
      }
    }
  }
  .abtest_parent,
  .abtest_child {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    background-color: #000;
    display: inline-flex;
    align-items: center;
    vertical-align: middle;
    justify-content: center;
    color: #fff;
    font-weight: 600;
    font-size: 12px;
    margin-right: 5px;
    position: relative;
    z-index: 1;
  }
  .abtest_parent {
    background-color: $brand-primary;
  }
  .abtest_child {
    background-color: #3E9BFE;
    width: 16px;
    height: 16px;
    border-radius: 16px;
    font-size: 10px;
    margin: 2px 7px 2px 2px;
  }
}

// for multiline chart
.line {
  stroke-width: 2;
  fill: none;
}

.axis path {
  stroke: black;
}


/* Notification */
.qd-aside-notification {
  width: 350px;
  // min-height: 500px;
  //height: 500px;
  padding: 20px 0 30px;
  background-color: #F1F1F1;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  overflow-y: auto;
  ul {
    list-style: none;
    padding: 0;
    color: #484848;
    margin-bottom: 0;
  }
  .list-group{
    height: calc(100% - 3rem);
    overflow-y: auto;
    display: block;
  }
  .user-notification__list{
    padding: 10px 10px 0;
    .list-group-item{
      position: relative;
      border-radius: 6px;
      cursor: pointer;
      &.active{
        background-color: $color-info;
        color: #fff;
        z-index: 1;
        .notification-content{
          color: #fff;
        }
        &:after{
         opacity: 0;
        }
        &:hover{
          background-color: $color-info;
        }
      }
      &:after{
        content:'';
        position: absolute;
        background-color: rgb(236, 233, 233);
        width: calc(100% - 59px);
        height: 1px;
        bottom: 0;
        right:0;
        opacity: 1;
      }
      &:hover{
        background-color: rgba($color-dark,0.1);
        &:after{
         opacity: 0;
        }
      }
    }
  }
  .list-group-item {
    background-color: transparent;
    color: #484848;
    border-top-width: 0;
    border-left-width: 0;
    border-right-width: 0;
    border-radius: 0;
    &:hover,
    &:focus,
    &.active {
      background-color: #fff;
      cursor: pointer;
      border-color: rgba(0,0,0,.125);

    }
    &.unread {
      h5 {
        font-weight: 600;
        color: #000;
      }
      p {
        font-weight: 600;
        line-height: 16px;
        margin-top: 5px;
        color: #000;
      }
    }
    &:last-child {
      border-bottom-width: 0px;
    }
  }
}
.list-group-flush {
  &:last-child {
    .list-group-item {
      &:last-child {
        border-bottom-width: 0px;
      }
    }
  }
}
.qd-aside-notification__header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 10px;
  .action-icons {
    display: flex;
    -ms-flex-align: center !important;
    align-items: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    .app-icon {
      &:last-child {
        margin-left: 10px;
      }
    }
  }
  .dropdown {
    position: relative;
    display: flex;
    flex: 1 0 auto;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 6px 10px;
    .nav-link{
      padding: 0 6px;
      line-height: normal;
      margin-left: 5px;
      &:hover{
        background-color: rgba(72, 72, 72, 0.1);
        border-radius: 4px;
      }
    }
    a{
      &.text-dark{
        &:hover{
          text-decoration: none;
        }
      }
    }
  }
  .dropdown-toggle {
    &:after {
      top: 4px;
      font-size: 18px;
      position: relative;
      color: #000;
    }
  }
}
.qd-section-notification{
  background-color: #F9F9F9;
  .qd-section-header{
    background-color: #fff;
  }
}
// icon
.app-icon {
  color: #484848;
  line-height: 20px;
  font-size: 14px;
  width: 32px;
  height: 32px;
  display: flex;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  border-radius: 4px;
  &:hover,
  &:focus {
    background-color: #fff;
    text-decoration: none;
  }
  &.dropdown-toggle {
    margin-left: 10px;
    &:after {
      display: none;
    }
  }
}

/* Help Icon */
.help-icon {
  width: 30px;
  height: 30px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #80808f;
  background-color: #f3f6f9;
  border-color: #f3f6f9;
  font-size: 16px;
  border-radius: 30px;
  &:hover,
  &:focus {
    text-decoration: none;
    color: #5e5e5e;
    background-color: #c7c7c7;
    border-color: #c7c7c7;
    outline: none;
  }
  i{
    color: $color-info;
    line-height: 15px;
  }
}

/*new styles*/
.qd-aside-notification{
  padding: 12px 0 0;
  background-color: #f1f1f1;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.qd-section-notification{
  background-color: #f9f9f9;
  .qd-section-header{
    background-color: #fff;
  }
}
.qd-notification-panel{
  .qd-section-header{
    padding: 10px 20px 10px 30px;
    .qd-section-header-title{
      white-space: normal;
      word-wrap: break-word;
      word-break: break-all;
    }
  }
}
@media (max-width: 991.98px){
  .qd-notification-panel{
    .qd-section-header{
      height: auto;
    }
  }
}
.qd-subheader__main .navbar-toggler-icon {
  margin-right: 10px;
  cursor: pointer;
}
@media(max-width: 576px){
  .profile-info .btn{
    padding: 6px 16px;
  }
}
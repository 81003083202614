/* Select */
.select2-container .select2-selection--single {
  font-size: 14px; }
.select2-container--bootstrap4 .select2-selection--single {
  height: 34px !important; }
  .select2-container--bootstrap4 .select2-selection--single .select2-selection__rendered,
  .select2-container--bootstrap4 .select2-selection--single .select2-selection__placeholder {
    line-height: 32px; }

/* Select2dropdown */
.select-control__value-container {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 15px !important;
  padding-right: 15px !important; }
.select-control__control {
  min-height: 34px !important;
  box-shadow: none !important;
  border: 1px solid #ced4da !important;
  .select-control__placeholder{
    color: #c9c9c9;
  }
  &.select-control__control--is-focused{
    border-color: #80bdff !important;
  }
  &:focus{
    border-color: #80bdff !important;
  } }
    .select-control__indicator {
      padding: 6px !important; }
      .select-control__indicator-separator { 
        display: none; }
        .select-control__multi-value {
          border-radius: 20px; }
          .select-control__multi-value__label { 
            padding-left: 10px; }
            .select-control__multi-value__remove {
              background-color: transparent;
              cursor: pointer;
              color: #000;}
              .col-select-control__placeholder-sm{
                .select-control__placeholder{
                  font-size: 12px;
                  // white-space: nowrap;
                  // overflow: hidden;
                  // text-overflow: ellipsis;
                  // width: 100%;
                }
              }

/*selct control*/

.select-control__menu{
  z-index: 2 !important;
}

/* Dropdown Tree Select */
.react-dropdown-tree {
  position: relative;
}
.react-dropdown-tree-prepend {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  svg {
    color: #6C757D;
  }
}
.react-dropdown-tree-select {
  .dropdown {
    .dropdown-trigger {
      display: block;
      height: 34px;
      &.arrow {
        &:after {
          content: '';
          width: 24px;
          height: 24px;
          position: absolute;
          top: 50%;
          right: 6px;
          opacity: 0.25;
          background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij4NCiAgPGcgaWQ9Ikdyb3VwXzEiIGRhdGEtbmFtZT0iR3JvdXAgMSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTc5IC01NzYpIj4NCiAgICA8cGF0aCBpZD0ia2V5Ym9hcmRfYXJyb3dfZG93bl9ibGFja18yNGRwIiBkPSJNOC4yNDYsOS4zMTJsNC4xNjYsNC4xNjYsNC4xNjYtNC4xNjZhMS4wNzEsMS4wNzEsMCwxLDEsMS41MTQsMS41MTRsLTQuOTI5LDQuOTI5YTEuMDY5LDEuMDY5LDAsMCwxLTEuNTE0LDBMNi43MjIsMTAuODI2YTEuMDY5LDEuMDY5LDAsMCwxLDAtMS41MTRBMS4wOTIsMS4wOTIsMCwwLDEsOC4yNDYsOS4zMTJaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg3OC41OTIgNTc1LjAwMykiIGZpbGw9IiMzMzMiLz4NCiAgICA8cmVjdCBpZD0iUmVjdGFuZ2xlXzEiIGRhdGEtbmFtZT0iUmVjdGFuZ2xlIDEiIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNzkgNTc2KSIgZmlsbD0ibm9uZSIvPg0KICA8L2c+DQo8L3N2Zz4NCg==") center center no-repeat;
          background-size: 24px auto;
          transform: translateY(-50%);
        }
        &.top {
          &:after {
            opacity: 0.8;
          }
        }
      } 
    }
    .dropdown-content {
      position: absolute;
      top: 100%;
      width: auto;
      min-width: 400px;
      margin-top: 8px;
      margin-bottom: 8px;
      border-radius: 4px;
      border: 1px solid #DBDFE9;
      background-color: $white;
      box-shadow: 0 10px 42px 0 rgba(82, 63, 105, 0.20);
    }
    .root  {
      max-height: 400px;
      padding: 20px 0;
      overflow-x: hidden;
      overflow-y: auto;
    }
    .infinite-scroll-component  {
      overflow: hidden !important;
    }
    .node {
      display: flex;
      align-items: center;
      padding: 4px 15px;
      white-space: nowrap;
      .toggle {
        width: 24px;
        height: 24px;
        display: inline-block;
        &.collapsed {
          &:after {
            opacity: 0.5;
            background: url("data:image/svg+xml;base64,PHN2ZyBpZD0iaWNvbl9hcnJvdy1yaWdodCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCI+DQogIDxwYXRoIGlkPSJQYXRoXzQiIGRhdGEtbmFtZT0iUGF0aCA0IiBkPSJNMTAsMTdsNS01TDEwLDdaIiBmaWxsPSIjNDg0ODQ4Ii8+DQogIDxwYXRoIGlkPSJQYXRoXzUiIGRhdGEtbmFtZT0iUGF0aCA1IiBkPSJNMCwyNFYwSDI0VjI0WiIgZmlsbD0ibm9uZSIvPg0KPC9zdmc+DQo=") center center no-repeat;
          }
          &:hover {
            &:after {
              opacity: 0.75;
            }
          }
        }
        &.expanded {
          &:after {
            background: url("data:image/svg+xml;base64,PHN2ZyBpZD0iaWNvbl9hcnJvdy1kb3duIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij4NCiAgPHBhdGggaWQ9IlBhdGhfNiIgZGF0YS1uYW1lPSJQYXRoIDYiIGQ9Ik0wLDBIMjRWMjRIMFoiIGZpbGw9Im5vbmUiLz4NCiAgPHBhdGggaWQ9IlBhdGhfNyIgZGF0YS1uYW1lPSJQYXRoIDciIGQ9Ik03LDEwbDUsNSw1LTVaIiBmaWxsPSIjNDg0ODQ4Ii8+DQo8L3N2Zz4NCg==") center center no-repeat;
          }
        }
        &.collapsed,&.expanded {
          &:after {
            content: '';
            width: 24px;
            height: 24px;
            display: inline-block;
            background-size: 24px auto;
          }
        }
      }
      label {
        position: relative;
        margin: 0;
        padding-left: 24px;
        .checkbox-item {
          position: absolute;
          left: 0;
          z-index: -1;
          &:checked {
            ~ .node-label {
              &:before {
                color: #fff;
                border-color: #007bff;
                background-color: #007bff;
              }
              &:after {
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
              }
            }
          }
        }
        .node-label {
          &:before,&:after {
            content: '';
            position: absolute;
            top: 2px;
            left: 0;
            display: block;
            width: 1rem;
            height: 1rem;
            border-radius: 4px;
            pointer-events: none;
          }
          &:before {
            background-color: #fff;
            border: 1px solid #adb5bd;
          }
          &:after {
            background: no-repeat 50%/50% 50%;
          }
        }
      }
      &:hover,
      &:focus,
      &.checked {
        background-color: #EBEFFF;
      }
      &[aria-level="1"] {
        padding-left: 6px !important;
      }
      &[aria-level="2"] {
        padding-left: 48px !important;
      }
      &[aria-level="3"] {
        padding-left: 72px !important;
      }
      &.disabled {
        padding: 6px 0 2px;
        .toggle {
          display: none;
        }
        label {
          .node-label {
            color: #999;
            cursor: default;
            font-size: 11px;
            font-weight: 500;
            text-transform: uppercase;
            &:before,&:after {
              content: none;
            }
          }
        }
        &:hover,&:focus {
          background-color: transparent;
        }
        &[aria-level="2"],&[aria-level="3"] {
          label {
            padding-left: 8px;
          }
        }
      }
    }

    .no-matches {
      display: block;
      text-align: center;
      color: hsl(0, 0%, 60%);
      padding: 20px 20px 30px;
    }
  }
  .tag-list {
    padding: 0;
    margin: 0;
    list-style-type: none;
    input {
      width: 100%;
      min-height: 34px;
      padding: 0 34px;
      border: 1px solid #ced4da;
      border-radius: 4px;
      outline: none;
      &::placeholder {
        color: #a9a9a9;
        opacity: 1;
      }
    }
  }
}
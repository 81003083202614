/*!
* Page Header
*/
.qd-header {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-transition: height 0.3s ease;
  transition: height 0.3s ease;
  position: relative;
  height: 80px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  .qd-header-container {
    width: 100%;
    height: 80px;
    z-index: 3;
    position: relative;
    -webkit-transition: height 0.3s ease;
      transition: height 0.3s ease;
    &.navbar {
      padding: 0;
    }
  }
  .qd-header__brand {
    height: 80px;
  }
  &.sticky-header {
    .qd-header-container {
      height: 64px;
      position: fixed;
      // background-color: #fa4616;
      background-color: #2659c9;
      -webkit-transition: height 0.3s ease;
      transition: height 0.3s ease;
      box-shadow: 0 0.5rem 1rem rgba(0,0,0,.05), inset 0 -1px 0 rgba(0,0,0,.1);
      background: rgba(33,62,177,1);
      background: -moz-linear-gradient(left, rgba(33,62,177,1) 0%, rgba(52,150,255,1) 100%);
      background: -webkit-gradient(left top, right top, color-stop(0%, rgba(33,62,177,1)), color-stop(100%, rgba(52,150,255,1)));
      background: -webkit-linear-gradient(left, rgba(33,62,177,1) 0%, rgba(52,150,255,1) 100%);
      background: -o-linear-gradient(left, rgba(33,62,177,1) 0%, rgba(52,150,255,1) 100%);
      background: -ms-linear-gradient(left, rgba(33,62,177,1) 0%, rgba(52,150,255,1) 100%);
      background: linear-gradient(to right, rgba(33,62,177,1) 0%, rgba(52,150,255,1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#213eb1', endColorstr='#3496ff', GradientType=1 );
      top: 0;
      .qd-header__brand {
        height: 64px;
      }
    }
  }
  .qd-header__brand-logo-default {
    display: inline-block;
  }
  .navbar-nav {
    li {
      &:hover {
        > ul {
          &.dropdown-menu {
            display: block;
          }
        }
      }
    }
  }
  .dropdown-submenu {
    position: relative;
    > .dropdown-menu {
      top: 0;
      left: 100%;
      margin-top: -6px;
    }
  }
  .navbar-collapse {
    padding: 0 30px 0 50px;
  }
  .nav-item {
    // padding: 0.75rem 1.25rem;
    border-radius: 4px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    cursor: pointer;
    // padding: 0.25rem 0.5rem;
    margin-right: 5px;
    .nav-link {
      border-radius: 4px;
      padding-right: 12px;
      padding-left: 12px;
      color: #fff;
    }
    &:hover,
    &.active {
      .nav-link {
        background-color: rgba(255, 255, 255, 0.25);
      }
    }
  }
  .dropdown-menu {
    width: 250px;
    margin: 0 auto;
    padding: 20px 0;
    border-radius: 4px;
    background-color: #fff;
    -webkit-box-shadow: 0 0 50px 0 rgba(82, 63, 105, 0.2);
    box-shadow: 0 0 50px 0 rgba(82, 63, 105, 0.2);
    border: none;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-transform-style: preserve-3d;
    -webkit-animation: kt-header-menu-submenu-fade-in 0.3s ease 1,
      kt-header-menu-submenu-move-down 0.3s ease-out 1;
    animation: kt-header-menu-submenu-fade-in 0.3s ease 1,
      kt-header-menu-submenu-move-down 0.3s ease-out 1;

      &.has-caret {
        &:before,
        &:after {
          position: absolute;
          display: inline-block;
          content: '';
        }
        &:before {
          top: -7px;
          left: 9px;
          border-right: 7px solid transparent;
          border-bottom: 7px solid #CCC;
          border-left: 7px solid transparent;
          border-bottom-color: #FFF;
        }
        &:after {
          top: -6px;
          left: 10px;
          border-right: 6px solid transparent;
          border-bottom: 6px solid white;
          border-left: 6px solid transparent;
        }
      }

      /* Notification Dropdown */
      &.dropdown-menu__user-notification {
        width: 350px;
        left: 50%;
        // margin-left: -175px;
        // top: calc(100% + 10px);
        margin-left: -158px;
        top: calc(100% + -20px) !important;
        &.has-caret {
          &:before,
          &:after {
            left: calc(50% - 7px);
          }
        }
      }

  }
  .dropdown-toggle {
    &:after {
      margin: -4px 0 0 6px;
      border: solid #fff;
      border-width: 0 1px 1px 0;
      display: inline-block;
      padding: 3px;
      vertical-align: middle;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
    }
  }
  .dropdown-item {
    color: #707070;
    font-size: 14px;
    padding: 8px 20px;
    margin-bottom: 2px;
    &:hover,
    &:focus,
    &.active {
      background-color: #f7ecec;
    }
    i {
      margin-right: 5px;
      width: 24px;
      display: inline-block;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  .nav-link {
    font-weight: 400;
    font-size: 13px;
    letter-spacing: 1px;
    text-transform: initial;
    color: #fff;
  }
  .navbar-toggler {
    margin-right: calc(30px - .75rem);
  }
  .navbar-light {
    .navbar-nav {
      .nav-link {
        color: #fff;
    }
    }
  }
  .navbar-expand-lg{
    width: 100%;
    padding: 0;
    .navbar-brand{
      padding: 0;
      margin: 0;
    }
  }
  .qd-header__topbar-icon{
    .nav-link{
      padding: .5rem 1rem;
      margin-right: 0;
      &:hover{
        background-color: transparent;
      }
    }
  }
  /* User Notification */
  .dropdown-menu__user-notification {
    padding: 0;
    &.dropdown-menu.show{
      position: absolute;
      top: 0px;
      left: 0px;
      transform: translate3d(0px, 34px, 0px);
      will-change: transform;
    }
  }
  .dropdown-menu_user-notification__head,
  .dropdown-menu_user-notification__footer {
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    padding: 12px;
  }
  .dropdown-menu_user-notification__head {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background-color: lighten($brand-secondary, 90%);
    border: 1px solid rgba(0,0,0,.125);
    color: #757474;
    cursor: default;
  }
  .dropdown-menu_user-notification__footer {
    cursor: pointer;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: lighten($brand-secondary, 63%);
    color: $brand-secondary;
  }
  /*menu icon*/
  .menu-icon {
    height: 24px;
    background-size: 24px;
    background-position: center center;
    background-repeat: no-repeat;
    vertical-align: middle;
    margin-top: -2px;
  }
  .icon_powerbi { 
    background-image: url('../../assets/img/icons/icon_powerbi.svg');
    @extend .menu-icon;
  }
  .icon_powerbi-error { 
    background-image: url('../../assets/img/icons/icon_powerbi-error.svg');
    @extend .menu-icon;
  }
  .icon_powerbi-mapping { 
    background-image: url('../../assets/img/icons/icon_powerbi-mapping.svg');
    @extend .menu-icon;
  }
}
.user-notification__list {
  max-height: 275px;
  overflow-y: auto;
}

.qd-header {
  .dropdown-menu__user-notification{
    .dropdown-menu_user-notification__head{
      margin-bottom: 0;
      background-color: #fff;
      &:hover{
        background-color: transparent;
      }
    }
    .user-notification__list{
      .list-group-item{
        margin-bottom: 0;
        border-right-width: 0;
        border-left-width: 0;
        border-radius: 0;
        &:first-child{
          border-top-width: 0;
        }
      }
      .notification-content{
        white-space: normal;
        word-wrap: break-word;
        word-break: break-word;
      }
      .notification-icon{
        span i{
          margin-right: 0;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
  .navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  }
  .navbar-light .navbar-toggler {
    border-color: transparent;
  }
}
@media (min-width: 768px) {
  .qd-header{
    .navbar-nav{
      .dropdown-menu{
        top: calc(100% + 5px);
      }
    }
  }
}

@media (min-width: 992px){
  .qd-header .navbar-expand-lg .navbar-nav .nav-link{
    padding-right: 12px;
    padding-left: 12px;
  }
}

@media (max-width: 991.98px) {
  .qd-header {
    .dropdown-menu.dropdown-menu__user-notification {
      margin-left: -260px;
      &.has-caret{
        &:before{
          left: calc(79% - 7px);
        }
        &:after{
          left: calc(79% - 7px);
        }
      }
    }
  }
}

@media (max-width: 767.98px) {
  .qd-header {
    .dropdown-menu.dropdown-menu__user-notification {
      width: 320px;
      margin-left: -20px;
      &.has-caret{
        &:before{
          left: calc(20% - 10px);
        }
        &:after{
          left: calc(20% - 10px);
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .qd-header {
    .dropdown-menu.dropdown-menu__user-notification {
      width: 340px;
      margin-left: -10px;
      &.has-caret{
        &:before{
          left: calc(10% - 12px);
        }
        &:after{
          left: calc(10% - 12px);
        }
      }
    }
  }
}

@media (max-width: 320px) {
  .qd-header {
    .dropdown-menu.dropdown-menu__user-notification {
      width: 300px;
      margin-left: -10px;
      &.has-caret{
        &:before{
          left: calc(10% - 9px);
        }
        &:after{
          left: calc(10% - 9px);
        }
      }
    }
  }
}

/* User Notification */
.user-notification__list {
  .list-group-item {
    padding: 10px 15px;
    display: table;
    width: 100%;
    h5 {
      font-size: 15px;
      margin-bottom: 0;
    }
    small{
      font-size: 70%;
    }
    p {
      font-size: 12px;
      margin-bottom: 0;
      opacity: 0.8;
      line-height: 15px;
      margin-top: 4px;
    }
    &:hover {
      cursor: pointer;
      background: rgb(242, 242, 242);
    }
  }
  // .list-group-item,
  .list-group-item-content {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  .notification-icon {
    width: 34px;
    text-align: center;
    display: table-cell;
    float: left;
    span {
      width: 34px;
      height: 34px;
      border-radius: 34px;
      margin-right: 10px;
      display: flex;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-pack: center;
      justify-content: center;
      i {
        color: #fff;
      }
      svg {
        height: auto;
      }
    }
  }
  .notification-content {
    padding-left: 10px;
    display: table-cell;
    float: left;
    width: calc(100% - 34px);
    white-space: normal;
    word-wrap: break-word;
    word-break: break-word;
    color: #212529;
  }
}

.settings-notification {
  .notification-icon {
    span {
      background: #fa4616;
      background: linear-gradient(90deg, #fa4616 0%, #ff9d2c 100%);
    }
  }
}
.app-notification {
  .notification-icon {
    span {
      background: rgb(0,33,165);
      background: linear-gradient(90deg, rgba(0,33,165,1) 0%, rgba(0,123,255,1) 96%);
    }
  }
}
.warning-notification {
  .notification-icon {
    span {
      background: rgb(255, 168, 0);
      background: linear-gradient(90deg, rgba(255, 168, 0, 1) 0%, rgba(245, 248, 74,1) 100%);
    }
  }
}



.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.qd-header__brand {
  margin-left: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.qd-header__brand-logo-default {
  width: auto;
  height: 34px;
}

.qd-header__topbar-item {
  margin: 0 5px;
  &:last-child {
    margin-right: 0;
  }
  .qd-header__topbar-icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-item-align: center;
    align-self: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 34px;
    width: 34px;
    border-radius: 50%;
    cursor: pointer;
    background: 0 0;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    border: 1px solid rgba(255, 255, 255, 0.5);
    > i {
      color: #fff;
      font-size: 14px;
      padding: 2px 0;
    }
  }
  .alert-badge {
    @include flexbox();
    @include align-items(center);
    @include justify-content(center);
    position: absolute;
    top: -4px;
    right: -4px;
    color: #fff;
    background-color: $brand-primary;
    border-radius: 50%;
    min-width: 18px;
    height: 18px;
    line-height: 18px;
    text-align: center;
    font-weight: 400;
    opacity: 1;
    padding: 1px;
  }
  .dropdown-menu {
    top: calc(100% + 5px);
  }
}
.qd-header__topbar-wrapper {
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.qd-header__topbar-welcome {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-item-align: center;
  align-self: center;
  padding: 0 0.55rem 0 0;
  font-weight: 500;
  font-size: 13px;
  color: rgba(255, 255, 255, 0.75);
}
.qd-header__topbar-username {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-item-align: center;
  align-self: center;
  padding: 0 0.55rem 0 0;
  font-weight: 500;
  font-size: 13px;
  color: #fff;
}
.qd-header__topbar-item--user {
  .qd-header__topbar-icon {
    height: 34px;
    width: 34px;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.25);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    border-radius: 4px;
    border: none;
  }
  .dropdown-toggle {
    &:after {
      display: none;
    }
  }
}
.qd-quick-search {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 200px;
  padding: 0;
  .qd-quick-search__form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 30px;
    background-color: transparent;
    .input-group {
      border-radius: 30px;
      background-color: transparent;
      border: 1px solid rgba(255, 255, 255, 0.5);
    }
    .input-group-prepend,
    .input-group-append {
      .input-group-text {
        background-color: transparent;
        border: 0;
        color: #fff;
        padding: 6px 10px;
        font-size: 12px;
      }
    }
    .form-control {
      border: 0;
      background: 0 0;
      outline: 0 !important;
      -webkit-box-shadow: none;
      box-shadow: none;
      background-color: transparent;
      padding-left: 0;
      padding-right: 0;
      border-top: 0;
      border-left: 0;
      border-right: 0;
      padding: 0;
      border-radius: 0;
      color: #fff;
      height: 34px;
      font-size: 13px;
    }
  }
}

@media (max-width: 767.98px){
  .qd-header{
    .qd-header__topbar-item {
         float: left;
      }
      .qd-header__topbar-icon{
        .nav-link{
          margin-bottom: 0;
        }
      }
      .navbar-nav{
        .nav-link{
          display: block;
          float: none;
        }
        .dropdown-menu{
          width: 100%;
          position: static;
        }
      }
    }
}


/* Sub Header */
.qd-subheader {
  padding: 20px 0;
  .qd-subheader__main {
  }
  .qd-subheader__breadcrumbs {
    list-style-type: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    list-style-type: none;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    .qd-subheader__breadcrumbs-icon {
      color: #fff;
      padding: 0 10px 0 0;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
      i {
        font-size: 22px;
      }
    }
    .qd-subheader__breadcrumbs-link {
      padding: 0 0.35rem 0 0;
      font-size: 1.2rem;
      font-weight: 500;
      color: #fff;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
    }
    .qd-subheader__breadcrumbs-separator {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: content;
      -ms-flex-pack: content;
      justify-content: content;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 0 0.35rem 0 0;
      &:after {
        display: block;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        content: " ";
        background: #e7e8ef;
        background: rgba(255, 255, 255, 0.75);
      }
    }
  }
}

/* Tab Subheader */
.qd-tab-subheader {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  margin: 10px 0;
  max-width: 100%;
  -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
    .qd-tab-subheader_container {
      display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%;
    }
    .qd-tab-subheader__title {
      margin: 0;
    padding: 0 1rem 0 0;
    font-size: 1.2rem;
    font-weight: 500;
    color: #434349;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    }
  .qd-tab-subheader__main {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: .25rem 0;
  }
  .qd-tab-subheader__toolbar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0;
  }
}

@media (min-width: 769px) {
  .qd-tab-subheader {
      max-width: 100%;
  }
}
@media (min-width: 1025px) {

}

/* Filter Section */
.qd-filter-overlay {
  -webkit-transition: top 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  -moz-transition: top 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  transition: top 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  position: fixed;
  top: -100%;
  left: 0;
  z-index: 800;
  width: 100%;
  height: calc(100% - 80px);
  overflow-y: scroll;
  background: rgba(250, 250, 250, 0.7);
  will-change: top;
  overflow-y: auto;
  &.visible {
    top: 80px;
  }
  .close {
    cursor: pointer;
    width: 44px;
    height: 44px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center!important;
    align-items: center!important;
    margin-top: 20px;
    margin-right: 20px;
    &:after,
    &:before {
      margin: 0 9px;
    }
    &:hover {
      &:after,
      &:before {
        margin: 0 6px;
        -webkit-transition: all 0.15s cubic-bezier(0.075, 0.82, 0.165, 1);
        -moz-transition: all 0.15s cubic-bezier(0.075, 0.82, 0.165, 1);
        transition: all 0.15s cubic-bezier(0.075, 0.82, 0.165, 1);
        width: 32px;
      }
    }
  }
}
.qd-filter-overlay-section {
  margin-bottom: 20px;
  > label {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 5px;
  }
}
.qd-filter-overlay-content {
  padding: 50px 50px 20px 50px;
  position: relative;
  margin: 0 auto;
  max-width: 1280px;
  width: 90%;
  .select2-container--bootstrap4 {
    .select2-selection {
      padding: 10px 35px 8px 10px;
      min-height: 56px;
      line-height: 30px;
    }
    .select2-selection--multiple {
      border-color:#ccc;
      .select2-selection__choice {
        display: inline-block;
        height: 30px;
        font-size: 12px;
        font-weight: 500;
        line-height: 28px;
        padding: 0 30px 0 12px;
        background-color: #fff;
        border: 1px solid #d19885;
        border-radius: 2px;
        color: $base-text-color;
        background-color: #fcdfd6;
        margin: 4px 4px 1px 0;
        font-family: 'Poppins', sans-serif;
      }
      .select2-selection__choice__remove {
        float: right;
        background-image: url(../../assets/img/icons/icon_close.svg) !important;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 20px 20px;
        width: 28px;
        height: 28px;
        margin-right: -30px;
        opacity: 0.5;
        text-indent: -9999px;
        &:hover {
          opacity: 1;
        }
      }
      .select2-selection__clear {
        background-image: url(../../assets/img/icons/icon_close.svg) !important;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 24px 24px;
        width: 34px;
        height: 34px;
        margin: 0;
        opacity: 0.5;
        border-radius: 4px;
        text-indent: -9999px;
        position: absolute;
        top: 5px;
        right: 5px;
        :hover {
          opacity: 1;
          background-color: #ddd;
        }
      }
      .select2-selection__rendered {
        padding: 6px 5px 0;
      }
    }
    &.select2-container--focus {
      .select2-selection--multiple {
        border-color:#1CBC9C;
      }
    }
  }
}
.qd-filter-overlay-footer {
  border-top: 1px solid #e8e8e8;
  padding-top: 15px;
  margin-top: 15px;
  > .btn {
    margin-left: 10px;
  }
}
body.has-sticky-header {
  .qd-filter-overlay {
    height: calc(100% - 64px);
    &.visible {
      top: 64px;
    }
  }
}


@media (min-width: 992px) {
  .qd-filter-overlay-content {
    width: 80%;
  }
}
/* @media (min-width: 768px) {
  .qd-filter-overlay-content { width: 90%; } } */
.blurry {
  -webkit-filter: blur(20px);
  filter: blur(20px); }


/* Filtered Section */
.qd-filtered-section {
  padding: 15px 13px;
  border-radius: 4px;
  display: -ms-flexbox;
  display: flex;
  background-color: #EEF1F5;
  border: 1px solid #e8e8e8;
  margin-bottom: 15px;
  position: relative;
  -webkit-transition: all .3s linear 0s;
  transition: all .3s linear 0s;
  .qd-filtered-body {
    width: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center!important;
  align-items: center!important;
  }
  .qd-filtered_chips {
    width: 100%;
  }
  .qd-filtered_button {
    text-align: right;
    width: 140px;
    -ms-flex-negative: 1!important;
    flex-shrink: 1!important;
    svg{
      margin-right: 5px;
    }
  }
  .card-body {
    padding: 15px 15px 11px 15px;
  }
}
.chip {
  margin: 2px;
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  box-sizing: border-box;
  padding: 2px 12px 2px 12px;
  line-height: 24px;
  color: rgba(0,0,0,.87);
  text-transform: capitalize;
  vertical-align: middle;
  cursor: default;
  background: #e0e0e0;
  border: 1px solid #e0e0e0;
  border-radius: 1.5rem;
  -ms-flex-align: center;
  align-items: center;
  font-size: 12px;
  .close {
    cursor: pointer;
    padding: 10px;
    height: 1px;
    color: #000;
    &:after,
    &:before {
      height: 1px;
      width: 14px;
      top: 10px;
      left: 5px;
    }
  }
  &-green {
    border-color: lighten($color-success, 20%);
    background-color: lighten($color-success, 30%);
  }
  &-blue {
    border-color: lighten($color-info, 20%);
    background-color: lighten($color-info, 30%);
  }
  &-yellow {
    border-color: lighten($color-warning, 20%);
    background-color: lighten($color-warning, 40%);
  }
  &-red {
    border-color: lighten($color-danger, 20%);
    background-color: lighten($color-danger, 30%);
  }
  &-purple {
    border-color: lighten($color-purple, 20%);
    background-color: lighten($color-purple, 30%);
  }
}

.close {
  -webkit-transition: background-color 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
  -moz-transition: background-color 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
  transition: background-color 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
  position: relative;

  display: block;
  width: 24px;
  height: 2px;
  margin-top: -1px;
  margin-left: 10px;
  &:after,
  &:before {
    height: 2px;
    background-color: #373737;
    content: '';
    position: absolute;
    display: block;
    -webkit-transform-origin: 50% 50%;
    -moz-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    -o-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transition: all 0.15s cubic-bezier(0.075, 0.82, 0.165, 1);
    -moz-transition: all 0.15s cubic-bezier(0.075, 0.82, 0.165, 1);
    transition: all 0.15s cubic-bezier(0.075, 0.82, 0.165, 1);
    width: 26px;
  }
  &:before {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  &:after {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
@media (max-width: 768px){
  .qd-filtered-section{
    .qd-filtered_button{
      width: 40px;
      svg{
        margin-right: 0;
      }
      span{
        display: none;
      }
      .btn{
        padding: 6px 6px;
      }
    }
  }
}

/* Header */
.qd-tab__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  height: 44px;
}
.qd-tab__content {
  padding: 20px;
}
.qd-tab__header-wrapper {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.qd-tab__header-menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  height: 44px;
  margin: 0;
  width: auto;
  overflow: hidden;
  .nav-tabs {
    list-style: none;
    margin: 0;
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    flex-wrap: nowrap;
    border: none;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    .nav-item {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: stretch;
      -ms-flex-align: stretch;
      align-items: stretch;
      padding: 0;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      margin-right: 4px;
      margin-bottom: 0;
      &:hover {
        background: rgba(0, 0, 0, 0.25);
        // .nav-link {
        //   color: $base-text-color;
        // }
      }
      &:first-child {
        border-top-left-radius: 10px;
        .nav-link {
          border-top-left-radius: 10px;
        }
      }
      .nav-link {
        padding: 0 25px;
        background: rgba(0, 0, 0, 0.25);
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
        cursor: pointer;
        border: none;
        // border-bottom: 1px solid #F9F9F9;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        text-decoration: none;
        position: relative;
        vertical-align: middle;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        outline: 0 !important;
        text-decoration: none;
        color: #fff;
        font-size: 14px;
        &.active {
          background: #f9f9f9;
          color: $base-text-color;
        }
        .qd-menu__link-text {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-flex: 1;
          -ms-flex-positive: 1;
          flex-grow: 1;
          padding: 0;
        }
        &.has-spinner {
          padding-left: 20px;
          padding-right: 10px;
          .spinner-img {
            width: 30px;
            height: 30px;
          }
        }
      }
    }
  }
}

.qd-body {
  background-color: #f9f9fc;
  // -webkit-box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
  // box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 30px 0 rgba(82,63,105,.05);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  &.no-tabs {
    border-radius: 10px;
  }
}
.qd-container {
  padding: 0 30px;
}

.qd-subheader {
  .qd-container {
    padding: 0 30px;
  }
  .qd-subheader__main {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0;
    .qd-subheader__title {
      margin: 0;
      padding: 0 1rem 0 0;
      font-size: 1.2rem;
      font-weight: 500;
      color: #434349;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
  .qd-header__topbar-welcome,
  .qd-header__topbar-username {
    display: none;
  }
  .qd-header{
    .navbar-collapse {
      padding: 0 30px 0 15px;
    }
    .nav-item {
      .nav-link {
        padding-left: 10px;
        padding-right: 10px;
        letter-spacing: 0.5px;
      }
    }
  }
}

@media (min-width: 1025px) {
  .qd-header {
    &.sticky-header {
      -webkit-animation: kt-header-minimize .5s ease 1;
      animation: kt-header-minimize .5s ease 1;
    }
  }
}
@media (max-width: 767.98px) {
  .qd-header {
    .nav-item {
      margin-bottom: 10px;
      float: left;
    }
    .navbar-collapse {
      background: linear-gradient(90deg, #2651C2, #3D94F7);
      padding: 10px 30px;
    }
    // .dropdown-menu {
    //   width: 100%;
    // }
  }
  .qd-header__topbar-welcome  {
    margin-left: 7px;
  }
}

@-webkit-keyframes kt-header-minimize{from{top:-80px}to{top:0}}
@keyframes kt-header-minimize{from{top:-80px}to{top:0}}

// keyframes
@-webkit-keyframes kt-header-menu-submenu-fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes kt-header-menu-submenu-fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@-webkit-keyframes kt-header-menu-submenu-fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes kt-header-menu-submenu-fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-webkit-keyframes kt-header-menu-submenu-move-up {
  from {
    margin-top: -10px;
  }

  to {
    margin-top: 0;
  }
}

@keyframes kt-header-menu-submenu-move-up {
  from {
    margin-top: -10px;
  }

  to {
    margin-top: 0;
  }
}

@-webkit-keyframes kt-header-menu-submenu-move-down {
  from {
    margin-top: 10px;
  }

  to {
    margin-top: 0;
  }
}

@keyframes kt-header-menu-submenu-move-down {
  from {
    margin-top: 10px;
  }

  to {
    margin-top: 0;
  }
}


.toast {
  z-index: 1040;
}

@media (max-width: 1199.98px) {
  .qd-tab__header-menu {
    .nav-tabs {
      .nav-item {
        .nav-link {
          padding-left: 15px;
          padding-right: 15px;
        }
      }
    }
  }
}
@media (max-width: 991.98px) {
  .qd-tab__header {
    height: 38px;
  }
  .qd-tab__header-menu {
    width: 100%;
    height: 38px;
    .nav-tabs {
      .nav-item {
        margin-right: 2px;
        .nav-link  {
          font-size: 12px;
          height: 100%;
        }
      }
    }
  }
}

@media (max-width: 575.98px) {
  .qd-header {
    .navbar-collapse {
      padding: 15px;
    }
    .navbar-toggler {
      margin-right: calc(15px - .75rem);
    }
  }
  .qd-header__brand {
    margin-left: 15px;
  }
  .qd-container {
    padding: 0;
  }
  .qd-tab__content {
    padding: 15px;
  }
  .qd-body {
    border-radius: 0;
  }
}


/* || Top Layout || */
.global-notifications-bar {
  display: flex;
  align-content: center; 
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 10px;
  padding-right: 16px;
  height: 50px;
  text-align: center;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  overflow: hidden;
  position: relative;
  flex-shrink: 0;
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: -0.16px;
  font-family: $font-family-poppins;
  // background: $brand-primary;
  background: $brand-primary;
  color: #fff;
  font-weight: 400;
  .btn {
    padding: 4px 12px;
    text-decoration: none;
    display: block;
    text-align: center;
    min-width: 100px;
    margin-left: 10px;
    background-color: #fff;
    color: #001772;
    &:hover,
    &:focus,
    &:active {
      color: #fff;
      border-color: #d83105;
      background-color: #d83105;
    }
  }
  @media (max-width: 991.98px) {
    display: none;
  }
}
.global-notification-btn {
  width: 24px;
  height: 24px;
  position: absolute;
  right: 10px;
  cursor: pointer;
  .close {
    top: 11px;
    right: -1px;
    opacity: .75;
    &:before,
    &:after {
      background-color: #fff;
      width: 22px;
    }
  }
  &:focus,
  &:hover {
    .close:not(:disabled):not(.disabled),
    .close:not(:disabled):not(.disabled) {
      opacity: 1;
    }
  }
}
/*icon close*/
.btn-clear{
	display: none;
}
.has-btn-close{
	.btn-clear{
		display: inline-block;
		width: 24px;
		height: 24px;
		padding: 2px;
		border-radius: 50%;
		position: absolute;
		top: 50%;
		right: 0;
		transform: translateY(-50%);
		transition: none;
		animation: btnfadeIn 0.5s linear;
		.icon-close{
			display: inline-block;
			width: 18px;
			height: 18px;
			background: url('../../assets/img/icons/icon_close-circle-dark.svg') center center no-repeat;
			background-size: 18px 18px;
			opacity: 0.25;
		}
		&:hover{
			background-color: rgba(60,64,67,0.078);
			box-shadow: none;
			.icon-close{
				opacity: 0.35;
			}
		}
		&:focus,
		&:active{
			background-color: rgba(60,64,67,0.1);
			box-shadow: none;
		}
	}
}
@keyframes btnfadeIn{
	0%{
		opacity: 0;
	}
	100%{
		opacity: 1;
	}
}
/* Comply search bar*/
.qd_search-container {
	padding: 5px 15px;
}
.qd_search-box {
	max-width: 800px;
	height: 60px;
	margin: 0 auto;
	display: flex;
	align-items: center;
	padding: 0;
	background: #fff;
	border-radius: 8px;
	position: relative;
	box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
    &:before {
		content: "";
		position: absolute;
		top: 50%;
		left: 14px;
		width: 32px;
		height: 32px;
		transform: translateY(-50%);
		background: url('../../assets/img/icons/icon_search.svg') center center no-repeat;
		background-size: 32px 32px;
		z-index: 1;
	}
	&.is-focused{
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
		.react-autosuggest__container--open{
			border: 1px solid #647EE3;
		}
	}
	&.ad-filter-open{
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
		&:before,input{
			opacity: 0.25;
		}
	}
    .qd_search-bar-divider {
		height: calc(100% - 4px);
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		top: 50%;
		right: 116px;
		background: #fff;
		transform: translateY(-50%);
        &:before {
			content: "";
			width: 1px;
			background-color: rgba(158, 158, 167, 0.5);
			height: calc(100% - 18px);
			position: absolute;
			left: 50%;
			top: 9px;
			transform: translateX(-50%);
		}
        span {
			font-size: 10px;
			font-weight: 500;
			width: 20px;
			height: 20px;
			background: #fff;
			z-index: 2;
			color: #B1B1B1;
			@include flexbox();
    		@include align-items(center);
    		@include justify-content(center);
		}
	}
    input {
		font-family: 'Poppins', sans-serif;
		font-size: 14px;
		font-weight: 400;
		border: none;
		overflow: hidden;
		padding: 6px 130px 6px 60px;
		color: #484848;
		height: calc(100% - 2px);
        border-radius: 8px;
        line-height: normal;
		&::placeholder{
			color: rgba(72, 72, 72, 0.5);
		}
	}
    .qd-filtered_button {
		position: absolute;
		right: 12px;
		top: 50%;
		transform: translateY(-50%);
		.btn {
			border-radius: 8px;
			padding: 6px 12px;
			width: 100px;
			height: 38px;
			text-align: center;
			svg{
				margin-right: 5px;
			}
			&.active{
				background-color: #3E63F7;
				border: 1px solid rgba(62, 99, 247, 0.35) !important;
				color: #fff !important;
				svg{
					fill: #fff;
				}
				.filter-count{
					display: none;
					width: 16px;
					height: 16px;
					border-radius: 8px;
					background: #fff;
					font-size: 11px;
					font-weight: 500;
					color: #3E63F7;
					line-height: 12px;
					padding: 0.094rem;
					margin-right: 6px;
					display: inline-flex;
					-webkit-box-align: flex-end;
					-ms-flex-align: flex-end;
					align-items: flex-end;
					-webkit-box-pack: center;
					-ms-flex-pack: center;
					justify-content: center;
				}
				&:hover,
				&:focus,
				&:active{
					background-color: #3E63F7 !important;
					border: 1px solid rgba(62, 99, 247, 0.35) !important;
					color: #fff !important;
				}
			}
		}
	}
	.react-autosuggest {
		&__container{
			width: 100%;
			font-family: 'Poppins', sans-serif;
			height: 60px;
			line-height: 60px;
			border: 1px solid transparent;
			&--open{
				height: 100%;
				line-height: 60px;
				border-radius: 8px;
				border: 1px solid #647EE3;
				position: relative;
				margin-bottom: 1px;
			}
		}
		&__suggestions-container{
			&--open{
				font-family: 'Poppins', sans-serif;
				width: calc(100% + 2px);
				left: -1px;
				border: none;
				padding: 20px 20px 15px;
				top: 59px;
				border-bottom-left-radius: 8px;
				border-bottom-right-radius: 8px;
				box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
				animation: dropdownFade .2s linear;
			}
		}
		&__suggestion{
			padding: 4px 6px;
			.icon-close{
				display: none;
			}
			&--highlighted{
				background-color: #F4F4F4;
				border-radius: 2px;
				.icon-close{
					width: 14px;
					height: 14px;
					display: inline-block;
					position: absolute;
					top: 3px;
					right: 3px;
					z-index: 2;
					background: url('../../assets/img/icons/icon_cross-light.svg') center center no-repeat;
					background-size: 14px 14px;
					opacity: 0.25;
					transition: all 0.1s linear;
					&:hover{
						opacity: 0.5;
						width: 16px;
						height: 16px;
						background-size: 16px 16px;
					}
				}
			}
		}
	}
	.qd_autosuggest-content{
		@include flexbox();
  		@include justify-content(space-between);
		@include align-items(center);
		&> span{
			font-size: 10px;
			font-weight: 400;
			color: #A8A4A4;
			line-height: normal;
			padding-left: 10px;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			> svg {
				margin-right: 5px;
			}
		}
		.qd_autosuggest-option {
			display: inline-flex;
			flex: 0 0 220px;
			overflow: hidden;
			&-inner {
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}
		}
	}
	.suggestion-content{
		.qd_autosuggest-option{
			.svg-inline--fa{
				color: #515151;
			}
		}
	}
	.qd_autosuggest-option{
		font-size: 12px;
		font-weight: 400;
		line-height: 20px;
		color: #515151;
		margin: 0;
		position: relative;
		.svg-inline--fa{
			margin-right: 8px;
			color: #64B5F6;
		}
		strong{
			font-weight: 600;
		}
		.qd_option-icon{
			font-size: 13px;
			color: #64B5F6;
		}
	}
	.no-search-result{
		font-size: 12px;
		font-weight: 500;
		color: #515151;
		padding: 10px 0;
		margin: 0;
		line-height: normal;
		.svg-inline--fa{
			margin-right: 5px;
		}
	}
	.search-result__count{
		font-size: 12px;
		font-weight: 500;
		margin-bottom: 0;
		color: #9A9A9A;
		line-height: normal;
		strong{
			font-weight: 600;
			color: #262323;
		}
	}
	hr{
		margin-top: 10px;
		margin-bottom: 10px;
	}
	&.has-btn-close{
		.btn-clear{
			top: calc(50% + 1px);
			right: 138px;
		}
	}
}

.qd_autosuggest-title{
	font-size: 12px;
	font-weight: 500;
	color: #9A9A9A;
	margin-bottom: 5px;
	line-height: 18px;
}
/*Search Filter Results*/
.qd_search-filter-rst{
	max-width: 800px;
	max-height: 235px;
	margin: 0 auto;
	position: relative;
	top: 1px;
	z-index: 2;
	.qd_search-filter-form{
		position: absolute;
		width: 100%;
		background: #fff;
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;
		box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
		animation: dropdownFade .2s linear;
	}
	.qd_autosuggest-title{
		margin-bottom: 18px;
	}
	.form-label-right-md{
		padding: 20px 20px 15px;
	}
	.btn-link{
		position: absolute;
		top: 14px;
		right: 20px;
		font-size: 12px;
		padding: 0;
	}
	.form-group{
		margin-bottom: 20px;
	}
}
.qd_search-rst-footer{
	padding: 12px 20px 14px;
	border-top: 1px solid rgba(112, 112, 112, 0.1);
}
.qd_tab-separated-block{
	display: flex;
	flex-wrap: wrap;
	list-style: none;
	padding: 0;
	margin: 0;
	.tab-separated-value{
		font-size: 12px;
		font-weight: 500;
		line-height: normal;
		color: #9A9A9A;
		margin: 0;
		span{
			strong{
				font-weight: 600;
				color: #262323;
			}
		}
		&+ .tab-separated-value{
			padding-left: 5px;
			&:before{
				display: inline-block;
				padding-right: 5px;
				color: #9A9A9A;
				content: "/";
			}
		}
	}
}
@keyframes dropdownFade {
	0% {
		opacity: 0;
		-webkit-transform: translateY(-10px);
		transform: translateY(-10px);
	}
	100% {
		opacity: 1;
		-webkit-transform: translateY(0px);
		transform: translateY(0px);
	}
}
@media (max-width: 1199.98px){
	.qd_search-box{
		.qd-filtered_button{
			.btn{
				&> span{
					display: inline-block;
				}
			}
		}
	}
}
@media (max-width: 576px){
	.qd_search-container {
		padding: 5px 0 2px;
	}
	.qd_search-box{
		height: 46px;
		border-radius: 6px;
		.form-control{
			font-size: 12px;
		}
		&:before{
			width: 24px;
			height: 24px;
			left: 10px;
			background-size: 24px 24px;
		}
		input{
			padding: 6px 70px 6px 40px;
			font-size: 12px;
		}
		.qd-filtered_button {
			right: 6px;
			.btn{
				border-radius: 6px;
				padding: 4px 6px;
				width: 38px;
				&> span{
					display: none;
				}
			}
		}
		.qd_search-bar-divider{
			font-size: 12px;
			right: 50px;
			height: calc(100% - 4px);
			&:before{
				height: calc(100% - 12px);
				top: 6px;
			}
		}
		.react-autosuggest {
			&__container{
				&--open{
					line-height: 42px;
					border-radius: 6px;
				}
			}
			&__suggestions-container{
				&--open{
					border-bottom-left-radius: 6px;
					border-bottom-right-radius: 6px;
					top: 45px;
					padding: 15px;
				}
			}
			&__suggestion{
				padding: 3px;
			}
		}
		.qd_autosuggest-option{
			.svg-inline--fa{
				margin-left: 0;
			}
		}
		hr{
			margin-top: 8px;
			margin-bottom: 8px;
		}
		.no-search-result{
			padding: 6px 0;
		}
	}
}
/*Tree view sidenav*/
.qd_tree-view-container {
	background-color: #f9f9fc;
	width: 100%;
	height: 100%;
}
.qd_tree-search{
	position: relative;
	padding: 15px;
	&.has-btn-close{
		.btn-clear{
			right: 20px;
		}
	}
}
.qd_tree-search .qd_tree-search-icon {
	position: absolute;
	z-index: 2;
	display: block;
	width: 34px;
	height: 34px;
	line-height: 34px;
	text-align: center;
	pointer-events: none;
	color: rgba(0,0,0,0.15);
  }
  .qd_tree-search .form-control {
	padding: 6px 24px 6px 34px;
	border-color: #E4E7EA;
}
.qd_tree-wrap{
	flex: 0 0 300px;
	.qd_tree-header{
		display: none;
		padding: 12px 15px;
		border-bottom: 1px solid #EFEFEF;
		h4{
			font-size: 14px;
			font-weight: 500;
			line-height: normal;
			color: #484848;
			margin: 0;
			[class*=" fa-"] {
				margin-right: 5px;
				-webkit-transform: scaleY(-1) rotate(-90deg);
				transform: scaleY(-1) rotate(-90deg);
			}
		}
		.close{
			width: 24px;
			height: 24px;
			margin-top: 0;
			border-radius: 2px;
			right: -5px;
			&:before,&:after{
				width: 16px;
				left: 0.268rem;
				top: 0.656rem;
				background-color: #A3A3A5;
			}
			&:hover{
				background-color: rgba(0, 0, 0, 0.15);
				&:before,&:after{
					background-color: #3D3D3E;
				}
			}
		}
	}
	+ .qd_tree-overlay{
		display: none;
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: rgba(0, 0, 0, 0.1);
		z-index: 4;
	}
}
.qd_tree-view {
	width: 100%;
	font-size: 15px;
	font-weight: 500;
	list-style-type: none;
	margin: 0;
	padding-left: 15px;
	color: #68a6e8;
	background-color: #f9f9fc;
    ul,
	li {
		list-style-type: none;
	}
    ul {
		padding-left: 20px;
		padding-top: 10px;
        li {
			padding-bottom: 5px;
			white-space: nowrap;
			// overflow: hidden; 
			text-overflow: ellipsis;
			// line-height: normal;
			span{
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}
    i {
		color: #68a6e8;
		margin-right: 10px;
	}
    .caret {
		margin: 0;
		border: 0;
		display: inline-block;
		width: 100%;
		height: auto;
		cursor: pointer;
		-webkit-user-select: none;
		/* Safari 3.1+ */
		-moz-user-select: none;
		/* Firefox 2+ */
		-ms-user-select: none;
		/* IE 10+ */
		user-select: none;
		position: relative;
        &:before {
			content: "";
			background: url('data:image/svg+xml;utf8,<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-right" class="svg-inline--fa fa-caret-right fa-w-6" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512"><path fill="rgba(125,125,125,0.5)" d="M0 384.662V127.338c0-17.818 21.543-26.741 34.142-14.142l128.662 128.662c7.81 7.81 7.81 20.474 0 28.284L34.142 398.804C21.543 411.404 0 402.48 0 384.662z"></path></svg>');
			background-size: auto;
			background-repeat: no-repeat;
			background-position: center;
			display: inline-block;
			margin-right: 5px;
			position: relative;
			top: 3px;
			left: 0;
			width: 16px;
			height: 16px;
		}
        &.caret-down:before {
			background: url('data:image/svg+xml;utf8,<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-right" class="svg-inline--fa fa-caret-right fa-w-6" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512"><path fill="rgb(125,125,125)" d="M0 384.662V127.338c0-17.818 21.543-26.741 34.142-14.142l128.662 128.662c7.81 7.81 7.81 20.474 0 28.284L34.142 398.804C21.543 411.404 0 402.48 0 384.662z"></path></svg>');
			background-size: auto;
			background-repeat: no-repeat;
			background-position: center;
		}
		&.caret-down {
			color: #007bff;
			i {
				color: #007bff;
			}
		}
		&.caret-invisible {
          &:before {
            visibility: hidden;
          }
        }

	}
    .nested {
		display: none;
		
		>li.active {
			position: relative;
			z-index: 1;
			// color: #007BFF;
			>.caret {
				font-weight: 600;
				i {
					color: #007BFF;
				}
			}
			&:before {
				content: "";
				height: 26px;
				width: calc(100% - 15px);
				background-color: #EBE8E8;
				position: absolute;
				left: 15px;
				top: 1px;
				z-index: -1;
				border-top-left-radius: 4px;
				border-bottom-left-radius: 4px;
			}
		}
		&.tree_top-level {
			padding-left: 18px;
			>li>.caret {
				font-size: 14px;
			}
			>.active{
				&:before{
					top: 0;
				}
			}
		}
        .caret {
			font-size: 12px;
			font-weight: 500;
            i {
				color: #9a9a9a;
				margin-right: 10px;
			}
			&.caret-down{
				i{
					color: #007bff;
				}
			}
		}
		&.has-no-caret {
			padding-left: 44px;
			font-size: 12px;
			li{
				padding-bottom: 8px;
				i {
					color: #9a9a9a;
				}
				&.active {
					color: #007bff;
					font-weight: 600;
					&:before {
						left: -10px;
						top: -4px;
						width: calc(100% + 10px);
					}
					i {
						color: #007bff;
					}
				}
				&:hover{
					color: #007bff;
				}
				&:last-child{
					padding-bottom: 2px;
				}
			}
		}
		&> li {
			&:hover {
			  cursor: pointer;
			  > .caret {
				color: #007bff;
			  }
			}
		}
	}
    .search-highlight {
		color: #fa4616;
		position: relative;
        &:before {
			position: absolute;
			content: "";
			left: 0;
			right: 0;
			bottom: 0;
			top: 0;
			border: 1px solid #f64e60;
			border-radius: 4px;
			margin: -4px -10px;
		}
	}
	> li{
		position: relative;
		&:hover{
			> .caret {
				color: #007bff;
			}
		}
		&.active{
			&:before {
				content: "";
				height: 26px;
				width: calc(100% - 15px);
				background-color: #EBE8E8;
				position: absolute;
				left: 15px;
				top: 0;
				border-top-left-radius: 4px;
				border-bottom-left-radius: 4px;
			}
		}
	}
	.search-text-highlight{
		position: relative;
		font-weight: 600;
		color: #007BFF;
		&:before{
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background-color: rgba(250, 70, 22, 0.15);
			margin: -2px 0 -2px -2px;
		}
	}
	.tree-search-text-highlight{
		font-weight: 600;
		color: #007BFF;
	}
}
.qd_tree-view .nested.has-no-caret .search-highlight i {
	color: #fa4616;
}
.qd_tree-view .caret-down:before {
	-ms-transform: rotate(90deg);
	/* IE 9 */
	-webkit-transform: rotate(90deg);
	/* Safari */
	transform: rotate(90deg);
}
.qd_tree-view .active {
	display: block;
}
.qd_tree-content {
	flex: 1 1 0;
	min-width: 0;
}
.qd_tree-content-inner {
	width: 100%;
	height: 100%;
	padding: 15px;
	border-left: 1px solid #eee;
	background-color: #fff;
	#discoverCards{
		&.portlet-body{
			padding: 10px;
		}
	}
	.dashboard-stat {
		margin-bottom: 10px;
		.details {
			.title{
				width: calc(100% - 30px);
				font-size: 16px;
				line-height: 18px !important;
			}
			.desc{
				.desc-value{
					font-size: 20px;
					line-height: 22px;
				}
				.desc-percentage{
					font-size: 14px;
				}
			}
			.desc-img{
				top: 50%;
				right: 0;
				width: 54px;
				transform: translateY(-50%);
			}
		}
		.help-icon{
			width: 24px;
			height: 24px;
			border-radius: 24px;
			font-size: 14px;
		}
	}
	.card-attribute{
		margin-bottom: 10px;
		.card-body{
			padding: 15px;
		}
		.card-text{
			font-size: 20px;
		}
		.card-image{
			.card_line-graph {
				width: 60px;
				height: auto;
			}
			.svg-inline--fa {
				width: 30px;
				height: auto;
			}
		}
	}
}
.qd_tree-info {
	.breadcrumb {
		padding-top: 6px;
		padding-bottom: 6px;
		.breadcrumb-item{
			color: #007bff;
			cursor: pointer;
			i{
				font-size: 12px;
			}
			a{
				color: #007bff;
				text-decoration: none;
			}
			&.active{
				a{
					color: #868E96;
				}
			}
			&.active{
				color: #868E96;
			}
			&:hover{
				span{
					text-decoration: underline;
				}
			}
		}
	}
	.qd_tree-info-title {
		font-size: 18px;
		font-weight: 500;
		color: #007bff;
		line-height: 27px;
		padding: 10px 0 20px;
		margin: 0;
		span {
			margin-left: 10px;
		}
		i {
			color: #007bff;
		}
	}
}
@media (max-width: 768px){
	.qd_tree-wrap{
		display: none;
		position: fixed;
		top: 0;
		left: -300px;
		z-index: 5;
		height: 100%;
		max-width: 300px;
		box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
		transition: all 0.2s linear;
		&.show{
			display: block;
			left: 0;
			.qd_tree-header{
				@include flexbox();
				@include align-items(center);
				@include justify-content(space-between);
			}
			+ .qd_tree-overlay{
				display: block;
			}
		}
	}
}
@media (max-width: 576px){
	.qd_search-container {
		+.portlet {
			> .portlet-title{
				padding: 5px 10px;
				> .caption{
					padding: 6px 0;
					span{
						font-size: 14px;
						line-height: normal;
					}
				}
				> .actions{
					.help-icon{
						width: 24px;
						height: 24px;
						border-radius: 24px;
						font-size: 14px;
						i{
							line-height: 14px;
						}
					}
				}
			}
		}
	}
	.qd_tree-info {
		.breadcrumb{
			padding: 2px 0;
			margin-bottom: 10px;
			.breadcrumb-item{
				font-size: 12px;
				span{
					display: none;
				}
				&:nth-last-child(1),&:nth-last-child(2){
					span{
						display: inline-flex;
					}
				}
				+ .breadcrumb-item{
					padding-left: 4px;
					&:before{
						padding-right: 4px;
					}
				}
			}
		}
		.qd_tree-info-title{
			font-size: 14px;
			line-height: normal;
			padding: 8px 0 5px;
		}
	}
	.qd_tree-content-inner{
		padding: 10px;
		hr{
			margin: 10px auto;
		}
		.nav-tabs{
			.nav-link{
				font-size: 12px;
				padding: 5px 10px;
				&.active{
					&:before{
						bottom: -6px;
					}
				}
			}
		}
		.chart-legend{
			margin: 5px 0 10px;
			@include justify-content(flex-start);
			>div{
				font-size: 10px;
				margin-left: 4px;
				margin-top: 4px;
				padding: 4px 6px;
				border-radius: 2px;
			}
			.dot{
				width: 8px;
				height: 8px;
				border-radius: 8px;
				margin-right: 4px;
			}
		}
		.portlet {
			> .portlet-title{
				padding: 5px 10px;
				> .caption{
					padding: 6px 0;
					span{
						font-size: 14px;
						line-height: normal;
					}
				}
			}
			.portlet-table{
				.form-group {
					padding: 6px;
					label{
						font-size: 12px;
					}
				}
				.portlet-table-value{
					font-size: 12px;
					line-height: normal;
				}
			}
		}
		.qd-chartWidget{
			.qd-chartWidget_top {
				height: 34px;
			}
			.qd-cw-col{
				min-width: 38px;
			}
			.qd-cw-btn{
				padding: 0 6px;
				
			}
			.qd-chartWidget_body{
				padding: 6px;
			}
		}
		.qd-cw-wrap{
			height: 34px;
		}
		.qd-monitor__footer {
			.error-detail{
				padding: 10px;
				p{
					font-size: 12px;
					line-height: normal;
					i{
						flex: 0 0 20px;
					}
				}
			}
		}
		.dashboard-stat {
			.details {
				.desc-header{
					min-height: 38px;
				}
			}
		}
	}
}
/*text color*/
.text-gold{
    color: #DEBA6B;
}
.fs-5 {
    font-size: 1.25rem !important;
}

/*Search Placeholder*/
.search-plh{
	padding: 100px 20px 40px;
	text-align: center;
	&__icon{
		width: 150px;
		height: auto;
		margin: 0 auto 10px;
	}
	&__title{
		font-size: 20px;
		font-weight: 600;
		line-height: 27px;
		color: $color-dark;
		margin-bottom: 10px;
	}
	&__desc{
		max-width: 300px;
		font-size: 14px;
		font-weight: 400;
		line-height: normal;
		color: $color-gray;
		margin: 0 auto;
	}
}
@media (max-width: 576px){
	.search-plh{
		padding: 20px 0 30px;
		&__icon{
			width: 120px;
			margin-bottom: 10px;
		}
		&__title{
			font-size: 18px;
			margin-bottom: 5px;
		}
		&__desc{
			font-size: 12px;
			max-width: 280px;
		}
	}
}

/*No match*/
.alert_notree-search-match{
	padding: 40px 20px;
	text-align: center;
	img{
		width: 44px;
		height: auto;
		margin-bottom: 5px;
	}
	p{
		max-width: 160px;
		font-size: 12px;
		font-weight: 500;
		color: #484848;
		margin: 0 auto;
	}
}

/*qd tree search results*/
.search-controls-section{
	padding: 15px;
}
.qd_tree-search-controls{
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 44px;
	border-bottom: 1px solid #EFEFEF;
	margin: 6px 0 0;
	p{
		font-size: 13px;
		font-weight: 400;
		line-height: 18px;
		color: #484848;
		margin: 0;
		strong{
			font-weight: 600;
		}
	}
	.search-actions{
		display: flex;
		align-items: center;
		.btn{
			padding: 0;
			width: 24px;
			height: 24px;
			line-height: 28px;
			border-radius: 2px;
			border: 1px solid transparent;
			+.btn{
				margin-left: 5px;
			}
			&:hover,&:focus,&:active{
				background-color: #EEEEEE;
				box-shadow: none;
				i{
					opacity: 0.85;
				}
			}
			&:active,&:focus{
				border: 1px solid #D6D6D6;
			}
			&.disabled,
			&:disabled {
				pointer-events: none;
    		opacity: .35;
			}
		}
	}
	.icon-divider{
		width: 1px;
		height: 14px;
		display: inline-block;
		margin: 0 2px;
		background-color: rgba(112, 112, 112, 0.3);
	}
	.icon_arrow-up{
		background: url('../../assets/img/icons/icon_arrow-up-black.svg') center center no-repeat;
	}
	.icon_arrow-down{
		background: url('../../assets/img/icons/icon_arrow-down-black.svg') center center no-repeat;
	}
	.icon_cross{
		background: url('../../assets/img/icons/icon_close-black.svg') center center no-repeat;
	}
	.icon_arrow-up,.icon_arrow-down,.icon_cross{
		width: 16px;
		height: 16px;
		display: inline-block;
		opacity: 0.65;
		background-size: 16px 16px;
	}
}

/*btn show more*/	
.btn-show-more{
	width: 240px;
	padding: 4px 10px;
	@include flexbox();
	@include align-content(center);
	@include justify-content(center);
	font-size: 10px;
	font-weight: 600;
	margin: 0 auto;
	line-height: normal;
	background-color: #F0F0F0;
	border: 1px solid #EAEAEA;
	color: $color-blue;
	text-transform: uppercase;
	&:hover, 
	&:focus, 
	&:active{
		color: $color-blue;
		background-color: #EBEBEB;
		border: 1px solid #E0E0E0;
		box-shadow: none;
	}
}
.icon-show-more{
	width: 16px;
	height: 16px;
	display: inline-block;
	background: url('../../assets/img/icons/icon_download-blue.svg') center center no-repeat;
	background-size: 16px 16px;
	margin-right: 5px;
}

/*custom scrollbar*/
.custom-scrollbar{
	&> div{
		margin-bottom: -18px !important;
		&:nth-child(3){
			background-color: transparent;
			right: 0 !important;
			&> div{
				background-color: #EFEFEF !important;
			}
		}
	}
}

/*btn tree toggler*/
.btn_tree-toggler{
	display: none;
	font-size: 14px;
	font-weight: 500;
	color: #484848;
	padding: 6px 15px;
	background-color: rgba(0, 0, 0, 0.15);
	border-radius: 0 0 10px 10px;
	position: fixed;
	top: 50%;
	left: -46px;
	transform: rotate(270deg);
	[class*=" fa-"] {
		margin-right: 5px;
		-webkit-transform: scaleX(-1);
		transform: scaleX(-1);
	}
}
@media (max-width: 768px){
	.btn_tree-toggler{
		display: block;
		left: -16px;
	}
}
@media (max-width: 575.98px){
	.btn_tree-toggler{
		left: -46px;
	}
}
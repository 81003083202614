.tabbable-line > {
  .nav-tabs {
    // border: none;
    margin: 0px;
    > .nav-item {
      margin: 0 5px 0 0;
      > .nav-link {
        position: relative;
        background: none !important;
        border: 0;
        margin: 0;
        padding-left: 15px;
        padding-right: 15px;
        color: lighten($base-text-color, 30%);
        i {
          color: lighten($base-text-color, 30%);;
          margin-right: 6px;
        }
        &.active {
          background: none;
          border-bottom: 4px solid transparent;
          position: relative;
          color: $base-text-color;
          font-weight: 500;
          color: lighten($base-text-color, 5%);
          i {
            color: lighten($base-text-color, 10%);
          }
          &:after {
            width: 100%;
            height: 4px;
            content: "";
            left: 0;
            bottom: -4px;
            position: absolute;
            background-color: #484848;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
          }
        }
        &.open, &:hover {
          background: none;
          // border-bottom: 4px solid #9fe4ea;
          &:after {
            width: 100%;
            height: 4px;
            content: "";
            left: 0;
            bottom: -4px;
            position: absolute;
            background-color: lighten($base-text-color, 40%);
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
          }
        }
        &.open > .nav-link, &:hover > .nav-link {
          border: 0;
          background: none !important;
          color: #333;
        }
        &.open > .nav-link > i, &:hover > .nav-link > i {
          color: #a6a6a6;
        }
        &.open .dropdown-menu, &:hover .dropdown-menu {
          margin-top: 0px;
        }
      }
      
      
    }
  }
}
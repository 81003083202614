:root {
    --sk-loading-grey:#fff;
  }
.sk-loading-img{
    background-color: #EEF1F5 !important;
}
.sk-loading {
    background-color: var(--sk-loading-grey);
    background: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, .5) 50%,
      rgba(255, 255, 255, 0) 60%
    ) var(--sk-loading-grey);
    background-size: 200% 100%;
    background-position-x: 180%;
    animation: 1s sk-loading ease-in-out infinite;
    /* min-height: 4rem; */
    border-radius: 4px;
    animation-delay: .01s;
  }
  @keyframes sk-loading {
    to {
      background-position-x: -20%;
    }
  }
  .sk-loading h5 {
    background-color: #EEF1F5;
    border-radius: 4px;
    animation-delay: .05s;
    min-height: 16px;
  }
  .sk-loading .img{
    background-color: #EEF1F5 !important;
    border-radius: 4px;
    animation-delay: .05s;
    min-height: 16px;
  }
  
  .sk-loading .card-value {
    background-color: #EEF1F5;
    border-radius: 4px;
    animation-delay: .06s;
    min-height: 24px;
  }